import React, { ReactElement, useState } from 'react';

export function TokenIcon({ url, tokenName, userAssets }: {
  tokenName: string;
  url: string;
  userAssets?: boolean;
}): ReactElement {
  const [hasError, setHasError] = useState(false);

  if (!url) {
    url = 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/solana/info/logo.png';
  }

  if (hasError || !url) {
    return null;
  }

  return (
    <figure style={{ margin: 0, display: 'inline-block' }}>
      <img
        src={url}
        title={tokenName}
        alt={tokenName}
        style={{
          width: 35,
          height: 35,
          backgroundColor: 'white',
          borderRadius: 35 / 2,
        }}
        onError={(): void => setHasError(true)}
      />
      {userAssets && <span className="userHasAssets" />}
    </figure>
  );
}
