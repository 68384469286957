import { Space, Typography } from 'antd';
import { ReactElement, useMemo } from 'react';
import { TokenData } from 'interfaces/Token.interface';
import { numberToFixed, percentFormat } from 'utils';
import { calculatorService } from 'features/Calculator/Calculator.service';
import { useSelector } from 'react-redux';
import { selectWalletBorrowTotalUsd, selectWalletDepositTotalUsd } from 'features/Wallet/state/Wallet.selector';

const { Text } = Typography;

export function Summary({ token, inputAmount, borrowFunds, borrowAvailable }: {
  borrowAvailable: number;
  borrowFunds: boolean;
  inputAmount: number;
  token: TokenData;
}): ReactElement {
  const deposited = useSelector(selectWalletDepositTotalUsd);
  const borrowed = useSelector(selectWalletBorrowTotalUsd);
  const borrowFee = useMemo(() => numberToFixed(calculatorService.getBorrowTotalFee(token, inputAmount), token.decimals), [token, inputAmount]);

  const isUnderLiquidation = useMemo(() => calculatorService.getIsUnderLiquidation(deposited, borrowed), [deposited, borrowed]);
  return (
    <div className="modal-subinfo">
      <Space direction="vertical" size={5}>
        <Space style={{ justifyContent: 'space-between' }}>
          <Text>Borrow limit</Text>
          <Text>
            {isUnderLiquidation
              ? <span className="liquidation">Liquidation risk</span>
              : (
                <Space size={5}>
                  <div>
                    {borrowAvailable.toFixed(token.decimals)}
                    {' '}
                    {token.symbol}
                  </div>
                </Space>
              )}
          </Text>
        </Space>
        <Space style={{ justifyContent: 'space-between' }}>
          <Text>Borrow APR</Text>
          <Text>{percentFormat.format(token.borrowApr)}</Text>
        </Space>
        <Space style={{ justifyContent: 'space-between' }}>
          <Text>Borrow fee per day</Text>
          <Text>
            <Space size={5}>
              <div>
                {borrowFunds ? borrowFee || 'Less than 0.1' : '0'}
                {' '}
                {token.symbol}
              </div>
            </Space>
          </Text>
        </Space>
      </Space>
    </div>
  );
}
