export type BorrowLending = {
  'accounts': [
    {
      'name': 'MarginAccount';
      'type': {
        'fields': [
          {
            'name': 'bump';
            'type': 'u8';
          },
          {
            'name': 'authority';
            'type': 'publicKey';
          },
          {
            'name': 'tokenShares';
            'type': {
              'array': [
                {
                  'defined': 'TokenShares';
                },
                64
              ];
            };
          },
          {
            'name': 'mainAccount';
            'type': 'publicKey';
          },
          {
            'name': 'padding';
            'type': {
              'array': [
                'u8',
                1024
              ];
            };
          }
        ];
        'kind': 'struct';
      };
    },
    {
      'name': 'MainAccount';
      'type': {
        'fields': [
          {
            'name': 'authority';
            'type': 'publicKey';
          },
          {
            'name': 'tokens';
            'type': {
              'array': [
                {
                  'defined': 'TokenData';
                },
                64
              ];
            };
          },
          {
            'name': 'balances';
            'type': {
              'array': [
                {
                  'defined': 'BalanceTs';
                },
                64
              ];
            };
          },
          {
            'name': 'prices';
            'type': {
              'array': [
                {
                  'defined': 'PriceTs';
                },
                64
              ];
            };
          },
          {
            'name': 'signerBump';
            'type': 'u8';
          },
          {
            'name': 'priceAuthority';
            'type': 'publicKey';
          },
          {
            'name': 'padding';
            'type': {
              'array': [
                'u8',
                992
              ];
            };
          }
        ];
        'kind': 'struct';
      };
    },
    {
      'name': 'TokensParametersAccount';
      'type': {
        'fields': [
          {
            'name': 'authority';
            'type': 'publicKey';
          },
          {
            'name': 'params';
            'type': {
              'array': [
                {
                  'defined': 'TokenParameters';
                },
                64
              ];
            };
          },
          {
            'name': 'rates';
            'type': {
              'array': [
                {
                  'defined': 'TokenRate';
                },
                64
              ];
            };
          },
          {
            'name': 'padding';
            'type': {
              'array': [
                'u8',
                500
              ];
            };
          }
        ];
        'kind': 'struct';
      };
    }
  ];
  'errors': [
    {
      'code': 300;
      'msg': 'Token account is not owned by main account.';
      'name': 'IncorrectTokenAccount';
    },
    {
      'code': 301;
      'msg': 'The given token mint is not supported.';
      'name': 'UnsupportedTokenMint';
    },
    {
      'code': 302;
      'msg': 'Account is not authorized to add a new token.';
      'name': 'UnauthorizedAddToken';
    },
    {
      'code': 303;
      'msg': 'Account is not authorized to set token price.';
      'name': 'UnauthorizedSetPrice';
    },
    {
      'code': 304;
      'msg': 'Stale price or interest data.';
      'name': 'StaleData';
    },
    {
      'code': 305;
      'msg': 'Too many decimals in token mint';
      'name': 'TooManyDecimals';
    },
    {
      'code': 306;
      'msg': 'Account has insufficient collateral.';
      'name': 'InsufficientCollateral';
    },
    {
      'code': 307;
      'msg': 'Account has sufficient collateral.';
      'name': 'SufficientCollateral';
    },
    {
      'code': 308;
      'msg': 'Invalid token configuration.';
      'name': 'InvalidTokenConfiguration';
    },
    {
      'code': 309;
      'msg': 'Invalid token id.';
      'name': 'InvalidTokenId';
    }
  ];
  'instructions': [
    {
      'accounts': [
        {
          'isMut': false;
          'isSigner': false;
          'name': 'mainSigner';
        },
        {
          'isMut': true;
          'isSigner': true;
          'name': 'mainAccount';
        },
        {
          'isMut': true;
          'isSigner': true;
          'name': 'authority';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'priceAuthority';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'systemProgram';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'rent';
        }
      ];
      'args': [
        {
          'name': 'signerBump';
          'type': 'u8';
        }
      ];
      'name': 'initializeMain';
    },
    {
      'accounts': [
        {
          'isMut': true;
          'isSigner': false;
          'name': 'mainAccount';
        },
        {
          'isMut': false;
          'isSigner': true;
          'name': 'authority';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'newPriceAuthority';
        }
      ];
      'args': [];
      'name': 'setPriceAuthority';
    },
    {
      'accounts': [
        {
          'isMut': true;
          'isSigner': false;
          'name': 'marginAccount';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'mainAccount';
        },
        {
          'isMut': true;
          'isSigner': true;
          'name': 'authority';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'systemProgram';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'rent';
        }
      ];
      'args': [
        {
          'name': 'marginBump';
          'type': 'u8';
        }
      ];
      'name': 'initializeMargin';
    },
    {
      'accounts': [
        {
          'isMut': true;
          'isSigner': false;
          'name': 'marginAccount';
        },
        {
          'isMut': true;
          'isSigner': false;
          'name': 'mainAccount';
        },
        {
          'isMut': true;
          'isSigner': false;
          'name': 'from';
        },
        {
          'isMut': true;
          'isSigner': false;
          'name': 'to';
        },
        {
          'isMut': false;
          'isSigner': true;
          'name': 'authority';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'tokenProgram';
        }
      ];
      'args': [
        {
          'name': 'quantity';
          'type': 'u64';
        },
        {
          'name': 'vaultBump';
          'type': 'u8';
        }
      ];
      'name': 'deposit';
    },
    {
      'accounts': [
        {
          'isMut': true;
          'isSigner': false;
          'name': 'marginAccount';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'mainSigner';
        },
        {
          'isMut': true;
          'isSigner': false;
          'name': 'mainAccount';
        },
        {
          'isMut': true;
          'isSigner': false;
          'name': 'from';
        },
        {
          'isMut': true;
          'isSigner': false;
          'name': 'to';
        },
        {
          'isMut': false;
          'isSigner': true;
          'name': 'authority';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'tokenProgram';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'clock';
        }
      ];
      'args': [
        {
          'name': 'quantity';
          'type': 'u64';
        },
        {
          'name': 'vaultBump';
          'type': 'u8';
        }
      ];
      'name': 'withdraw';
    },
    {
      'accounts': [
        {
          'isMut': true;
          'isSigner': false;
          'name': 'liquidateeAccount';
        },
        {
          'isMut': true;
          'isSigner': false;
          'name': 'liquidatorAccount';
        },
        {
          'isMut': true;
          'isSigner': false;
          'name': 'mainAccount';
        },
        {
          'isMut': false;
          'isSigner': true;
          'name': 'authority';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'clock';
        }
      ];
      'args': [];
      'name': 'liquidate';
    },
    {
      'accounts': [
        {
          'isMut': true;
          'isSigner': false;
          'name': 'mainAccount';
        },
        {
          'isMut': true;
          'isSigner': false;
          'name': 'tokenParamsAccount';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'clock';
        }
      ];
      'args': [
        {
          'name': 'tpaBump';
          'type': 'u8';
        },
        {
          'name': 'tokenIds';
          'type': {
            'array': [
              'u8',
              16
            ];
          };
        }
      ];
      'name': 'crankInterest';
    },
    {
      'accounts': [
        {
          'isMut': true;
          'isSigner': false;
          'name': 'mainAccount';
        },
        {
          'isMut': false;
          'isSigner': true;
          'name': 'priceAuthority';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'clock';
        }
      ];
      'args': [
        {
          'name': 'tokenIds';
          'type': {
            'array': [
              'u8',
              16
            ];
          };
        },
        {
          'name': 'prices';
          'type': {
            'array': [
              'u128',
              16
            ];
          };
        }
      ];
      'name': 'setPrice';
    },
    {
      'accounts': [];
      'args': [
        {
          'name': 'tokenIds';
          'type': {
            'array': [
              'u8',
              16
            ];
          };
        }
      ];
      'name': 'crankPrice';
    },
    {
      'accounts': [
        {
          'isMut': false;
          'isSigner': false;
          'name': 'mainSigner';
        },
        {
          'isMut': true;
          'isSigner': false;
          'name': 'mainAccount';
        },
        {
          'isMut': true;
          'isSigner': false;
          'name': 'tokenVault';
        },
        {
          'isMut': true;
          'isSigner': false;
          'name': 'feeVault';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'mint';
        },
        {
          'isMut': true;
          'isSigner': true;
          'name': 'authority';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'systemProgram';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'rent';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'tokenProgram';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'clock';
        }
      ];
      'args': [
        {
          'name': 'vaultBump';
          'type': 'u8';
        },
        {
          'name': 'feeBump';
          'type': 'u8';
        }
      ];
      'name': 'addToken';
    },
    {
      'accounts': [
        {
          'isMut': true;
          'isSigner': false;
          'name': 'mainAccount';
        },
        {
          'isMut': true;
          'isSigner': false;
          'name': 'tokenParamsAccount';
        },
        {
          'isMut': true;
          'isSigner': true;
          'name': 'authority';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'systemProgram';
        },
        {
          'isMut': false;
          'isSigner': false;
          'name': 'rent';
        }
      ];
      'args': [
        {
          'name': 'tpaBump';
          'type': 'u8';
        }
      ];
      'name': 'initTokenParameters';
    },
    {
      'accounts': [
        {
          'isMut': false;
          'isSigner': false;
          'name': 'mainAccount';
        },
        {
          'isMut': true;
          'isSigner': false;
          'name': 'tokenParamsAccount';
        },
        {
          'isMut': true;
          'isSigner': true;
          'name': 'authority';
        }
      ];
      'args': [
        {
          'name': 'tpaBump';
          'type': 'u8';
        },
        {
          'name': 'tokenId';
          'type': 'u8';
        },
        {
          'name': 'uOptimal';
          'type': 'u16';
        },
        {
          'name': 'baseRate';
          'type': 'u16';
        },
        {
          'name': 'slopeOne';
          'type': 'u16';
        },
        {
          'name': 'slopeTwo';
          'type': 'u16';
        }
      ];
      'name': 'setTokenParameters';
    }
  ];
  'name': 'borrow_lending';
  'types': [
    {
      'name': 'TokenShares';
      'type': {
        'fields': [
          {
            'name': 'tokenId';
            'type': 'u8';
          },
          {
            'name': 'shares';
            'type': 'i128';
          }
        ];
        'kind': 'struct';
      };
    },
    {
      'name': 'Balance';
      'type': {
        'fields': [
          {
            'name': 'depositTotal';
            'type': 'u128';
          },
          {
            'name': 'borrowTotal';
            'type': 'u128';
          },
          {
            'name': 'depositShares';
            'type': 'u128';
          },
          {
            'name': 'borrowShares';
            'type': 'u128';
          }
        ];
        'kind': 'struct';
      };
    },
    {
      'name': 'BalanceTs';
      'type': {
        'fields': [
          {
            'name': 'balance';
            'type': {
              'defined': 'Balance';
            };
          },
          {
            'name': 'ts';
            'type': 'i64';
          }
        ];
        'kind': 'struct';
      };
    },
    {
      'name': 'TokenRate';
      'type': {
        'fields': [
          {
            'name': 'borrowApr';
            'type': 'u128';
          },
          {
            'name': 'depositApy';
            'type': 'u128';
          }
        ];
        'kind': 'struct';
      };
    },
    {
      'name': 'TokenParameters';
      'type': {
        'fields': [
          {
            'name': 'uOptimal';
            'type': 'u16';
          },
          {
            'name': 'baseRate';
            'type': 'u16';
          },
          {
            'name': 'slopeOne';
            'type': 'u16';
          },
          {
            'name': 'slopeTwo';
            'type': 'u16';
          }
        ];
        'kind': 'struct';
      };
    },
    {
      'name': 'TokenData';
      'type': {
        'fields': [
          {
            'name': 'mint';
            'type': 'publicKey';
          },
          {
            'name': 'decimals';
            'type': 'u8';
          }
        ];
        'kind': 'struct';
      };
    },
    {
      'name': 'PriceTs';
      'type': {
        'fields': [
          {
            'name': 'price';
            'type': 'u128';
          },
          {
            'name': 'ts';
            'type': 'i64';
          }
        ];
        'kind': 'struct';
      };
    }
  ];
  'version': '0.0.0';
};

export const IDL: BorrowLending = {
  version: '0.0.0',
  name: 'borrow_lending',
  instructions: [
    {
      name: 'initializeMain',
      accounts: [
        {
          name: 'mainSigner',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'mainAccount',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'authority',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'priceAuthority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'rent',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'signerBump',
          type: 'u8',
        },
      ],
    },
    {
      name: 'setPriceAuthority',
      accounts: [
        {
          name: 'mainAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'authority',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'newPriceAuthority',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'initializeMargin',
      accounts: [
        {
          name: 'marginAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'mainAccount',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'authority',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'rent',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'marginBump',
          type: 'u8',
        },
      ],
    },
    {
      name: 'deposit',
      accounts: [
        {
          name: 'marginAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'mainAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'from',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'to',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'authority',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'quantity',
          type: 'u64',
        },
        {
          name: 'vaultBump',
          type: 'u8',
        },
      ],
    },
    {
      name: 'withdraw',
      accounts: [
        {
          name: 'marginAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'mainSigner',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'mainAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'from',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'to',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'authority',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'clock',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'quantity',
          type: 'u64',
        },
        {
          name: 'vaultBump',
          type: 'u8',
        },
      ],
    },
    {
      name: 'liquidate',
      accounts: [
        {
          name: 'liquidateeAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'liquidatorAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'mainAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'authority',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'clock',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'crankInterest',
      accounts: [
        {
          name: 'mainAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenParamsAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'clock',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'tpaBump',
          type: 'u8',
        },
        {
          name: 'tokenIds',
          type: {
            array: [
              'u8',
              16,
            ],
          },
        },
      ],
    },
    {
      name: 'setPrice',
      accounts: [
        {
          name: 'mainAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'priceAuthority',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'clock',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'tokenIds',
          type: {
            array: [
              'u8',
              16,
            ],
          },
        },
        {
          name: 'prices',
          type: {
            array: [
              'u128',
              16,
            ],
          },
        },
      ],
    },
    {
      name: 'crankPrice',
      accounts: [],
      args: [
        {
          name: 'tokenIds',
          type: {
            array: [
              'u8',
              16,
            ],
          },
        },
      ],
    },
    {
      name: 'addToken',
      accounts: [
        {
          name: 'mainSigner',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'mainAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenVault',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'feeVault',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'mint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'authority',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'rent',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'clock',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'vaultBump',
          type: 'u8',
        },
        {
          name: 'feeBump',
          type: 'u8',
        },
      ],
    },
    {
      name: 'initTokenParameters',
      accounts: [
        {
          name: 'mainAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenParamsAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'authority',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'rent',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'tpaBump',
          type: 'u8',
        },
      ],
    },
    {
      name: 'setTokenParameters',
      accounts: [
        {
          name: 'mainAccount',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenParamsAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'authority',
          isMut: true,
          isSigner: true,
        },
      ],
      args: [
        {
          name: 'tpaBump',
          type: 'u8',
        },
        {
          name: 'tokenId',
          type: 'u8',
        },
        {
          name: 'uOptimal',
          type: 'u16',
        },
        {
          name: 'baseRate',
          type: 'u16',
        },
        {
          name: 'slopeOne',
          type: 'u16',
        },
        {
          name: 'slopeTwo',
          type: 'u16',
        },
      ],
    },
  ],
  accounts: [
    {
      name: 'MarginAccount',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'bump',
            type: 'u8',
          },
          {
            name: 'authority',
            type: 'publicKey',
          },
          {
            name: 'tokenShares',
            type: {
              array: [
                {
                  defined: 'TokenShares',
                },
                64,
              ],
            },
          },
          {
            name: 'mainAccount',
            type: 'publicKey',
          },
          {
            name: 'padding',
            type: {
              array: [
                'u8',
                1024,
              ],
            },
          },
        ],
      },
    },
    {
      name: 'MainAccount',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'authority',
            type: 'publicKey',
          },
          {
            name: 'tokens',
            type: {
              array: [
                {
                  defined: 'TokenData',
                },
                64,
              ],
            },
          },
          {
            name: 'balances',
            type: {
              array: [
                {
                  defined: 'BalanceTs',
                },
                64,
              ],
            },
          },
          {
            name: 'prices',
            type: {
              array: [
                {
                  defined: 'PriceTs',
                },
                64,
              ],
            },
          },
          {
            name: 'signerBump',
            type: 'u8',
          },
          {
            name: 'priceAuthority',
            type: 'publicKey',
          },
          {
            name: 'padding',
            type: {
              array: [
                'u8',
                992,
              ],
            },
          },
        ],
      },
    },
    {
      name: 'TokensParametersAccount',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'authority',
            type: 'publicKey',
          },
          {
            name: 'params',
            type: {
              array: [
                {
                  defined: 'TokenParameters',
                },
                64,
              ],
            },
          },
          {
            name: 'rates',
            type: {
              array: [
                {
                  defined: 'TokenRate',
                },
                64,
              ],
            },
          },
          {
            name: 'padding',
            type: {
              array: [
                'u8',
                500,
              ],
            },
          },
        ],
      },
    },
  ],
  types: [
    {
      name: 'TokenShares',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'tokenId',
            type: 'u8',
          },
          {
            name: 'shares',
            type: 'i128',
          },
        ],
      },
    },
    {
      name: 'Balance',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'depositTotal',
            type: 'u128',
          },
          {
            name: 'borrowTotal',
            type: 'u128',
          },
          {
            name: 'depositShares',
            type: 'u128',
          },
          {
            name: 'borrowShares',
            type: 'u128',
          },
        ],
      },
    },
    {
      name: 'BalanceTs',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'balance',
            type: {
              defined: 'Balance',
            },
          },
          {
            name: 'ts',
            type: 'i64',
          },
        ],
      },
    },
    {
      name: 'TokenRate',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'borrowApr',
            type: 'u128',
          },
          {
            name: 'depositApy',
            type: 'u128',
          },
        ],
      },
    },
    {
      name: 'TokenParameters',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'uOptimal',
            type: 'u16',
          },
          {
            name: 'baseRate',
            type: 'u16',
          },
          {
            name: 'slopeOne',
            type: 'u16',
          },
          {
            name: 'slopeTwo',
            type: 'u16',
          },
        ],
      },
    },
    {
      name: 'TokenData',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'mint',
            type: 'publicKey',
          },
          {
            name: 'decimals',
            type: 'u8',
          },
        ],
      },
    },
    {
      name: 'PriceTs',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'price',
            type: 'u128',
          },
          {
            name: 'ts',
            type: 'i64',
          },
        ],
      },
    },
  ],
  errors: [
    {
      code: 300,
      name: 'IncorrectTokenAccount',
      msg: 'Token account is not owned by main account.',
    },
    {
      code: 301,
      name: 'UnsupportedTokenMint',
      msg: 'The given token mint is not supported.',
    },
    {
      code: 302,
      name: 'UnauthorizedAddToken',
      msg: 'Account is not authorized to add a new token.',
    },
    {
      code: 303,
      name: 'UnauthorizedSetPrice',
      msg: 'Account is not authorized to set token price.',
    },
    {
      code: 304,
      name: 'StaleData',
      msg: 'Stale price or interest data.',
    },
    {
      code: 305,
      name: 'TooManyDecimals',
      msg: 'Too many decimals in token mint',
    },
    {
      code: 306,
      name: 'InsufficientCollateral',
      msg: 'Account has insufficient collateral.',
    },
    {
      code: 307,
      name: 'SufficientCollateral',
      msg: 'Account has sufficient collateral.',
    },
    {
      code: 308,
      name: 'InvalidTokenConfiguration',
      msg: 'Invalid token configuration.',
    },
    {
      code: 309,
      name: 'InvalidTokenId',
      msg: 'Invalid token id.',
    },
  ],
};
