import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Button, Drawer, Modal, Progress, Tooltip, Typography, message } from 'antd';
import { catchError } from 'features/Error/Error.program';
import { ReactComponent as InfoSVG } from 'features/Statistics/StatisticItem/info.svg';
import { useIsMobile } from 'features/UI/hooks';
import { ReactComponent as CloseSVG } from 'icons/close.svg';
import { ReactComponent as OxySVG } from 'icons/oxy.svg';
import { LoadingStatus } from 'interfaces/Types';
import moment from 'moment-timezone';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Textfit } from 'react-textfit';
import { numberToFixed } from 'utils';
import { getFullTime, getLeftTime, isPast } from '../../helpers/Staking.helper';
import { OXY_DECIMALS, OXY_RATE, StakingStatus } from '../../models/Staking.model';
import { setSelectedPool, setSelectedPoolVisible, stakingClaim, stakingClaimAllAvailable, stakingEarlyWithdraw, stakingInstantWithdraw } from '../../state/Staking.actions';
import { selectIsPoolDetailsVisible, selectPoolDetails } from '../../state/Staking.selector';

moment.tz.setDefault('UTC');

export function StakingCardDetails(): ReactElement {
  const countIn = process.env.REACT_APP_OXY_COUNT as any;

  const dispatch = useDispatch();
  const wallet = useWallet();
  const { connection } = useConnection();
  const isMobile = useIsMobile();

  const pool = useSelector(selectPoolDetails);
  const isVisible = useSelector(selectIsPoolDetailsVisible);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(LoadingStatus.Idle);
  const [isLoadingNow, setIsLoadingNow] = useState(LoadingStatus.Idle);

  const handleDrawerClose = useCallback(() => {
    dispatch(setSelectedPoolVisible(!isVisible));
    dispatch(setSelectedPool({}));
  }, [dispatch, isVisible]);

  const getStatusType = (status: string): string => {
    switch (status) {
      case StakingStatus.Active:
        return 'Staking';
      case StakingStatus.EarlyWithdrawal:
        return 'Unstaking';
      case StakingStatus.WaitingForUnlockTokens:
        return 'Finished';
      default:
        return '';
    }
  };

  const leftTime = useMemo(() => pool && getLeftTime(pool.releaseTimestamp, countIn), [countIn, pool]);
  const fullTime = useMemo(() => pool && getFullTime(pool.startTimestamp, pool.releaseTimestamp, countIn), [countIn, pool]);
  const getDailyTokensAmount = useMemo((): number => {
    if (!pool) {
      return;
    }
    const today = moment();
    const pastDays = today.diff(moment.unix(pool.releaseTimestamp), countIn);
    const cyclesCount = parseInt((pastDays / pool.unlockInterval).toFixed(0), 10);
    const claimedUnlockedAmount = (pool.claimedAmount - pool.lockedInterest - pool.stakedAmount);
    const amount = (cyclesCount * pool.dailyUnlockedInterest) - claimedUnlockedAmount;

    return numberToFixed(amount, OXY_DECIMALS);
  }, [pool, countIn]);

  const getPercents = (start: number, end: number): number => {
    let amount = (((100 * Math.abs(leftTime)) / fullTime));

    if (amount > 100) {
      amount = 100;
    } else {
      amount = 100 - amount;
    }

    if (isPast(end, countIn)) {
      amount = 100;
    }

    return parseFloat(amount.toFixed(2));
  };

  const handleClaim = useCallback(async (): Promise<void> => {
    setIsLoading(LoadingStatus.Loading);
    try {
      await dispatch(stakingClaim(connection, wallet, pool));

      let claimedAmountInner: number;
      if (pool.state === StakingStatus.EarlyWithdrawal) {
        claimedAmountInner = pool.stakedAmount;
      } else {
        claimedAmountInner = pool.stakedAmount + pool.interest;
      }

      message.success(`Successfully claimed ${numberToFixed(claimedAmountInner, OXY_DECIMALS)} OXY`);
      setIsLoading(LoadingStatus.Success);
      handleDrawerClose();
    } catch (e) {
      catchError(e);
      setIsLoading(LoadingStatus.Fail);
    }
  }, [connection, dispatch, handleDrawerClose, pool, wallet]);

  const handleInstantWithdraw = useCallback(async (): Promise<void> => {
    setIsLoadingNow(LoadingStatus.Loading);
    try {
      await dispatch(stakingInstantWithdraw(connection, wallet, pool));
      message.success(`Successfully withdrawn ${numberToFixed(pool.stakedAmount - (pool.stakedAmount * 0.05), OXY_DECIMALS)} OXY`);
      setIsLoadingNow(LoadingStatus.Success);
      handleDrawerClose();
      setIsModalVisible(false);
    } catch (e) {
      catchError(e);
      setIsLoadingNow(LoadingStatus.Fail);
    }
  }, [connection, dispatch, handleDrawerClose, pool, wallet]);

  const handleDailyWithdraw = useCallback(async (): Promise<void> => {
    setIsLoading(LoadingStatus.Loading);
    try {
      await dispatch(stakingClaimAllAvailable(connection, wallet, pool));
      message.success(`Successfully withdrawn ${getDailyTokensAmount} OXY`);
      setIsLoading(LoadingStatus.Success);
      handleDrawerClose();
    } catch (e) {
      catchError(e);
      setIsLoading(LoadingStatus.Fail);
    }
  }, [connection, dispatch, getDailyTokensAmount, handleDrawerClose, pool, wallet]);

  const handleEarlyWithdrawal = useCallback(async (): Promise<void> => {
    setIsLoading(LoadingStatus.Loading);
    try {
      await dispatch(stakingEarlyWithdraw(connection, wallet, pool));
      message.success('Your stake will be available in 7 days.');
      setIsLoading(LoadingStatus.Success);
      handleDrawerClose();
    } catch (e) {
      catchError(e);
      setIsLoading(LoadingStatus.Fail);
    }
  }, [connection, dispatch, handleDrawerClose, pool, wallet]);

  const handleModalClose = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  if (!pool) {
    return <></>;
  }

  return (
    <>
      <Modal
        title=""
        className="stake-modal-instant"
        width="325px"
        visible={isModalVisible}
        cancelButtonProps={{ size: 'large', shape: 'round' }}
        okButtonProps={{ size: 'middle', shape: 'round' }}
        okText="Withdraw"
        onCancel={handleModalClose}
        onOk={handleInstantWithdraw}
        confirmLoading={isLoadingNow === LoadingStatus.Loading}
        centered
      >
        <Typography.Text style={{ textAlign: 'center', display: 'block' }}>
          <strong>
            You are withdrawing
            {' '}
            {pool.stakedAmount - (pool.stakedAmount * 0.05)}
            {' '}
            OXY.
            Instant withdrawal fee of
            {' '}
            {(pool.stakedAmount * 0.05)}
            {' '}
            OXY applies.
            {' '}
            <br />
            You will forgo accrued yield.
          </strong>
        </Typography.Text>
      </Modal>
      <Drawer
        placement="right"
        closable={false}
        onClose={handleDrawerClose}
        visible={isVisible}
        key="right"
        width={350}
        title={(
          <>
            <div className="stake-pool-details-header">
              <div className="stake-header-details-left">
                <OxySVG />
                <div>
                  <Textfit forceSingleModeWidth={false} max={20}>
                    <strong>{pool.stakedAmount}</strong>
                    <span className="stake-header-token">OXY</span>
                  </Textfit>
                </div>
              </div>
              <span className="stake-header-details-status">
                {getStatusType(pool.state)}
              </span>
            </div>
            {isMobile && <CloseSVG style={{ width: '14px', height: '12px' }} onClick={handleDrawerClose} />}
          </>
      )}
      >
        <div className={`stake-pool-content ${pool.state === StakingStatus.WaitingForUnlockTokens ? 'finished-drawer' : ''}`}>
          <div className="stake-pool-item">
            <Typography.Text className="stake-pool-title">{`${pool.rate / OXY_RATE}%`}</Typography.Text>
            <Typography.Text className="stake-pool-text">
              <span>APY</span>
              <Tooltip title="Annual Percentage Yield" placement="bottom"><InfoSVG /></Tooltip>
            </Typography.Text>
          </div>
          <div className="stake-pool-item">
            <Typography.Text className="stake-pool-title">{`${numberToFixed(pool.interest + pool.lockedInterest, OXY_DECIMALS)} OXY`}</Typography.Text>
            <span className="stake-pool-sub">
              {pool.lockedInterest}
              <span>{` + ${pool.interest}`}</span>
            </span>
            <Typography.Text className="stake-pool-text">
              <span>Yield to be earned</span>
              <Tooltip
                overlayStyle={{ whiteSpace: 'pre-line' }}
                title={`Total staked yield
                            Locked tokens + Unlocked tokens
                            
                            Locked OXY unlocks linearly every day until 18/12/2026`}
                placement="bottom"
              >
                <InfoSVG />
              </Tooltip>
            </Typography.Text>
          </div>
          <div className="stake-pool-item">
            <Typography.Text className="stake-pool-title">
              <strong className="blue">
                {leftTime <= 0 ? fullTime : fullTime - Math.abs(leftTime)}
              </strong>
              {` of ${fullTime} ${countIn}`}
            </Typography.Text>
            <div className="stake-pool-progress">
              <Progress strokeColor="#2260E5" type="line" percent={getPercents(pool.startTimestamp, pool.releaseTimestamp)} />
              <span>{moment.unix(pool.releaseTimestamp).format('DD/MM/YY')}</span>
              <Tooltip placement="bottom" title="Scheduled availability date"><InfoSVG className="stake-pool-icon" /></Tooltip>
            </div>
            <Typography.Text className="stake-pool-text">
              <span>Staking progress</span>
            </Typography.Text>
          </div>

          {pool.state === StakingStatus.WaitingForUnlockTokens && (
          <>
            <div className="stake-pool-item">
              <Typography.Text className="stake-pool-title">
                {pool.stakedAmount}
                {' '}
                +
                {' '}
                {numberToFixed(pool.claimedAmount - pool.stakedAmount, OXY_DECIMALS)}
                {' '}
                OXY
              </Typography.Text>
              <Typography.Text className="stake-pool-text">
                <span>Withdrawn</span>
              </Typography.Text>
            </div>
            <div className="stake-pool-item stake-pool-active-only">
              <Typography.Text className="stake-pool-title">
                {`${numberToFixed(pool.lockedInterest - (pool.claimedAmount - pool.stakedAmount - pool.interest), OXY_DECIMALS)} OXY`}
              </Typography.Text>
              <Typography.Text className="stake-pool-text">
                <span>Total balance of locked OXY</span>
                <Tooltip
                  overlayStyle={{ whiteSpace: 'pre-line' }}
                  title={`${numberToFixed(pool.dailyUnlockedInterest, OXY_DECIMALS)} OXY available for withdrawal daily until 18/12/2026`}
                  placement="bottom"
                >
                  <InfoSVG />
                </Tooltip>
              </Typography.Text>
            </div>
          </>
          )}

          {/* pool is active and end date not passed */}
          {pool.state === StakingStatus.Active && !isPast(pool.releaseTimestamp, countIn) && (
          <>
            <div className="stake-pool-item stake-pool-button">
              <Typography.Text className="stake-pool-text">
                If you unstake before scheduled availability date you will forgo accrued yield.
                <br />
                <br />
                Your stake will be available in 7 days.
              </Typography.Text>
              <Button block shape="round" size="large" type="primary" onClick={handleEarlyWithdrawal} loading={isLoading === LoadingStatus.Loading}>Unstake</Button>
            </div>
            <div className="stake-pool-item stake-pool-button">
              <Typography.Text className="stake-pool-text">
                Withdraw immediately for 5% fee (and forgo accrued yield).
              </Typography.Text>
              <Button
                block
                shape="round"
                size="large"
                type="ghost"
                onClick={(): void => setIsModalVisible(true)}
                loading={isLoadingNow === LoadingStatus.Loading}
              >
                Unstake Now
              </Button>
            </div>
          </>
          )}

          {/* pool is active or unstaking and it's end date passed */}
          {(pool.state === StakingStatus.Active || pool.state === StakingStatus.EarlyWithdrawal) && isPast(pool.releaseTimestamp, countIn) && (
          <div className="stake-pool-item stake-pool-button">
            <Typography.Text className="stake-pool-text">
              Available to withdraw:
              <br />
              {pool.state === StakingStatus.Active ? (
                <strong>{`${numberToFixed(pool.stakedAmount + pool.interest, OXY_DECIMALS)} OXY`}</strong>
              ) : (
                <strong>{`${pool.stakedAmount} OXY`}</strong>
              )}
            </Typography.Text>
            <Button
              type="primary"
              shape="round"
              block
              size="large"
              loading={isLoading === LoadingStatus.Loading}
              onClick={handleClaim}
            >
              Withdraw
            </Button>
          </div>
          )}

          {/* pool is unstaking and it's end date not passed */}
          {pool.state === StakingStatus.EarlyWithdrawal && !isPast(pool.releaseTimestamp, countIn) && (
          <>
            <div className="stake-pool-item stake-pool-button">
              <Typography.Text className="stake-pool-text">
                Withdraw immediately for 5% fee (and forgo accrued yield).
              </Typography.Text>
              <Button
                type="primary"
                shape="round"
                block
                size="large"
                loading={isLoadingNow === LoadingStatus.Loading}
                onClick={(): void => setIsModalVisible(true)}
              >
                Unstake Now
              </Button>
            </div>
          </>
          )}

          {/* pool is finished */}
          {pool.state === StakingStatus.WaitingForUnlockTokens && (
          <div className="stake-pool-item stake-pool-button">
            <Typography.Text className="stake-pool-text">
              OXY tokens available to withdraw:
              <br />
              <strong>{`${getDailyTokensAmount} `}</strong>
            </Typography.Text>

            <Button
              type="primary"
              shape="round"
              block
              size="large"
              loading={isLoading === LoadingStatus.Loading}
              onClick={handleDailyWithdraw}
            >
              Withdraw
            </Button>
          </div>
          )}

          <div className="staking-drawer-close">
            <Button size="large" block shape="round" onClick={handleDrawerClose}>Close</Button>
          </div>
        </div>
      </Drawer>
    </>
  );
}
