import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Button, Modal, Space, Tabs } from 'antd';
import { selectMarketTokens } from 'features/Market/state/Market.selector';
import { useIsMobile } from 'features/UI/hooks';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { marketTokensLoad } from '../../Market/state/Market.actions';
import { walletLoadBalances } from '../../Wallet/state/Wallet.actions';
import { Deposit } from '../Deposit/Deposit';
import { Withdraw } from '../Withdraw/Withdraw';
import './Popup.css';

const { TabPane } = Tabs;
export function SupplyOrBorrow({ visible, tokenPubkey, onClose, defaultTab }: {
  onClose: Function;
  tokenPubkey: string;
  visible: boolean;
  defaultTab?: 'deposit' | 'borrow' | 'withdraw';
}): ReactElement {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const wallet = useWallet();
  const tokens = useSelector(selectMarketTokens);
  const token = useMemo(() => tokens[tokenPubkey], [tokens, tokenPubkey]);
  const { connection } = useConnection();

  const [tab, setTab] = useState('borrow');

  const handleCancel = useCallback(() => {
    onClose();
    setTab('borrow');
  }, [onClose]);

  const handleTabSwitch = useCallback((e) => {
    setTab(e);
  }, []);

  const reloadAllData = useCallback(async () => {
    await dispatch(marketTokensLoad(connection));
    if (wallet.publicKey) await dispatch(walletLoadBalances(connection, wallet));
  }, [connection, dispatch, wallet]);

  useEffect(() => {
    if (defaultTab && defaultTab === 'deposit') {
      setTab('borrow');
    } else if (defaultTab && defaultTab === 'withdraw') {
      setTab('borrow');
    }
  }, [defaultTab]);

  return (
    <Modal
      closeIcon={null}
      visible={visible}
      title={null}
      onCancel={handleCancel}
      footer={null}
      maskTransitionName=""
      transitionName=""
      className="supply-or-borrow-modal"
      width="550px"
    >
      <Tabs className="modal-tabs" activeKey={tab} onChange={handleTabSwitch} centered>
        {/* DEPOSIT */}
        {/* <TabPane tab={<Space size={10} style={{ justifyContent: 'center' }}><span>Deposit</span></Space>} key="deposit" className="tab"> */}
        {/*  {tab === 'deposit' && ( */}
        {/*    <Deposit token={token} handleCancel={handleCancel} reloadAllData={reloadAllData} /> */}
        {/*  )} */}
        {/* </TabPane> */}

        {/* BORROW / WITHDRAW */}
        <TabPane tab={<Space size={10} style={{ justifyContent: 'center' }}><span>Withdraw</span></Space>} key="borrow" className="tab">
          {tab === 'borrow' && (
            <Withdraw token={token} handleCancel={handleCancel} reloadAllData={reloadAllData} />
          )}
        </TabPane>
      </Tabs>

      {isMobile && (
      <div style={{ padding: '0 20px 20px 20px' }}>
        <Button
          onClick={handleCancel}
          style={{ height: 50 }}
          className="close-btn"
          size="large"
          block
          shape="round"
          type="default"
        >
          Close
        </Button>
      </div>
      )}
    </Modal>
  );
}
