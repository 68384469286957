import { INTERNAL_DECIMALS } from 'client/mainAccount';
import { calculatorService } from 'features/Calculator/Calculator.service';
import { selectMarketTokens } from 'features/Market/state/Market.selector';
import { selectWalletBalances, selectWalletBorrowTotalUsd, selectWalletDepositTotalUsd } from 'features/Wallet/state/Wallet.selector';
import { useSelector } from 'react-redux';
import { numberToFixed } from 'utils';
import { percentFormat } from 'utils/formats';
import { Tabs } from './types';
import { getFormattedValue } from './utils';

export interface TableTokenData {
  avaliable: string | number;
  borrowApr: string;
  borrowed: string | number;
  borrowedProgram: string;
  decimals: number;
  depositApy: string;
  deposited: string | number;
  depositedProgram: string;
  formatDecimals: number;
  id: string;
  imageUrl: string;
  key: string;
  mintPubkey: string;
  name: string;
  symbol: string;
  totalAvailable: string | number;
  totalBorrowed: string | number;
  totalDeposited: string | number;
  userAssets: boolean;
  utilization: string;
  walletBalance: string | number;
  walletBalanceProgram: string;
}
export function useTableTokenData(selectedMarket: Tabs): TableTokenData[] {
  const dataTokens = useSelector(selectMarketTokens);
  const balances = useSelector(selectWalletBalances);
  const walletTotalDepositedUsd = useSelector(selectWalletDepositTotalUsd);
  const walletTotalBorrowedUsd = useSelector(selectWalletBorrowTotalUsd);

  const data = Object.values(dataTokens)?.map((token) => {
    let avaliable: number | string = 0;
    if (token?.available.usd > 0 && walletTotalDepositedUsd > 0) {
      const val = numberToFixed(
        calculatorService.getBorrowLimit(dataTokens[token.id], walletTotalDepositedUsd, walletTotalBorrowedUsd),
        dataTokens[token.id].decimals,
      );
      avaliable = getFormattedValue(
        { usd: numberToFixed(val * (token?.usdAmount || 0), 2, 'down'), token: val },
        dataTokens[token.id],
        selectedMarket,
      );
    }

    let borrowed = balances[token.id]?.borrowed ? getFormattedValue(balances[token.id].borrowed, token, selectedMarket, 'up') : 0;
    if (selectedMarket === Tabs.Tokens && balances[token.id]?.borrowed?.token > 0 && borrowed === 0) {
      borrowed = `<${1 / 10 ** token.formatDecimals}`;
    }
    if (selectedMarket === Tabs.Usd
      && numberToFixed(balances[token.id]?.borrowed?.token, token.decimals, 'down') > 0
      && borrowed === 0) {
      borrowed = '<$0.01';
    }

    let deposited = balances[token.id]?.deposited ? getFormattedValue(balances[token.id].deposited, token, selectedMarket, 'down') : 0;
    if (numberToFixed(balances[token.id]?.deposited?.token, token.decimals, 'down') > 0
      && deposited === 0) {
      deposited = selectedMarket === Tabs.Tokens ? `<${1 / 10 ** token.formatDecimals}` : '<$0.01';
    }

    return {
      id: token.id,
      symbol: token.symbol,
      key: token.id,
      imageUrl: token.imageUrl,
      name: token.name,
      mintPubkey: token.mintPubkey,
      userAssets: balances[token.id]?.borrowed?.usd > 0 || balances[token.id]?.deposited?.usd > 0,
      depositApy: percentFormat.format(token.depositApy),
      borrowApr: percentFormat.format(token.borrowApr),
      totalDeposited: getFormattedValue(token.deposited, token, selectedMarket),
      totalBorrowed: getFormattedValue(token.borrowed, token, selectedMarket),
      totalAvailable: getFormattedValue(token?.available, token, selectedMarket),
      utilization: `${percentFormat.format(token.utilization)} utilization`,
      deposited,
      borrowed,
      avaliable,
      walletBalance: balances[token.id]?.balance ? getFormattedValue(balances[token.id].balance, token, selectedMarket) : 0,
      depositedProgram: (balances[token.id]?.deposited.program || 0).toFixed(INTERNAL_DECIMALS),
      borrowedProgram: (balances[token.id]?.borrowed.program || 0).toFixed(INTERNAL_DECIMALS),
      walletBalanceProgram: (balances[token.id]?.balance.program || 0).toFixed(INTERNAL_DECIMALS),
      decimals: token.decimals,
      formatDecimals: token.formatDecimals,
    };
  });
  return data;
}
