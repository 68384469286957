import { createSlice } from '@reduxjs/toolkit';
import { StakingPool } from 'interfaces/Staking.interface';
import { LoadingStatus } from 'interfaces/Types';

export interface StakingSlice {
  mobileEntry: boolean;
  pool: {
    address: string;
    rewardOxyAccount: string;
  };
  poolStatistics: {
    avgLockedPeriod: number;
    totalRewards: number;
    totalStaked: number;
    totalStakers: number;
  };
  selectedPool: StakingPool;
  selectedPoolVisible: boolean;
  userOxyBalance: number;
  userPools: StakingPool[];
  userPoolsLoaded: LoadingStatus;
}

const initialState: StakingSlice = {
  pool: {
    address: '',
    rewardOxyAccount: '',
  },
  poolStatistics: {
    avgLockedPeriod: 0,
    totalRewards: 0,
    totalStaked: 0,
    totalStakers: 0,
  },
  userOxyBalance: 0,
  userPools: [],
  userPoolsLoaded: LoadingStatus.Loading,
  selectedPool: {
    address: '',
    claimedAmount: 0,
    contractNumber: 0,
    dailyUnlockedInterest: 0,
    duration: 0,
    effectiveRate: 0,
    interest: 0,
    lockedInterest: 0,
    rate: 0,
    releaseTimestamp: 0,
    stakedAmount: 0,
    startTimestamp: 0,
    state: '',
    unlockInterval: 0,
    user: '',
    userOxyAccount: '',
  },
  selectedPoolVisible: false,
  mobileEntry: false,
};

export const stakingSlice = createSlice({
  name: 'staking',
  initialState,
  reducers: {
    setPoolStatistics: (state, { payload }) => {
      state.poolStatistics = payload;
    },
    setPool: (state, { payload }) => {
      state.pool = payload;
    },
    setUserOxyBalance: (state, { payload }) => {
      state.userOxyBalance = payload;
    },
    setUserPools: (state, { payload }) => {
      state.userPools = [...payload];
    },
    setUserPoolLoaded: (state, { payload }) => {
      state.userPoolsLoaded = payload;
    },
    clearStakingInfo: (state) => {
      state.userOxyBalance = 0;
      state.userPools = [];
      state.userPoolsLoaded = LoadingStatus.Loading;
    },
    setSelectedPool: (state, { payload }) => {
      state.selectedPool = state.userPools.find((pool) => pool.address === payload);
    },
    setSelectedPoolVisible: (state, { payload }) => {
      state.selectedPoolVisible = payload;
    },
    setMobileEntry: (state, { payload }) => {
      state.mobileEntry = payload;
    },
  },
});

export const stakingReducer = stakingSlice.reducer;
