// @ts-nocheck
import { Space, Table, Typography } from 'antd';
import link from 'icons/link.svg';
import { Tx } from 'interfaces/History.interface';
import { ReactElement } from 'react';
import { timeHumanReadableFormat } from 'utils/formats';
import './History.css';

const { Text } = Typography;

export function HistoryTableMobile({
  transactions,
}: {
  transactions: Tx[];
}): ReactElement {
  if (transactions.length > 0) {
    return (
      <Table
        className="table"
        columns={[
          {
            title: 'Timestamp',
            dataIndex: 'time',
            key: 'time',
            width: '25%',
            render: (time): ReactElement => (
              <Text>{timeHumanReadableFormat(time)}</Text>
            ),
          },
          {
            title: 'Transaction Type',
            dataIndex: 'name',
            key: 'name',
            render: (text, record): ReactElement => (
              <Text style={{ textTransform: 'capitalize' }}>
                {record.type}
              </Text>
            ),
          },
          {
            title: 'Balance changes',
            dataIndex: 'postTokenBalances',
            key: 'postTokenBalances',
            render: (_, record): ReactElement => (
              <Text>
                <Space align="center" size={10}>
                  <img alt="icon" src={record.tokenImg} className="icon" />
                  <Text>
                    {record.amount}
                    {' '}
                    {record.symbol}
                  </Text>
                </Space>
              </Text>
            ),
          },
          {
            title: 'Txn details',
            dataIndex: 'address',
            key: 'address',
            width: '125px',
            render: (_, record): ReactElement => (
              <a href={`https://solscan.io/tx/${record.key}`} target="_blank" rel="noreferrer"><img alt="link" src={link} /></a>
            ),
          },
        ]}
        dataSource={transactions}
        rowClassName={(_, index): string => ((index % 2 === 0) ? 'odd' : 'even')}
        pagination={{ hideOnSinglePage: true, showSizeChanger: false }}
      />
    );
  }
  return null;
}
