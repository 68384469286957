import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Button, Slider, Typography, message } from 'antd';
import { BetterInputNumber } from 'features/UI';
import { OxyReward } from 'interfaces/Staking.interface';
import moment from 'moment-timezone';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingStatus } from 'interfaces/Types';
import { oxyFormatToken } from 'utils';
import { catchError } from 'features/Error/Error.program';
import { calculateRewards, getMonthsInDays } from '../../helpers/Staking.helper';
import {
  INTEGER_LIMIT_AMOUNT, INTEGER_LIMIT_DAYS,
  MAX_OXY_AMOUNT, MIN_OXY_AMOUNT, OXY_DECIMALS, OXY_SLIDER_STEP, SEVEN_DAYS, STAKING_PERIOD_LIST, TWO_YEARS,
} from '../../models/Staking.model';
import { stakingStake } from '../../state/Staking.actions';
import { selectStakingUserBalance } from '../../state/Staking.selector';
import { StakingEarnings } from '../StakingEarnings/StakingEarnings';

moment.tz.setDefault('UTC');

export function StakingForm(): ReactElement {
  const userOxyBalance = useSelector(selectStakingUserBalance);
  const wallet = useWallet();
  const dispatch = useDispatch();
  const stakingListSorted = useMemo(() => Object.keys(STAKING_PERIOD_LIST).sort(), []);

  const [period, setPeriod] = useState(parseFloat(stakingListSorted[0]));
  const [periodDays, setPeriodDays] = useState(SEVEN_DAYS);
  const [amount, setAmount] = useState(MIN_OXY_AMOUNT);
  const [oxyReward, setOxyReward] = useState<OxyReward>({ oxyCount: 0, date: moment().format('MMMM YYYY'), apy: 0 });
  const [isLoading, setIsLoading] = useState(LoadingStatus.Idle);

  const { connection } = useConnection();

  // eslint-disable-next-line max-len
  const disabledStakeButton = useMemo(() => !wallet.publicKey || (!amount || amount === 0 || amount < MIN_OXY_AMOUNT) || !periodDays || userOxyBalance === 0 || amount > userOxyBalance, [amount, periodDays, userOxyBalance, wallet.publicKey]);

  // days slider change
  const handlePeriodSliderChange = useCallback((e) => {
    setPeriod(e);
    setPeriodDays(getMonthsInDays(e));
  }, []);

  // days input change
  const handlePeriodChange = useCallback((e) => {
    if (e > TWO_YEARS) {
      setPeriodDays(TWO_YEARS);
    } else {
      setPeriodDays(e);
    }

    const countedPeriod = (e / 7) + OXY_SLIDER_STEP;
    if (countedPeriod >= MIN_OXY_AMOUNT) {
      setPeriod(countedPeriod - OXY_SLIDER_STEP);
    } else {
      setPeriod(countedPeriod);
    }
  }, []);

  const handleAmountChange = useCallback((e) => {
    if (e >= MAX_OXY_AMOUNT) {
      setAmount(MAX_OXY_AMOUNT);
    } else {
      setAmount(e);
    }
  }, []);

  const handleDepositMax = useCallback(() => {
    if (userOxyBalance > MAX_OXY_AMOUNT) {
      setAmount(MAX_OXY_AMOUNT);
    } else {
      setAmount(userOxyBalance);
    }
  }, [userOxyBalance]);

  const handleStakeAction = useCallback(async () => {
    if (!wallet.publicKey) {
      message.error('Connect your wallet!');
      return;
    }

    if (userOxyBalance === 0 || userOxyBalance < amount) {
      message.error('Not enough tokens!');
      return;
    }

    if (amount < MIN_OXY_AMOUNT) {
      message.error(`Minimum amount to stake is ${MIN_OXY_AMOUNT} OXY`);
      return;
    }

    if (amount > MAX_OXY_AMOUNT) {
      message.error(`Maximum amount to stake is ${MAX_OXY_AMOUNT} OXY`);
      return;
    }

    if (periodDays < SEVEN_DAYS) {
      message.error(`Minimum stake period is ${SEVEN_DAYS} days`);
      return;
    }

    setIsLoading(LoadingStatus.Loading);

    try {
      await dispatch(stakingStake(connection, wallet, amount, periodDays));
      setIsLoading(LoadingStatus.Success);
      setAmount(0);
      setPeriodDays(SEVEN_DAYS);
      setPeriod(parseFloat(stakingListSorted[0]));
      message.success(`Successfully staked ${amount} OXY for ${periodDays} days`);
    } catch (error) {
      catchError(error);
      setIsLoading(LoadingStatus.Fail);
    }
  }, [amount, connection, dispatch, periodDays, stakingListSorted, userOxyBalance, wallet]);

  useEffect(() => {
    const rewards: any = calculateRewards(periodDays, amount);
    setOxyReward(rewards);
  }, [periodDays, amount]);

  return (
    <div className="staking-flex">
      <div className="staking-form">
        <div className="input-holder">
          <label htmlFor="amount">Amount, OXY</label>
          <BetterInputNumber
            id="amount"
            integerLimit={INTEGER_LIMIT_AMOUNT}
            decimals={OXY_DECIMALS}
            onChange={handleAmountChange}
            placeholder="Amount, OXY"
            value={amount}
          />
          {wallet.publicKey && (
          <span onClick={handleDepositMax} className="staking-deposit-all">
            Stake all
            <br />
            <strong>{`${oxyFormatToken(userOxyBalance)} Oxy`}</strong>
          </span>
          )}
        </div>
        <div className="input-holder">
          <label htmlFor="period">Stake Period, Days</label>
          <BetterInputNumber
            id="period"
            integerLimit={INTEGER_LIMIT_DAYS}
            decimals={OXY_DECIMALS}
            onChange={handlePeriodChange}
            placeholder="Stake Period, Days"
            value={periodDays}
          />
        </div>
        <div className="input-holder last-item">
          <Slider
            marks={STAKING_PERIOD_LIST}
            value={period}
            onChange={handlePeriodSliderChange}
            min={parseFloat(stakingListSorted[0])}
            max={parseFloat(stakingListSorted[stakingListSorted.length - 1])}
            tooltipVisible={false}
            step={OXY_SLIDER_STEP}
          />
        </div>

        {wallet.publicKey ? (
          <Button
            type="primary"
            size="large"
            block
            shape="round"
            className="staking-button"
            onClick={handleStakeAction}
            disabled={disabledStakeButton}
            loading={isLoading === LoadingStatus.Loading}
          >
            Stake
          </Button>
        ) : (
          <WalletMultiButton className="connectWallet">Connect Wallet</WalletMultiButton>
        )}

        <Typography.Text className="staking-conditions">
          Unstake OXY at anytime with 7-day notice.
          <br />
          Instant withdrawal for 5% fee.
          <br />
          Early unstaking means no yield for whole period.
        </Typography.Text>
      </div>

      {oxyReward && <StakingEarnings date={oxyReward.date} apy={oxyReward.apy} oxyCount={oxyReward.oxyCount} />}
    </div>
  );
}
