import * as anchor from '@project-serum/anchor';
import { WalletContextState } from '@solana/wallet-adapter-react';
import { Connection, PublicKey } from '@solana/web3.js';
import BorrowLending from 'client/borrowLending';
import { Program } from 'client/program';
import { selectMarketTokens } from 'features/Market/state/Market.selector';
import { selectWalletBalancesLoadingStatus, selectWalletPublicKey } from 'features/Wallet/state/Wallet.selector';
import { numberToFixed } from 'utils';
import { AppDispatch, AppGetState } from 'store/store';
import { LoadingStatus } from 'interfaces/Types';
import { TokenData } from 'interfaces/Token.interface';
import { WalletBalances } from 'interfaces/Wallet.interface';
import { calculatorService } from 'features/Calculator/Calculator.service';
import { walletSlice } from './Wallet.slice';

export const { setPublicKey, setBalances, clearBalances, clearPublicKey, setTotals, setBalancesLoadingStatus } = walletSlice.actions;

export const walletLoadBalances = (
  connection: Connection,
  wallet: WalletContextState,
  tokens?: { [key: string]: TokenData },
) => async (dispatch: AppDispatch, getState: AppGetState): Promise<void> => {
  try {
    const walletBalancesLoadingStatus = selectWalletBalancesLoadingStatus(getState());
    if (walletBalancesLoadingStatus === LoadingStatus.Loading) {
      return;
    }

    dispatch(setBalancesLoadingStatus(LoadingStatus.Loading));
    if (!wallet.publicKey) {
      dispatch(clearBalances());
      dispatch(setBalancesLoadingStatus(LoadingStatus.Idle));
      return;
    }

    const provider = await new anchor.Provider(connection, wallet, { commitment: 'confirmed' });
    const program = await Program.getProgram(provider);
    // @ts-ignore
    const lending = await BorrowLending.create(program);

    tokens = selectMarketTokens(getState()) ?? tokens;
    const [walletBalances, marginBalances = {}] = await Promise.all([
      lending?.getWalletBalances(),
      lending.getMarginBalances({ mainData: lending.mainAccount.staticData }),
    ]);

    const balances: { [key: string]: WalletBalances } = {};

    // console.log('----------------------------------------');

    Object.values(tokens).forEach((token) => {
      const depositedBalance = marginBalances[token.id]?.uiAmount > 0 ? marginBalances[token.id].uiAmount : 0;
      const borrowedBalance = Math.abs(marginBalances[token.id]?.uiAmount < 0 ? marginBalances[token.id].uiAmount : 0);
      const borrowMax = borrowedBalance > 0 ? Math.max(calculatorService.getMinimumPaymentUnit(), borrowedBalance) : 0;
      // console.log(token.symbol, depositedBalance.toFixed(13), borrowedBalance.toFixed(13));

      const balance = {
        deposited: {
          token: depositedBalance,
          usd: numberToFixed(numberToFixed(depositedBalance, token.decimals, 'down') * token.usdAmount, 2, 'down'),
          program: depositedBalance,
        },
        borrowed: {
          token: borrowMax,
          usd: numberToFixed(numberToFixed(borrowedBalance, token.decimals, 'up') * token.usdAmount, 2, 'up'),
          program: borrowedBalance,
        },
        balance: {
          token: walletBalances[token.id].uiAmount,
          usd: numberToFixed(numberToFixed(walletBalances[token.id].uiAmount, token.decimals) * token.usdAmount, 2, 'down'),
          program: walletBalances[token.id].uiAmount,
        },
      };

      balances[token.id] = balance;
    });

    // console.log('----------------------------------------');

    const walletPubKey = selectWalletPublicKey(getState());
    if (!walletPubKey) {
      return;
    }

    dispatch(setBalances(balances));
    dispatch(setBalancesLoadingStatus(LoadingStatus.Success));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    dispatch(setBalancesLoadingStatus(LoadingStatus.Fail));
  }
};

export const walletDepositToken = async (connection: Connection, wallet: WalletContextState, mintPublicKey: string, amount: number): Promise<void> => {
  const provider = await new anchor.Provider(connection, wallet, { commitment: 'confirmed' });
  const program = await Program.getProgram(provider);
  // @ts-ignore
  const lending = await BorrowLending.create(program);
  await lending.deposit(new PublicKey(mintPublicKey), amount);
};

export const walletWithdrawToken = async (connection: Connection, wallet: WalletContextState, mintPublicKey: string, amount: number): Promise<void> => {
  const provider = await new anchor.Provider(connection, wallet, { commitment: 'confirmed' });
  const program = await Program.getProgram(provider);
  // @ts-ignore
  const lending = await BorrowLending.create(program);
  await lending.withdraw(new PublicKey(mintPublicKey), amount);
};
