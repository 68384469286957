import { Select } from 'antd';
import { ConnectionMap, NetworksTypes } from 'features/Connection/models/Connection.model';
import { setConnection } from 'features/Connection/state/Connection.actions';
import { selectCurrentNetwork } from 'features/Connection/state/Connection.selector';
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ConnectionSelector.css';

const { Option } = Select;

export function ConnectionSelector(): ReactElement {
  const dispatch = useDispatch();
  const currentConnection = useSelector(selectCurrentNetwork);
  const handleChange = useCallback((value) => {
    dispatch(setConnection(value));
  }, [dispatch]);

  const connectionList = useMemo(() => ConnectionMap[process.env.REACT_APP_CONNECTION_NETWORK as NetworksTypes], []);

  useEffect(() => {
    if (!currentConnection || !connectionList.find(({ url }) => url === currentConnection)) {
      dispatch(setConnection(process.env.REACT_APP_CONNECTION_ENDPOINT));
    }
  }, [connectionList, currentConnection, dispatch]);

  return (
    <Select className="connection-selector" defaultValue={currentConnection} onChange={handleChange}>
      {connectionList.map(({ title, url }) => (
        <Option key={url} value={url}>{title}</Option>
      ))}
    </Select>
  );
}
