import { TokenAsset, TokenData } from 'interfaces/Token.interface';
import { dollarFormatMarketNumeral, numberToFixed, tokenFormatNumeral } from 'utils';
import { Tabs } from './types';

export const getFormattedValue = (assets: TokenAsset, token?: TokenData, selectedMarket?: Tabs, direction?: 'up' | 'down'): string | number => {
  if (!assets) {
    return 0;
  }

  if (selectedMarket === 'tokens') {
    const amount = numberToFixed(assets.token, token?.formatDecimals, direction);
    if (assets.token === 0 || amount === 0) {
      return 0;
    }
    return tokenFormatNumeral(amount);
  }

  if (assets.usd === 0) {
    return 0;
  }
  return dollarFormatMarketNumeral(assets.usd);
};
