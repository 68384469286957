import { TokensSymbols } from 'interfaces/Types';

// https://docs.mango.markets/mango/token-specs#spot-market-leverage
export const SPOT_MARKET_LEVERAGE = {
  [TokensSymbols.Btc]: {
    InitialLeverage: 5,
    MaintenanceLeverage: 10,
    LiquidationFee: 5.00,
  },
  [TokensSymbols.Eth]: {
    InitialLeverage: 5,
    MaintenanceLeverage: 10,
    LiquidationFee: 5.00,
  },
  [TokensSymbols.Sol]: {
    InitialLeverage: 5,
    MaintenanceLeverage: 10,
    LiquidationFee: 5.00,
  },
  [TokensSymbols.Usdt]: {
    InitialLeverage: 10,
    MaintenanceLeverage: 20,
    LiquidationFee: 2.50,

  },
  [TokensSymbols.Usdc]: {
    InitialLeverage: 10,
    MaintenanceLeverage: 20,
    LiquidationFee: 2.50,
  },
  [TokensSymbols.Srm]: {
    InitialLeverage: 5,
    MaintenanceLeverage: 10,
    LiquidationFee: 5.00,

  },
};

// https://docs.mango.markets/mango/token-specs#asset-weights
export const ASSET_WEIGHTS = {
  [TokensSymbols.Btc]: {
    InitAssetWeight: 0.8,
    MaintAssetWeight: 0.9,
  },
  [TokensSymbols.Eth]: {
    InitAssetWeight: 0.8,
    MaintAssetWeight: 0.9,
  },
  [TokensSymbols.Sol]: {
    InitAssetWeight: 0.8,
    MaintAssetWeight: 0.9,
  },
  [TokensSymbols.Usdt]: {
    InitAssetWeight: 0.9,
    MaintAssetWeight: 0.95,
  },
  [TokensSymbols.Srm]: {
    InitAssetWeight: 0.8,
    MaintAssetWeight: 0.9,
  },
  [TokensSymbols.Usdc]: {
    InitAssetWeight: 1,
    MaintAssetWeight: 1,
  },
};

// https://docs.mango.markets/mango/token-specs#asset-interest-rates
export const ASSET_INTEREST_RATES = {
  [TokensSymbols.Usdc]: {
    OptimalRate: 0.08,
    MaxRate: 1.5,
    OptimalUtilization: 0.7,
  },
  [TokensSymbols.Btc]: {
    OptimalRate: 0.035,
    MaxRate: 0.875,
    OptimalUtilization: 0.7,
  },
  [TokensSymbols.Eth]: {
    OptimalRate: 0.025,
    MaxRate: 0.625,
    OptimalUtilization: 0.7,
  },
  [TokensSymbols.Sol]: {
    OptimalRate: 0.025,
    MaxRate: 0.625,
    OptimalUtilization: 0.7,
  },
  [TokensSymbols.Srm]: {
    OptimalRate: 0.1,
    MaxRate: 2.5,
    OptimalUtilization: 0.7,
  },
  [TokensSymbols.Usdt]: {
    OptimalRate: 0.05,
    MaxRate: 1.25,
    OptimalUtilization: 0.7,
  },
};
