import * as anchor from '@project-serum/anchor';
import { PublicKey, SYSVAR_RENT_PUBKEY, SystemProgram } from '@solana/web3.js';

class MarginAccount {
  // @ts-ignore
  address: PublicKey;
  // @ts-ignore
  mainAccountAddress: PublicKey;
  // @ts-ignore
  bump: number;
  program: anchor.Program;

  constructor(program: anchor.Program) {
    this.program = program;
  }

  static async create(program: anchor.Program, mainAccountAddress: PublicKey): Promise<MarginAccount> {
    const marginAccount = new MarginAccount(program);
    marginAccount.mainAccountAddress = mainAccountAddress;

    [marginAccount.address, marginAccount.bump] = await PublicKey.findProgramAddress(
      [
        anchor.utils.bytes.utf8.encode('margin-account'),
        marginAccount.mainAccountAddress.toBuffer(),
        program.provider.wallet.publicKey.toBuffer(),
      ],
      program.programId,
    );
    return marginAccount;
  }

  async isInitialized(): Promise<boolean> {
    return (await this.getData()) !== undefined;
  }

  async getData(): Promise<Object | undefined> {
    try {
      return await this.program.account.marginAccount.fetch(this.address);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      return undefined;
    }
  }

  async initialize(): Promise<string> {
    return this.program.rpc.initializeMargin(this.bump, {
      accounts: {
        marginAccount: this.address,
        mainAccount: this.mainAccountAddress,
        authority: this.program.provider.wallet.publicKey,
        systemProgram: SystemProgram.programId,
        rent: SYSVAR_RENT_PUBKEY,
      },
    });
  }
}

export default MarginAccount;
