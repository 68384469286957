import { Connection, PublicKey } from '@solana/web3.js';
import { LoadingStatus } from 'interfaces/Types';
import { AppDispatch } from 'store/store';
import { unpackTransaction } from '../program/History.program';
import { historySlice } from './History.slice';

export const { setHistoryTransactions, clearHistoryTransactions } = historySlice.actions;
const MAX_LIMIT_TX_PER_REQUEST = 200;

export const historyLoadUserTransactions = (connection: Connection, publicKey: PublicKey) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(clearHistoryTransactions());
  dispatch(setHistoryTransactions({ loadingStatus: LoadingStatus.Loading }));
  const userTransactions = await connection.getConfirmedSignaturesForAddress2(publicKey, {}, 'confirmed');
  const userTxArray = userTransactions.map((item) => item.signature);
  const listChunks = [];
  while (userTxArray.length) {
    listChunks.push(userTxArray.splice(0, Math.min(MAX_LIMIT_TX_PER_REQUEST, userTxArray.length)));
  }

  // eslint-disable-next-line no-restricted-syntax
  for await (const listChunk of listChunks) {
    const confirmedTransactions = await connection.getParsedConfirmedTransactions(listChunk);
    const txDescriptions = confirmedTransactions.map((tx) => unpackTransaction(tx));
    const list = await Promise.all(txDescriptions);
    const transactions = list.filter((item) => !!item);
    dispatch(setHistoryTransactions({ transactions, loadingStatus: LoadingStatus.Loading }));
  }
  dispatch(setHistoryTransactions({ loadingStatus: LoadingStatus.Success }));
};
