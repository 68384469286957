import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { ReactElement, useCallback } from 'react';
import './TermsCheckbox.css';

export function TermsCheckbox({ onChange, checked }: {
  checked: boolean;
  onChange: (value: boolean) => void;
}): ReactElement {
  const handleChange = useCallback((e: CheckboxChangeEvent) => {
    onChange(e.target.checked);
  }, [onChange]);
  return (
    <div className="terms-checkbox">
      <Checkbox onChange={handleChange} checked={checked}>
        I agree to the Oxygen Light
        {' '}
        <a target="_blank" href="https://docs.light.oxygen.org/#oxygen-light-terms-and-conditions" rel="noreferrer">
          terms and conditions
        </a>
      </Checkbox>
    </div>
  );
}
