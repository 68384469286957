import * as anchor from '@project-serum/anchor';
import { NodeWallet } from '@project-serum/common';
import { Account, Connection } from '@solana/web3.js';

class Provider {
  static async guest(network: string): Promise<anchor.Provider> {
    const wallet = new NodeWallet(new Account());
    const opts = anchor.Provider.defaultOptions();
    const connection = new Connection(network, opts.preflightCommitment);
    return new anchor.Provider(connection, wallet as any, opts);
  }
}

export default Provider;
