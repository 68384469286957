// @ts-nocheck
import { Collapse, Typography } from 'antd';
import { TokenIcon } from 'features/Market/components/TokenIcon/TokenIcon';
import { ReactComponent as ArrowSVG } from 'icons/arrow.svg';
import { ReactComponent as LinkSVG } from 'icons/link-mobile.svg';
import link from 'icons/link.svg';
import { Tx } from 'interfaces/History.interface';
import { ReactElement } from 'react';
import { timeHumanReadableFormat } from 'utils/formats';
import './History.css';

const { Text } = Typography;
const { Panel } = Collapse;

export function HistoryTable({
  transactions,
}: {
  transactions: Tx[];
}): ReactElement {
  if (transactions.length > 0) {
    return (
      <div className="mobile-market-table">
        {transactions?.map((transaction, index) => (
          <Collapse accordion key={transaction.key}>
            <Panel
              header={(
                <div className="market-row">
                  <div className="market-id" style={{ alignItems: 'center', gap: '10px' }}>
                    {transaction.type === 'withdraw' ? <LinkSVG className="withdraw" /> : <LinkSVG />}
                    <TokenIcon url={transaction.tokenImg} tokenName={transaction.symbol} />
                    <Text className="bolder">{transaction.symbol}</Text>
                  </div>
                  <div>
                    <span style={{ marginRight: '15px', fontWeight: '500' }}>
                      {transaction.amount.toString()}
                    </span>
                    <ArrowSVG className="arrow" />
                  </div>
                </div>
              )}
              key={`history-${index}`}
            >
              <p className="history-date">
                <Text className="tablehint" style={{ textTransform: 'capitalize' }}>
                  {transaction.type}
                </Text>
                <Text>{timeHumanReadableFormat(transaction.time)}</Text>
              </p>
              <a href={`https://solscan.io/tx/${transaction.key}`} target="_blank" className="history-link" rel="noreferrer">
                <img alt="link" src={link} />
                TX Details
              </a>
            </Panel>
          </Collapse>
        ))}
      </div>
    );
  }
  return null;
}
