import { Button, Typography } from 'antd';
import { StakingPool } from 'interfaces/Staking.interface';
import moment from 'moment';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as FinishSVG } from 'icons/finished.svg';
import { numberToFixed } from 'utils';
import { getLeftTime } from '../../helpers/Staking.helper';
import { OXY_DECIMALS, OXY_RATE, StakingStatus } from '../../models/Staking.model';
import { stakingSelectPool } from '../../state/Staking.actions';
import { selectPoolDetails } from '../../state/Staking.selector';

export function StakingCard({ pool }: {
  pool: StakingPool;
}): ReactElement {
  const { address, stakedAmount, rate, releaseTimestamp, state, dailyUnlockedInterest, unlockInterval, claimedAmount, lockedInterest } = pool;
  const selectedPool = useSelector(selectPoolDetails);

  const dispatch = useDispatch();
  const countIn = process.env.REACT_APP_OXY_COUNT as any;

  const handleDetails = useCallback(() => {
    dispatch(stakingSelectPool(address));
  }, [address, dispatch]);

  const getDailyTokensAmount = useMemo((): number => {
    const today = moment();
    const pastDays = today.diff(moment.unix(releaseTimestamp), countIn);
    const cyclesCount = parseInt((pastDays / unlockInterval).toFixed(0), 10);
    const claimedUnlockedAmount = (claimedAmount - lockedInterest - stakedAmount);
    const amount = (cyclesCount * dailyUnlockedInterest) - claimedUnlockedAmount;

    return numberToFixed(amount, OXY_DECIMALS);
  }, [releaseTimestamp, countIn, unlockInterval, claimedAmount, lockedInterest, stakedAmount, dailyUnlockedInterest]);

  return (
    <div
      onClick={handleDetails}
      className={`unstake-card ${state === StakingStatus.WaitingForUnlockTokens ? 'finished' : ''} ${state === StakingStatus.EarlyWithdrawal ? 'early' : ''} ${(selectedPool && selectedPool.address === address) ? 'active' : ''}`}
    >
      <div className="unstake-card-top">
        <div className="unstake-oxy-count">
          <strong>{numberToFixed(stakedAmount, 2)}</strong>
          OXY
        </div>
        <Typography.Text className="unstake-apy">
          APY
          <br />
          <span>{`${rate / OXY_RATE}%`}</span>
        </Typography.Text>
      </div>

      {state === StakingStatus.WaitingForUnlockTokens && (
        <Typography.Text className="unstake-tokens-available-date">
          OXY tokens available to withdraw:
          <br />
          <strong>{`${getDailyTokensAmount} `}</strong>
        </Typography.Text>
      )}

      <div className="ustake-card-bottom">
        {state === StakingStatus.WaitingForUnlockTokens ? <FinishSVG /> : (
          <div className="unstake-progress">
            <Typography.Text className="unstake-progress-dates">
              <strong>{`${getLeftTime(releaseTimestamp, countIn) > 0 ? getLeftTime(releaseTimestamp, countIn) : 0} ${countIn}`}</strong>
              <span>
                {`to ${moment.unix(releaseTimestamp).format('DD.MM.YY')}`}
              </span>
            </Typography.Text>
          </div>
        )}
        <div className="unstake-card-buttons">
          <Button className="unstake-modal-btn" type="dashed" size="small" onClick={handleDetails}>Details</Button>
        </div>
      </div>
    </div>
  );
}
