import { Space } from 'antd';
import React, { ReactElement } from 'react';
import { TokenData } from 'interfaces/Token.interface';
import { BetterInputNumber } from '../BetterInputNumber/BetterInputNumber';

export function PopupInput({ token, inputAmount, onChange }: {
  inputAmount: number;
  onChange: (value: Event) => void;
  token: TokenData;
}): ReactElement {
  return (
    <div className="relative">
      <Space size={10}>
        <img alt="icon" src={token.imageUrl} style={{ width: 35, height: 35, borderRadius: 60 }} />
        <BetterInputNumber
          decimals={token.decimals}
          placeholder={`Enter ${token.symbol} amount`}
          value={inputAmount}
          onChange={onChange}
        />
      </Space>
      <span className="roundTokenAsset">{token.symbol}</span>
    </div>
  );
}
