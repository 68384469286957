export const MINTS = [
  {
    symbol: 'BTC',
    name: 'Bitcoin',
    mintAddress: process.env.REACT_APP_BTC_MINT_ADDRESS,
    imageUrl: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/solana/assets/9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E/logo.png',
    decimals: 6,
    formatDecimals: 6,
  },
  {
    symbol: 'ETH',
    name: 'Ethereum',
    mintAddress: process.env.REACT_APP_ETH_MINT_ADDRESS,
    imageUrl: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/solana/assets/2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk/logo.png',
    decimals: 6,
    formatDecimals: 6,
  },
  {
    symbol: 'SOL',
    name: 'Solana',
    mintAddress: process.env.REACT_APP_SOL_MINT_ADDRESS,
    imageUrl: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/solana/info/logo.png',
    decimals: 9,
    formatDecimals: 3,
  },
  {
    symbol: 'USDC',
    name: 'USD Coin',
    mintAddress: process.env.REACT_APP_USDC_MINT_ADDRESS,
    imageUrl: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/solana/assets/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png',
    decimals: 6,
    formatDecimals: 3,
  },
  {
    symbol: 'SRM',
    name: 'Serum',
    mintAddress: process.env.REACT_APP_SRM_MINT_ADDRESS,
    imageUrl: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/solana/assets/SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt/logo.png',
    decimals: 6,
    formatDecimals: 3,
  },
  {
    symbol: 'USDT',
    name: 'Tether',
    mintAddress: process.env.REACT_APP_USDT_MINT_ADDRESS,
    imageUrl: 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4/logo.png',
    decimals: 6,
    formatDecimals: 3,
  },
];
