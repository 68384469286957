import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const selectWalletSlice = (state: RootState) => state.wallet;

export const selectWalletBalances = createSelector(
  selectWalletSlice,
  (state) => state.balances,
);

export const selectWalletBalancesList = createSelector(
  selectWalletSlice,
  (state) => Object.values(state.balances),
);

export const selectWalletBalancesLoadingStatus = createSelector(
  selectWalletSlice,
  (state) => state.balancesLoadingStatus,
);

export const selectWalletPublicKey = createSelector(
  selectWalletSlice,
  (state) => state.publicKey,
);

export const selectWalletTotals = createSelector(
  selectWalletSlice,
  (state) => state.totals,
);

export const selectWalletTotalUsd = createSelector(
  selectWalletBalancesList,
  (state) => state.map((wallet) => wallet.balance.usd).reduce((a, b) => a + b, 0),
);

export const selectWalletTotalToken = createSelector(
  selectWalletBalancesList,
  (state) => state.map((wallet) => wallet.balance.token).reduce((a, b) => a + b, 0),
);

export const selectWalletDepositTotalUsd = createSelector(
  selectWalletBalancesList,
  (state): number => state.map((wallet) => wallet.deposited.usd).reduce((a, b) => a + b, 0),
);

export const selectWalletDepositTotalToken = createSelector(
  selectWalletBalancesList,
  (state) => state.map((wallet) => wallet.deposited.token).reduce((a, b) => a + b, 0),
);

export const selectWalletBorrowTotalUsd = createSelector(
  selectWalletBalancesList,
  (state) => state.map((wallet) => wallet.borrowed.usd).reduce((a, b) => a + b, 0),
);

export const selectWalletBorrowTotalToken = createSelector(
  selectWalletBalancesList,
  (state) => state.map((wallet) => wallet.borrowed.token).reduce((a, b) => a + b, 0),
);
