import { useWallet } from '@solana/wallet-adapter-react';
import { Alert, message } from 'antd';
import { ModeSwitcher } from 'features/UI';
import React, { ReactElement, useCallback, useState } from 'react';
import { truncateTwice } from 'utils';
import { Alert as UserAlert } from 'features/Alert/components/Alert/Alert';
import { ReactComponent as CopySVG } from 'features/Navbar/Navbar/copy.svg';
import { ReactComponent as LogoutSVG } from 'features/Navbar/Navbar/logout.svg';
import { ReactComponent as UserSVG } from 'features/Navbar/Navbar/user.svg';
import { ReactComponent as AlertSVG } from 'icons/alert.svg';
import { ReactComponent as GetSolSVG } from 'icons/getSol.svg';
import { ReactComponent as SupportSVG } from 'icons/support.svg';

export function AccountMobile(): ReactElement {
  const { connected, publicKey, disconnect } = useWallet();

  const [isAlertOpened, setAlertOpened] = useState(false);
  const toggleAlertModal = useCallback(() => {
    setAlertOpened(!isAlertOpened);
  }, [isAlertOpened]);

  const handleCopy = useCallback(async () => {
    await navigator.clipboard.writeText(publicKey.toString());
    await message.success('Wallet address successfuly copied');
  }, [publicKey]);

  const handleLogout = useCallback(async () => {
    await disconnect();
  }, [disconnect]);

  return (
    <div className="mobile-account">
      {!connected ? (
        <div style={{ margin: '0 0 10px' }}>
          <Alert
            message="Connect your wallet"
            type="info"
            showIcon
          />
        </div>
      ) : (
        <ul>
          <li>
            <span className="icon">
              <UserSVG />
            </span>
            <span className="text account">
              <span className="small">Account</span>
              <span className="wallet">{truncateTwice(publicKey.toString())}</span>
            </span>
            <span className="copy" onClick={handleCopy}>
              <CopySVG />
            </span>
          </li>
          <li>
            <button type="button" onClick={toggleAlertModal}>
              <span className="icon">
                <AlertSVG />
              </span>
              <span className="text">
                Alert
              </span>
            </button>
          </li>
          <li>
            <button type="button" onClick={handleLogout}>
              <span className="icon">
                <LogoutSVG />
              </span>
              <span className="text">
                Disconnect
              </span>
            </button>
          </li>
        </ul>
      )}
      <ul>
        <li id="support-link">
          <span className="icon">
            <GetSolSVG />
          </span>
          <span className="text">
            Get Sol
          </span>
        </li>
      </ul>
      <ul>
        <li id="support-link">
          <span className="icon">
            <SupportSVG />
          </span>
          <span className="text">
            Support
          </span>
        </li>
      </ul>
      <ModeSwitcher />
      {publicKey && <UserAlert isModalOpen={isAlertOpened} toggleAlert={toggleAlertModal} />}
    </div>
  );
}
