import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
} from 'redux-persist';
import { historyReducer } from '../features/History/state/History.slice';
import { marketReducer } from '../features/Market/state/Market.slice';
import { themeReducer } from '../features/Theme/state/Theme.slice';
import { walletReducer } from '../features/Wallet/state/Wallet.slice';
import { connectionReducer } from '../features/Connection/state/Connection.slice';
import { stakingReducer } from '../features/Staking/state/Staking.slice';

const reducers = combineReducers({
  market: marketReducer,
  theme: themeReducer,
  wallet: walletReducer,
  connection: connectionReducer,
  history: historyReducer,
  staking: stakingReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['connection'],
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppGetState = typeof store.getState;
export default store;
