import { useWallet } from '@solana/wallet-adapter-react';
import { Spin } from 'antd';
import { ReactComponent as LogoSVG } from 'icons/logo-mobile.svg';
import { LoadingStatus } from 'interfaces/Types';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { selectHistoryLoadingStatus, selectHistoryTransactions } from '../state/History.selector';
import './History.css';
import { HistoryTable } from './HistoryTable';
import { HistoryTableMobile } from './HistoryTableMobile';

export function History(): ReactElement {
  const { publicKey } = useWallet();
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const transactions = useSelector(selectHistoryTransactions);
  const loadingStatus = useSelector(selectHistoryLoadingStatus);

  if (!publicKey) {
    return <div>Connect your wallet</div>;
  }

  return (
    <div>
      {!isMobile
        ? <HistoryTableMobile transactions={transactions} />
        : <HistoryTable transactions={transactions} />}
      {!transactions.length && loadingStatus === LoadingStatus.Success && (
        <div className="no-data">
          <div>
            <LogoSVG />
            {' '}
            <br />
            {' '}
            No transactions
          </div>
        </div>
      )}
      {loadingStatus === LoadingStatus.Loading && <div className="spinner-container"><Spin /></div>}
    </div>
  );
}
