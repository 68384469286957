import { createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from 'interfaces/Types';
import { TokenTotal } from '../../../interfaces/Token.interface';
import { WalletBalances } from '../../../interfaces/Wallet.interface';

export interface WalletSlice {
  balances: { [key: string]: WalletBalances };
  balancesLoadingStatus: LoadingStatus;
  publicKey: string;
  totals: TokenTotal;
}

const initialState: WalletSlice = {
  publicKey: '',
  balances: {},
  balancesLoadingStatus: LoadingStatus.Idle,
  totals: {
    borrowLimit: 0,
    withdrawTotalAvailable: 0,
    borrowTotalAvailable: 0,
  },
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setPublicKey: (state, { payload }) => {
      state.publicKey = payload;
    },
    setBalances: (state, { payload }) => {
      state.balances = payload;
    },
    clearBalances: (state) => {
      state.balances = {};
      state.balancesLoadingStatus = LoadingStatus.Idle;
    },
    clearPublicKey: (state) => {
      state.publicKey = '';
    },
    setTotals: (state, { payload }) => {
      state.totals = payload;
    },
    setBalancesLoadingStatus: (state, { payload }) => {
      state.balancesLoadingStatus = payload;
    },
  },
});

export const walletReducer = walletSlice.reducer;
