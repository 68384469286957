import * as anchor from '@project-serum/anchor';
import { AccountLayout, TOKEN_PROGRAM_ID, Token } from '@solana/spl-token';
import { Keypair, LAMPORTS_PER_SOL, PublicKey, Signer, SystemProgram, Transaction } from '@solana/web3.js';

export const WRAPPED_SOL_MINT = new PublicKey(
  'So11111111111111111111111111111111111111112',
);

export async function wrapSol(
  provider: anchor.Provider,
  amount: number,
): Promise<{ account: Keypair; signers: Array<Signer | undefined>; tx: Transaction }> {
  const account = Keypair.generate();
  const tx = new Transaction();
  const signers = [account];
  // Create new, rent exempt account.
  tx.add(
    SystemProgram.createAccount({
      fromPubkey: provider.wallet.publicKey,
      newAccountPubkey: account.publicKey,
      lamports: await Token.getMinBalanceRentForExemptAccount(
        provider.connection,
      ),
      space: AccountLayout.span,
      programId: TOKEN_PROGRAM_ID,
    }),
  );
  // Transfer lamports. These will be converted to an SPL balance by the
  // token program.
  tx.add(
    SystemProgram.transfer({
      fromPubkey: provider.wallet.publicKey,
      toPubkey: account.publicKey,
      lamports: Math.floor(amount * LAMPORTS_PER_SOL),
    }),
  );
  // Initialize the account.
  tx.add(
    Token.createInitAccountInstruction(
      TOKEN_PROGRAM_ID,
      WRAPPED_SOL_MINT,
      account.publicKey,
      provider.wallet.publicKey,
    ),
  );
  return { tx, signers, account };
}
