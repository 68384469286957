import * as anchor from '@project-serum/anchor';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { Keypair, PublicKey, SYSVAR_CLOCK_PUBKEY, SYSVAR_RENT_PUBKEY, SystemProgram } from '@solana/web3.js';
import { convertToBN } from 'utils';
import { Balance, MainData } from '../interfaces/BorrowLending.interface';

export const INTERNAL_DECIMALS = 12;
const MAIN = {
  publicKey: process.env.REACT_APP_MAIN_ACCOUNT_ADDRESS,
};

class MainAccount {
  address: PublicKey;
  // @ts-ignore
  signer: PublicKey;
  // @ts-ignore
  signerBump: number;
  program: anchor.Program;
  staticData: any;
  // @ts-ignore
  tokenAccounts: PublicKey[];
  // @ts-ignore
  tokenAccountBumps: number[];
  // @ts-ignore
  feeAccounts: PublicKey[];
  // @ts-ignore
  feeAccountBumps: number[];

  constructor(program: anchor.Program) {
    this.program = program;
    this.address = new PublicKey(MAIN.publicKey);
  }

  static async create(program: anchor.Program, options: { shouldInit?: boolean } = {}): Promise<MainAccount> {
    const mainAccount = new MainAccount(program);

    [mainAccount.signer, mainAccount.signerBump] = await PublicKey.findProgramAddress(
      [
        anchor.utils.bytes.utf8.encode('main-signer'),
        mainAccount.address.toBuffer(),
      ],
      program.programId,
    );

    mainAccount.staticData = await mainAccount.getData();
    if (!mainAccount.staticData) {
      if (options.shouldInit) {
        await mainAccount.initialize();
      } else {
        throw Error('Main account is not initialized!');
      }
    }

    [mainAccount.tokenAccounts, mainAccount.tokenAccountBumps] = await mainAccount.getTokenAccounts();
    [mainAccount.feeAccounts, mainAccount.feeAccountBumps] = await mainAccount.getFeeAccounts();

    return mainAccount;
  }

  getMintIndex(mint: PublicKey): number {
    return this.staticData.tokens.findIndex((token: any) => mint.equals(token.mint));
  }

  async isInitialized(): Promise<boolean> {
    return (await this.getData()) !== undefined;
  }

  async getData(): Promise<MainData | undefined> {
    try {
      const data = await this.program.account.mainAccount.fetch(this.address) as MainData;
      const { balancesMap, pricesMap, tokensPKByIndex } = data.tokens.reduce((acc, { mint }, i) => {
        acc.pricesMap[mint.toBase58()] = data.prices[i]?.price || new anchor.BN(0);
        acc.balancesMap[mint.toBase58()] = data.balances[i]?.balance || {
          borrowShares: new anchor.BN(0),
          borrowTotal: new anchor.BN(0),
          depositShares: new anchor.BN(0),
          depositTotal: new anchor.BN(0),
        };
        acc.tokensPKByIndex[i] = mint.toBase58();
        return acc;
      }, { balancesMap: {}, pricesMap: {}, tokensPKByIndex: {} } as {
        balancesMap: {
          [key: string]: Balance;
        };
        pricesMap: { [key: string]: anchor.BN };
        tokensPKByIndex: { [key: number]: string };
      });

      data.balancesMap = balancesMap;
      data.pricesMap = pricesMap;
      data.tokensPKByIndex = tokensPKByIndex;
      return data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      return undefined;
    }
  }

  async getTokenAccountInfo(mint: PublicKey): Promise<[PublicKey, number]> {
    return PublicKey.findProgramAddress(
      [
        anchor.utils.bytes.utf8.encode('token-account'),
        this.address.toBuffer(),
        mint.toBuffer(),
      ],
      this.program.programId,
    );
  }

  async getTokenAccounts(): Promise<[PublicKey[], number[]]> {
    const tokenAccounts: PublicKey[] = [];
    const tokenAccountBumps: number[] = [];

    for (let i = 0; i < this.staticData.tokens.length; i++) {
      const { mint } = this.staticData.tokens[i];

      if (mint.toBase58() === '1'.repeat(32)) {
        break;
      }

      [tokenAccounts[i], tokenAccountBumps[i]] = await this.getTokenAccountInfo(mint);
    }

    return [tokenAccounts, tokenAccountBumps];
  }

  async getFeeAccountInfo(mint: PublicKey): Promise<[PublicKey, number]> {
    return PublicKey.findProgramAddress(
      [
        anchor.utils.bytes.utf8.encode('fee-account'),
        this.address.toBuffer(),
        mint.toBuffer(),
      ],
      this.program.programId,
    );
  }

  async getFeeAccounts(): Promise<[PublicKey[], number[]]> {
    const feeAccounts: PublicKey[] = [];
    const feeAccountBumps: number[] = [];

    for (let i = 0; i < this.staticData.tokens.length; i++) {
      const { mint } = this.staticData.tokens[i];

      if (mint.toBase58() === '1'.repeat(32)) {
        break;
      }

      [feeAccounts[i], feeAccountBumps[i]] = await this.getFeeAccountInfo(mint);
    }

    return [feeAccounts, feeAccountBumps];
  }

  async initialize(): Promise<string> {
    return this.program.rpc.initializeMain(this.signerBump, {
      accounts: {
        mainSigner: this.signer,
        mainAccount: this.address,
        authority: this.program.provider.wallet.publicKey,
        systemProgram: SystemProgram.programId,
        rent: SYSVAR_RENT_PUBKEY,
      },
      signers: [Keypair.fromSecretKey(Buffer.from((MAIN as any).secretKey, 'hex'))],
    });
  }

  // TODO: set all prices at once
  async setPrice(mint: PublicKey, price: number): Promise<string> {
    const mintIndex = this.getMintIndex(mint);
    const bnPrice = convertToBN(price, 12);

    const zeros = [...new Array(16)].map(() => new anchor.BN(0));
    const tokenIds = [...zeros];
    const prices = [...zeros];

    tokenIds[0] = new anchor.BN(mintIndex + 1);
    prices[0] = bnPrice;

    return this.program.rpc.setPrice(tokenIds, prices, {
      accounts: {
        mainAccount: this.address,
        authority: this.program.provider.wallet.publicKey,
        clock: SYSVAR_CLOCK_PUBKEY,
      },
    });
  }

  // TODO: crank all at once
  async crankInterest(mint: PublicKey): Promise<string> {
    const mintIndex = this.getMintIndex(mint);

    const zeros = [...new Array(64)].map(() => new anchor.BN(0));
    const tokenIds = [...zeros];

    tokenIds[0] = new anchor.BN(mintIndex + 1);

    return this.program.rpc.crankInterest(tokenIds, {
      accounts: {
        mainAccount: this.address,
        tokenProgram: TOKEN_PROGRAM_ID,
        clock: SYSVAR_CLOCK_PUBKEY,
      },
    });
  }

  async setPriceAll(rawPrices: number[]): Promise<string> {
    const zeros = [...new Array(16)].map(() => new anchor.BN(0));
    const tokenIds = [...zeros];
    const prices = [...zeros];

    for (let i = 0; i < this.tokenAccounts.length; i++) {
      tokenIds[i] = new anchor.BN(i + 1);
      prices[i] = convertToBN(rawPrices[i], 12);
    }

    return this.program.rpc.setPrice(tokenIds, prices, {
      accounts: {
        mainAccount: this.address,
        authority: this.program.provider.wallet.publicKey,
        clock: SYSVAR_CLOCK_PUBKEY,
      },
    });
  }

  async crankInterestAll(): Promise<string> {
    const tokenIds = [...new Array(64)].map((_, i) => new anchor.BN(i < this.tokenAccounts.length ? i + 1 : 0));

    return this.program.rpc.crankInterest(tokenIds, {
      accounts: {
        mainAccount: this.address,
        tokenProgram: TOKEN_PROGRAM_ID,
        clock: SYSVAR_CLOCK_PUBKEY,
      },
    });
  }

  async addToken(mint: PublicKey): Promise<string> {
    const [tokenAccount, tokenAccountBump] = await this.getTokenAccountInfo(mint);

    this.tokenAccounts.push(tokenAccount);
    this.tokenAccountBumps.push(tokenAccountBump);

    const [feeAccount, feeAccountBump] = await this.getFeeAccountInfo(mint);

    this.feeAccounts.push(feeAccount);
    this.feeAccountBumps.push(feeAccountBump);

    return this.program.rpc.addToken(
      tokenAccountBump,
      feeAccountBump,
      {
        accounts: {
          mainSigner: this.signer,
          mainAccount: this.address,
          tokenVault: tokenAccount,
          feeVault: feeAccount,
          mint,
          authority: this.program.provider.wallet.publicKey,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
          tokenProgram: TOKEN_PROGRAM_ID,
          clock: SYSVAR_CLOCK_PUBKEY,
        },
      },
    );
  }
}

export default MainAccount;
