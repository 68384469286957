import { WalletContextState } from '@solana/wallet-adapter-react';
import { Connection, PublicKey } from '@solana/web3.js';
import BigNumber from 'bignumber.js';
import { convertFromBN, numberToFixed, tokenFormatNumeral } from 'utils';
import { AppDispatch, AppGetState } from 'store/store';
import { StakingPool } from 'interfaces/Staking.interface';
import { catchError } from 'features/Error/Error.program';
import { LoadingStatus } from 'interfaces/Types';
import { selectWalletPublicKey } from '../../Wallet/state/Wallet.selector';
import { createProgram, getMainUserStakeContractAddress, getPoolAddress, getUserStakeContractAddress } from '../helpers/Staking.helper';
import { OXY_DECIMALS } from '../models/Staking.model';
import { OXY_TOKEN_MINT, claim, claimAllAvailable, commitment, earlyWithdraw, instantWithdraw, stake } from '../program/Staking.program';
import { selectPool } from './Staking.selector';
import { stakingSlice } from './Staking.slice';

export const {
  setPool, setPoolStatistics,
  setUserOxyBalance, setUserPools, setUserPoolLoaded, clearStakingInfo,
  setSelectedPool, setSelectedPoolVisible, setMobileEntry,
} = stakingSlice.actions;

export const stakingLoadPool = (connection: Connection) => async (dispatch: AppDispatch): Promise<void> => {
  const program = await createProgram(connection);
  const [poolAddress] = await getPoolAddress(program);
  const poolInfo = await program.account.pool.fetch(poolAddress);

  const avg = new BigNumber(poolInfo.totalDuration).div(poolInfo.totalStakeContract).div(365);

  dispatch(setPool({
    address: poolAddress.toString(),
    rewardOxyAccount: poolInfo.rewardOxyAccount.toString(),
  }));

  dispatch(setPoolStatistics({
    avgLockedPeriod: numberToFixed(avg, 2) || 0,
    totalRewards: numberToFixed(convertFromBN(poolInfo.totalClaimedRewards, OXY_DECIMALS), OXY_DECIMALS / 2),
    totalStaked: numberToFixed(convertFromBN(poolInfo.totalAmount, OXY_DECIMALS), OXY_DECIMALS / 2),
    totalStakers: poolInfo.totalStakeContract.toString(),
  }));
};

export const stakingLoadUserBalance = (connection: Connection, walletPublicKey: PublicKey) => async (dispatch: AppDispatch, getState: AppGetState): Promise<void> => {
  try {
    const balance = await connection.getParsedTokenAccountsByOwner(walletPublicKey, {
      mint: OXY_TOKEN_MINT,
    }, commitment);
    const balanceAmount = balance.value[0]?.account.data.parsed.info.tokenAmount.uiAmount || 0;

    const walletPk = selectWalletPublicKey(getState());

    if (walletPk) {
      dispatch(setUserOxyBalance(balanceAmount));
    }
  } catch (e: any) {
    catchError(e);
  }
};

export const stakingLoadUserAccounts = (connection: Connection, wallet: WalletContextState) => async (dispatch: AppDispatch, getState: AppGetState): Promise<void> => {
  const program = await createProgram(connection, wallet);
  const pool = selectPool(getState());

  const [mainUserStakeContractAddress] = await getMainUserStakeContractAddress(new PublicKey(pool.address), wallet, program);

  try {
    const mainUserContract = await program.account.mainStakeContract.fetch(mainUserStakeContractAddress);
    const userPoolAdresses: PublicKey[] = [];

    for (let i = mainUserContract.minContractNumber; i <= mainUserContract.maxContractNumber; i++) {
      const [userStakeContractAddress] = await getUserStakeContractAddress(new PublicKey(pool.address), wallet, i, program);
      userPoolAdresses.push(userStakeContractAddress);
    }

    const dataFull = await program.account.stakeContract.fetchMultiple(userPoolAdresses);
    const dataFiltered = dataFull.map((innerPool: any, i: number) => {
      if (innerPool) {
        return {
          address: userPoolAdresses[i].toString(),
          claimedAmount: convertFromBN(innerPool.claimedAmount, OXY_DECIMALS),
          contractNumber: innerPool.contractNumber,
          dailyUnlockedInterest: convertFromBN(innerPool.dailyUnlockedInterest, OXY_DECIMALS),
          duration: innerPool.duration,
          effectiveRate: convertFromBN(innerPool.effectiveRate, OXY_DECIMALS),
          rate: innerPool.rate,
          releaseTimestamp: innerPool.releaseTimestamp.toNumber(),
          stakedAmount: convertFromBN(innerPool.stakedAmount, OXY_DECIMALS),
          startTimestamp: innerPool.startTimestamp.toNumber(),
          state: Object.keys(innerPool.state)[0],
          user: innerPool.user.toString(),
          unlockInterval: innerPool.unlockInterval,
          userOxyAccount: innerPool.userOxyAccount.toString(),
          interest: convertFromBN(innerPool.interest, OXY_DECIMALS),
          lockedInterest: convertFromBN(innerPool.lockedInterest, OXY_DECIMALS),
        };
      }
      return null;
    }).filter((innerPool: any) => !!innerPool);

    const walletPublicKey = selectWalletPublicKey(getState());

    if (walletPublicKey) {
      dispatch(setUserPools(dataFiltered));
    }
  } catch (e) {
    console.log('--no user pools found...');
  }
};

const reloadAllData = (connection: Connection, wallet: WalletContextState) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(setUserPoolLoaded(LoadingStatus.Loading));
  dispatch(stakingLoadUserBalance(connection, wallet.publicKey));
  dispatch(stakingLoadPool(connection));
  await dispatch(stakingLoadUserAccounts(connection, wallet));
  dispatch(setUserPoolLoaded(LoadingStatus.Success));
};

export const stakingStake = (connection: Connection, wallet: WalletContextState, amount: number, daysCount: number) => async (dispatch: AppDispatch): Promise<void> => {
  const [program, tx] = await dispatch(stake(connection, wallet, amount, daysCount));
  await wallet.sendTransaction(tx, connection);
  dispatch(reloadAllData(connection, wallet));
};

export const stakingInstantWithdraw = (connection: Connection, wallet: WalletContextState, pool: StakingPool) => async (dispatch: AppDispatch): Promise<void> => {
  const [program, tx] = await dispatch(instantWithdraw(connection, wallet, pool));
  await wallet.sendTransaction(tx, connection);
  dispatch(reloadAllData(connection, wallet));
};

export const stakingClaim = (connection: Connection, wallet: WalletContextState, pool: StakingPool) => async (dispatch: AppDispatch): Promise<void> => {
  const [program, tx] = await dispatch(claim(connection, wallet, pool));
  await wallet.sendTransaction(tx, connection);
  dispatch(reloadAllData(connection, wallet));
};

export const stakingEarlyWithdraw = (connection: Connection, wallet: WalletContextState, pool: StakingPool) => async (dispatch: AppDispatch): Promise<void> => {
  const [program, tx] = await dispatch(earlyWithdraw(connection, wallet, pool));
  await wallet.sendTransaction(tx, connection);
  dispatch(reloadAllData(connection, wallet));
};

export const stakingClaimAllAvailable = (connection: Connection, wallet: WalletContextState, pool: StakingPool) => async (dispatch: AppDispatch): Promise<void> => {
  const [program, tx] = await dispatch(claimAllAvailable(connection, wallet, pool));
  // await program.provider.send(tx);
  await wallet.sendTransaction(tx, connection);
  dispatch(reloadAllData(connection, wallet));
};

export const stakingSelectPool = (poolId: string) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(setSelectedPool(poolId));
  dispatch(setSelectedPoolVisible(true));
};
