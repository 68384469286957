import * as anchor from '@project-serum/anchor';
import * as serum from '@project-serum/common';
import { MintInfo } from '@solana/spl-token';
import { PublicKey } from '@solana/web3.js';

const mintInfo: Map<PublicKey, Promise<MintInfo>> = new Map();

export const getMintInfo = async (provider: anchor.Provider, mint: PublicKey): Promise<MintInfo> => {
  if (!mintInfo.has(mint)) {
    mintInfo.set(mint, serum.getMintInfo(provider, mint));
  }
  return mintInfo.get(mint)!;
};
