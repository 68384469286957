import { Typography } from 'antd';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { tokenFormatNumeralNonZero } from 'utils';
import { selectStakingStatistics } from '../../state/Staking.selector';

export function StakingStatistics(): ReactElement {
  const poolStatistics = useSelector(selectStakingStatistics);

  return (
    <div className="staking-stat">
      <div className="staking-stat-block">
        <Typography.Text className="staking-stat-title">Total OXY staked</Typography.Text>
        <Typography.Text className="staking-stat-number">{tokenFormatNumeralNonZero(poolStatistics.totalStaked)}</Typography.Text>
      </div>
      <div className="staking-stat-block">
        <Typography.Text className="staking-stat-title">Total stakers</Typography.Text>
        <Typography.Text className="staking-stat-number">{poolStatistics.totalStakers}</Typography.Text>
      </div>
      <div className="staking-stat-block">
        <Typography.Text className="staking-stat-title">Avg locked period, years</Typography.Text>
        <Typography.Text className="staking-stat-number">{poolStatistics.avgLockedPeriod}</Typography.Text>
      </div>
      <div className="staking-stat-block">
        <Typography.Text className="staking-stat-title">
          Total OXY rewards
          distributed
        </Typography.Text>
        <Typography.Text className="staking-stat-number">{tokenFormatNumeralNonZero(poolStatistics.totalRewards)}</Typography.Text>
      </div>
    </div>
  );
}
