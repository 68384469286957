import * as anchor from '@project-serum/anchor';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { WalletContextState } from '@solana/wallet-adapter-react';
import { Commitment, Connection, PublicKey, SystemProgram, Transaction } from '@solana/web3.js';
import { StakingPool } from 'interfaces/Staking.interface';
import { AppDispatch, AppGetState } from 'store/store';
import { convertToBN } from 'utils';
import { createProgram, findAssociatedTokenAddress, getMainUserStakeContractAddress, getPoolAddress, getUserStakeContractAddress } from '../helpers/Staking.helper';
import { OXY_DECIMALS } from '../models/Staking.model';
import { selectPool } from '../state/Staking.selector';

export const OXY_STAKING_PROGRAM = new PublicKey(process.env.REACT_APP_OXY_STAKING_PROGRAM);
export const OXY_TOKEN_MINT = new PublicKey(process.env.REACT_APP_OXY_TOKEN_MINT);
export const commitment: Commitment = 'confirmed';

export const stake = (
  connection: Connection,
  wallet: WalletContextState,
  amount: number,
  duration: number,
) => async (): Promise<[anchor.Program, Transaction]> => {
  const program = await createProgram(connection, wallet);
  const [poolAddress] = await getPoolAddress(program);
  const tx = new Transaction();

  const userOxyTokenAddress = await findAssociatedTokenAddress(wallet.publicKey, OXY_TOKEN_MINT);
  const [mainUserStakeContractAddress] = await getMainUserStakeContractAddress(poolAddress, wallet, program);

  let maxContractNumber;

  try {
    const mainUserStakeContract = await program.account.mainStakeContract.fetch(mainUserStakeContractAddress);
    maxContractNumber = mainUserStakeContract.maxContractNumber + 1;
  } catch (e) {
    maxContractNumber = 1;
  }

  const [userStakeContractAddress] = await getUserStakeContractAddress(poolAddress, wallet, maxContractNumber, program);

  const poolOxyAccount = await findAssociatedTokenAddress(poolAddress, OXY_TOKEN_MINT);

  const stakeIx = await program.instruction.stake(
    new anchor.BN(amount * 1_000_000),
    duration,
    {
      accounts: {
        user: wallet.publicKey,
        userOxyAccount: userOxyTokenAddress,
        pool: poolAddress,
        poolOxyAccount,
        mainStakeContract: mainUserStakeContractAddress,
        stakeContract: userStakeContractAddress,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
      },
    },
  );

  tx.add(stakeIx);
  return [program, tx];
};

export const instantWithdraw = (
  connection: Connection,
  wallet: WalletContextState,
  pool: StakingPool,
) => async (dispatch: AppDispatch, getState: AppGetState): Promise<[anchor.Program, Transaction]> => {
  const program = await createProgram(connection, wallet);
  const [poolAddress] = await getPoolAddress(program);
  const mainPool = selectPool(getState());
  const tx = new Transaction();

  const [mainUserStakeContractAddress] = await getMainUserStakeContractAddress(poolAddress, wallet, program);
  const poolOxyAccount = await findAssociatedTokenAddress(poolAddress, OXY_TOKEN_MINT);

  const instantWidhrawIx = await program.instruction.immediatelyWithdrawal(
    {
      accounts: {
        user: wallet.publicKey,
        userOxyAccount: pool.userOxyAccount,
        pool: poolAddress,
        poolOxyAccount,
        rewardOxyAccount: mainPool.rewardOxyAccount,
        mainStakeContract: mainUserStakeContractAddress,
        stakeContract: pool.address,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
      },
    },
  );

  tx.add(instantWidhrawIx);
  return [program, tx];
};

export const claim = (
  connection: Connection,
  wallet: WalletContextState,
  pool: StakingPool,
) => async (dispatch: AppDispatch, getState: AppGetState): Promise<[anchor.Program, Transaction]> => {
  const program = await createProgram(connection, wallet);
  const [poolAddress] = await getPoolAddress(program);
  const mainPool = selectPool(getState());
  const tx = new Transaction();

  const [mainUserStakeContractAddress] = await getMainUserStakeContractAddress(poolAddress, wallet, program);
  const poolOxyAccount = await findAssociatedTokenAddress(poolAddress, OXY_TOKEN_MINT);
  const userOxyTokenAddress = await findAssociatedTokenAddress(wallet.publicKey, OXY_TOKEN_MINT);

  const stakedAmount = convertToBN(pool.stakedAmount, OXY_DECIMALS);
  const interest = convertToBN(pool.interest, OXY_DECIMALS);

  let sumToClaim;

  if (pool.state === 'earlyWithdrawal') {
    sumToClaim = stakedAmount;
  } else {
    sumToClaim = stakedAmount.add(interest);
  }

  const claimIx = await program.instruction.claim(
    sumToClaim,
    {
      accounts: {
        user: wallet.publicKey,
        userOxyAccount: userOxyTokenAddress,
        pool: poolAddress,
        poolOxyAccount,
        mainStakeContract: mainUserStakeContractAddress,
        rewardOxyAccount: mainPool.rewardOxyAccount,
        stakeContract: pool.address,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
      },
    },
  );

  tx.add(claimIx);
  return [program, tx];
};

export const earlyWithdraw = (
  connection: Connection,
  wallet: WalletContextState,
  pool: StakingPool,
) => async (): Promise<[anchor.Program, Transaction]> => {
  const program = await createProgram(connection, wallet);
  const [poolAddress] = await getPoolAddress(program);
  const tx = new Transaction();

  const [mainUserStakeContractAddress] = await getMainUserStakeContractAddress(poolAddress, wallet, program);
  const poolOxyAccount = await findAssociatedTokenAddress(poolAddress, OXY_TOKEN_MINT);

  const earlyWithdrawIx = await program.instruction.earlyWithdrawal(
    {
      accounts: {
        user: wallet.publicKey,
        pool: poolAddress,
        poolOxyAccount,
        mainStakeContract: mainUserStakeContractAddress,
        stakeContract: pool.address,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
      },
    },
  );

  tx.add(earlyWithdrawIx);
  return [program, tx];
};

export const claimAllAvailable = (
  connection: Connection,
  wallet: WalletContextState,
  pool: StakingPool,
) => async (dispatch: AppDispatch, getState: AppGetState): Promise<[anchor.Program, Transaction]> => {
  const program = await createProgram(connection, wallet);
  const mainPool = selectPool(getState());
  const poolAddress = new PublicKey(mainPool.address);
  const tx = new Transaction();

  const [mainUserStakeContractAddress] = await getMainUserStakeContractAddress(poolAddress, wallet, program);
  const poolOxyAccount = await findAssociatedTokenAddress(poolAddress, OXY_TOKEN_MINT);
  const userOxyTokenAddress = await findAssociatedTokenAddress(wallet.publicKey, OXY_TOKEN_MINT);

  const earlyWithdrawIx = await program.instruction.claimAllAvailiable(
    {
      accounts: {
        user: wallet.publicKey,
        userOxyAccount: userOxyTokenAddress,
        pool: poolAddress,
        poolOxyAccount,
        rewardOxyAccount: mainPool.rewardOxyAccount,
        mainStakeContract: mainUserStakeContractAddress,
        stakeContract: pool.address,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
      },
    },
  );

  tx.add(earlyWithdrawIx);
  return [program, tx];
};
