import { CaretDownOutlined, DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Alert as AlertAnt, Dropdown, Layout, Menu, Space, Spin, message } from 'antd';
import { ConnectionSelector } from 'features/Connection';
import { clearHistoryTransactions } from 'features/History/state/History.actions';
import { marketTokensLoadAndBalances } from 'features/Market/state/Market.actions';
import { themeSelectType } from 'features/Theme/state/Theme.selector';
import { ModeSwitcher } from 'features/UI';
import { selectWalletBalancesLoadingStatus } from 'features/Wallet/state/Wallet.selector';
import { LoadingStatus } from 'interfaces/Types';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { wholeDollarFormatNumeral } from 'utils/formats';
import { truncateTwice } from 'utils/wallet';
import { Alert } from 'features/Alert/components/Alert/Alert';
import { selectMarketTokens } from '../../Market/state/Market.selector';
import { clearStakingInfo } from '../../Staking/state/Staking.actions';
import { clearBalances, setPublicKey } from '../../Wallet/state/Wallet.actions';
import copyDark from './copy-dark.svg';
import copy from './copy.svg';
import { ReactComponent as LogoMobile } from './logo-mobile.svg';
import logoWhite from './logo-white.svg';
import logo from './logo.svg';
import logoutDark from './logout-dark.svg';
import logout from './logout.svg';
import './Navbar.css';
import user from './user.svg';

const { Header } = Layout;
const DOCS_LINK = 'https://docs.light.oxygen.org/';
const OXYGEN_LINK = 'https://oxygen.org';

export function Navbar({ mobile }: {
  mobile?: boolean;
}): ReactElement {
  const location = useLocation();
  const { connection } = useConnection();
  const dataTokens = useSelector(selectMarketTokens);
  const walletBalancesLoadingStatus = useSelector(selectWalletBalancesLoadingStatus);
  const dispatch = useDispatch();
  const theme = useSelector(themeSelectType);
  const wallet = useWallet();
  const [topStats, setTopStats] = useState(null);
  const [isBalancesLoaded, setIsBalancesLoaded] = useState(false);
  const [isAlertOpened, setAlertOpened] = useState(false);
  const navigation = useNavigate();

  const handleLogout = useCallback(async () => {
    await wallet.disconnect();
    dispatch(clearBalances());
    dispatch(setPublicKey(null));
    dispatch(clearHistoryTransactions());
    dispatch(clearStakingInfo());
    setIsBalancesLoaded(false);
  }, [dispatch, wallet]);

  const toggleAlertModal = useCallback(() => {
    setAlertOpened(!isAlertOpened);
  }, [isAlertOpened]);

  const handleCopy = useCallback(async () => {
    await navigator.clipboard.writeText(wallet.publicKey.toString());
    await message.success('Wallet address successfuly copied');
  }, [wallet.publicKey]);

  const accountMenu = (
    <Menu className="accountMenu">
      <Menu.Item
        key="copy"
        onClick={handleCopy}
        icon={(
          <span className="accountIcon">
            <img alt="copy" src={theme === 'light' ? copy : copyDark} />
          </span>
        )}
      >
        Copy address
      </Menu.Item>
      <Menu.Item
        key="disconnect"
        onClick={handleLogout}
        icon={(
          <span className="accountIcon">
            <img alt="logout" src={theme === 'light' ? logout : logoutDark} />
          </span>
        )}
      >
        Disconnect
        {' '}
        <span className="accountWalletAddress">{truncateTwice(wallet.publicKey?.toString())}</span>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (!dataTokens) {
      return;
    }

    // @ts-ignore
    const totalDeposited = Object.values(dataTokens).map((token: any) => token.deposited.usd).reduce((prev, next) => prev + next, 0);
    const totalBorrowed = Object.values(dataTokens).map((token: any) => token.borrowed.usd).reduce((prev, next) => prev + next, 0);
    const totalIe = Object.values(dataTokens).map((token: any) => token.borrowed.usd * token.borrowApr).reduce((prev, next) => prev + next, 0);

    setTopStats({
      tvl: totalDeposited,
      al: totalDeposited - totalBorrowed,
      ie24: totalIe / 365,
    });
  }, [dataTokens]);

  useEffect(() => {
    if (!wallet.connected || isBalancesLoaded) {
      return;
    }
    dispatch(setPublicKey(wallet.publicKey.toString()));
    dispatch(marketTokensLoadAndBalances(connection, wallet));
  }, [dispatch, wallet, connection, isBalancesLoaded]);

  useEffect(() => {
    if (!isBalancesLoaded && walletBalancesLoadingStatus === LoadingStatus.Success) {
      setIsBalancesLoaded(true);
    }
  }, [isBalancesLoaded, walletBalancesLoadingStatus]);

  return (
    <>
      {wallet.connected && <Alert isModalOpen={isAlertOpened} toggleAlert={toggleAlertModal} />}
      <Header className="headerWhite">
        {!mobile
          ? (
            <>
              <div className="headerInfo">
                <div className="logo">
                  <a href={OXYGEN_LINK}>
                    {theme === 'light' ? <img src={logo} alt="Oxygen Light" /> : <img src={logoWhite} alt="Oxygen Light" />}
                    <span className="badge">Light</span>
                  </a>
                </div>
                <Menu className="headerWithMenus" style={{ flex: '1' }} selectedKeys={[location.pathname]} theme="light" mode="horizontal">
                  <Menu.SubMenu
                    popupOffset={[0, 0]}
                    key="submenu"
                    title={(
                      <>
                        Borrow / Lending
                        <CaretDownOutlined style={{ marginLeft: 5, fontSize: '12px' }} />
                      </>
                  )}
                    onTitleClick={(): void => navigation('/')}
                  >
                    <Menu.ItemGroup title="">
                      <Menu.Item key="/">
                        <Link to="/">
                          Dashboard
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="/docs">
                        <a href={DOCS_LINK} target="_blank" rel="noopener noreferrer">Docs</a>
                      </Menu.Item>
                      {wallet.connected && <Menu.Item key="/notifications" onClick={toggleAlertModal}>Alert</Menu.Item>}
                    </Menu.ItemGroup>
                  </Menu.SubMenu>
                  {process.env.REACT_APP_STAKING_MENU === '1' && (
                    <Menu.Item key="/staking">
                      <Link to="/staking">
                        Staking
                      </Link>
                    </Menu.Item>
                  )}
                  <Menu.Item key="get-sol">
                    <a
                      href="https://get-sol.oxygen.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Get Sol
                    </a>
                  </Menu.Item>
                  <Menu.Item key="/support" id="support-link">
                    Support
                  </Menu.Item>
                </Menu>
              </div>
              <div className="headerActions">
                <div>
                  <Space size={16}>
                    <div className="tvlAlIe">
                      {Object.values(dataTokens).length === 0 ? <Spin /> : (
                        <Space align="center" size={20}>
                          <span>
                            TVL
                            <strong>{wholeDollarFormatNumeral(topStats?.tvl)}</strong>
                          </span>
                          <span>
                            AL
                            <strong>{wholeDollarFormatNumeral(topStats?.al)}</strong>
                          </span>
                          {/* <span> */}
                          {/*  IE 24h */}
                          {/*  <strong>{dollarFormatIENumeral(topStats?.ie24)}</strong> */}
                          {/* </span> */}
                        </Space>
                      )}
                    </div>
                    <ConnectionSelector />
                    {!wallet.connected
                      ? (
                        <WalletMultiButton className="connectWallet">Connect Wallet</WalletMultiButton>
                      ) : (
                        <Dropdown overlay={accountMenu} trigger={['click']} overlayClassName="accountDropdown" placement="topRight">
                          <div className="accountList">
                            {isBalancesLoaded
                              ? (
                                <div className="account">
                                  Account
                                  <span className="address">{truncateTwice(wallet.publicKey?.toString())}</span>
                                </div>
                              )
                              : (<Spin />)}
                            <span className="switcher"><img src={user} alt="mode" /></span>
                          </div>
                        </Dropdown>
                      )}
                    {/* <ModeSwitcher /> */}
                  </Space>
                </div>
              </div>
            </>
          )
          : (
            <>
              <div className="mobile-inner">
                <div className="headerInfo">
                  <div className="logo">
                    <LogoMobile />
                  </div>
                </div>
                <div className="headerActions">
                  <div className="tvlAlIe">
                    {Object.values(dataTokens).length === 0 ? <Spin /> : (
                      <Space align="center" size={10}>
                        <span>
                          TVL
                          <strong>{wholeDollarFormatNumeral(topStats?.tvl)}</strong>
                        </span>
                        <span>
                          AL
                          <strong>{wholeDollarFormatNumeral(topStats?.al)}</strong>
                        </span>
                        {/* <span> */}
                        {/*  IE 24h */}
                        {/*  <strong>{dollarFormatIENumeral(topStats?.ie24)}</strong> */}
                        {/* </span> */}
                      </Space>
                    )}
                  </div>
                </div>
              </div>
              <div className="connectionWrapper">
                <ConnectionSelector />
                {!wallet.connected && <WalletMultiButton className="connectWallet">Connect Wallet</WalletMultiButton>}
              </div>
            </>
          )}
      </Header>
      <AlertAnt type="info" showIcon icon={<InfoCircleOutlined />} message="Due to the recent events involving FTX and uncertainty of pricing of some assets deposits are currently disabled. You can withdraw your assets if you have no borrows. We are working on enhancing the protocol to take into account the new information." />
    </>
  );
}
