import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Tabs } from 'antd';
import moment from 'moment-timezone';
import { ReactElement, useEffect } from 'react';
import './Staking.css';
import '../StakingAlert/StakingAlert.css';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingStatus } from 'interfaces/Types';
import { marketTokensLoadAndBalances } from 'features/Market/state/Market.actions';
import { selectMarketTokensLoadingStatus } from 'features/Market/state/Market.selector';
import { useIsMobile } from 'features/UI/hooks';
import { Navigate, useLocation } from 'react-router-dom';
import { setMobileEntry, setUserPoolLoaded, stakingLoadPool, stakingLoadUserAccounts, stakingLoadUserBalance } from '../../state/Staking.actions';
import { selectPool, selectUserFinishedPools, selectUserPools } from '../../state/Staking.selector';
import { StakingCardDetails } from '../StakingCardDetails/StakingCardDetails';
import { StakingFinishedForm } from '../StakingFinishedForm/StakingFinishedForm';
import { StakingForm } from '../StakingForm/StakingForm';
import { StakingStatistics } from '../StakingStatistics/StakingStatistics';
import { StakingUnstakingForm } from '../StakingUnstakingForm/StakingUnstakingForm';

const { TabPane } = Tabs;
moment.tz.setDefault('UTC');

export function Staking(): ReactElement {
  const wallet = useWallet();
  const pool = useSelector(selectPool);
  const tokensLoadingStatus = useSelector(selectMarketTokensLoadingStatus);
  const { connection } = useConnection();
  const dispatch = useDispatch();
  const finishedPools = useSelector(selectUserFinishedPools);
  const activePools = useSelector(selectUserPools);
  const isMobile = useIsMobile();
  const location = useLocation();

  useEffect(() => {
    const init = async (): Promise<void> => {
      dispatch(stakingLoadPool(connection));
    };
    if (!pool.address) {
      init();
    }
  }, [connection, dispatch, pool.address]);

  useEffect(() => {
    if (tokensLoadingStatus !== LoadingStatus.Success) {
      dispatch(marketTokensLoadAndBalances(connection, wallet));
    }
  }, [connection, dispatch, tokensLoadingStatus, wallet]);

  useEffect(() => {
    if (!wallet.publicKey || !pool.address) {
      return;
    }

    const init = async (): Promise<void> => {
      const promises = [
        dispatch(stakingLoadUserBalance(connection, wallet.publicKey)),
        dispatch(stakingLoadUserAccounts(connection, wallet)),
      ];

      await Promise.all(promises);
      await dispatch(setUserPoolLoaded(LoadingStatus.Success));
    };

    init();
  }, [wallet, pool.address, dispatch, connection]);

  if (isMobile && location.pathname === '/staking') {
    dispatch(setMobileEntry(true));
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <div className="staking-block">
        <div className="container">
          <h2>Staking</h2>
          <Tabs className="tabs-market staking-tabs">
            <TabPane tab="Stake" key="Stake">
              <StakingForm />
            </TabPane>
            <TabPane
              tab={(
                <span className="unstake-finished-tab">
                  In Progress
                  <span className="unstake-finished-count">{activePools.length - finishedPools.length}</span>
                </span>
            )}
              key="Unstake"
            >
              <StakingUnstakingForm />
            </TabPane>
            <TabPane
              tab={(
                <span className="unstake-finished-tab">
                  Completed
                  <span className="unstake-finished-count">{finishedPools.length}</span>
                </span>
              )}
              key="Finished"
            >
              <StakingFinishedForm />
            </TabPane>
          </Tabs>
        </div>
      </div>
      <div className="staking-statistics">
        <div className="container">
          <h2>Statistics</h2>
          <StakingStatistics />
        </div>
      </div>
      <StakingCardDetails />
    </>
  );
}
