export function truncate(address: string): string {
  return address.length > 15
    ? `${address.slice(0, 8)}...${address.slice(
      address.length - 8,
      address.length,
    )}`
    : address;
}

export function truncateTwice(address: string): string {
  return address && address.length > 15
    ? `${address.slice(0, 5)}...${address.slice(
      address.length - 5,
      address.length,
    )}`
    : address;
}
