export enum LoadingStatus {
  Idle = 'idle',
  Loading = 'loading',
  Success = 'success',
  Fail = 'fail',
}

export enum TokensSymbols {
  Sol = 'SOL',
  Btc = 'BTC',
  Eth = 'ETH',
  Srm = 'SRM',
  Usdc = 'USDC',
  Usdt = 'USDT'
}
