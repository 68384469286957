import React, { ReactElement } from 'react';
import { StakingPeriods } from '../../models/Staking.model';

export function StakingTooltipTable(): ReactElement {
  return (
    <>
      <table className="tooltip-table-content" cellPadding={0} cellSpacing={0}>
        <thead>
          <tr>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <th />
            <th colSpan={4}>
              Amount of Staked OXY
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td />
            <td width={71}><strong>100+</strong></td>
            <td width={71}><strong>1000+</strong></td>
            <td width={71}><strong>10 000+</strong></td>
            <td width={71}><strong>100 000+</strong></td>
          </tr>
          <tr>
            <td><strong>{StakingPeriods.SevenDays}</strong></td>
            <td>3%</td>
            <td>4%</td>
            <td>5%</td>
            <td>6%</td>
          </tr>
          <tr>
            <td><strong>{StakingPeriods.ThreeMonths}</strong></td>
            <td>4%</td>
            <td>5%</td>
            <td>6%</td>
            <td>7%</td>
          </tr>
          <tr>
            <td><strong>{StakingPeriods.SixMonths}</strong></td>
            <td>5%</td>
            <td>6%</td>
            <td>7%</td>
            <td>8%</td>
          </tr>
          <tr>
            <td><strong>{StakingPeriods.NineMonths}</strong></td>
            <td>6%</td>
            <td>7%</td>
            <td>8%</td>
            <td>9%</td>
          </tr>
          <tr>
            <td><strong>{StakingPeriods.TwelweMonths}</strong></td>
            <td>7%</td>
            <td>8%</td>
            <td>9%</td>
            <td>10%</td>
          </tr>
          <tr>
            <td><strong>{StakingPeriods.EighteenMonths}</strong></td>
            <td>9.5%</td>
            <td>10.5%</td>
            <td>11.5%</td>
            <td>12.5%</td>
          </tr>
          <tr>
            <td><strong>{StakingPeriods.TwentyFourMonths}</strong></td>
            <td>12%</td>
            <td>13%</td>
            <td>14%</td>
            <td>15%</td>
          </tr>
          <tr>
            <td colSpan={5}>
              <br />
              Yield will be distributed 50%/50% with OXY tokens and Locked OXY tokens. Locked OXY unlocks linearly every day until 18/12/2026
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
