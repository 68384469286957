import { Program as ProgramClient, Provider } from '@project-serum/anchor';
import { BorrowLending, IDL as idl } from './idl';

export const ADDRESS = process.env.REACT_APP_PROGRAM_ADDRESS;

export class Program {
  static async getProgram(provider: Provider): Promise<ProgramClient<BorrowLending>> {
    return new ProgramClient<any>(idl, ADDRESS, provider);
  }
}
