import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { HistorySlice } from './History.slice';

const selectHistorySlice = (state: RootState): HistorySlice => state.history;

export const selectHistoryTransactions = createSelector(
  selectHistorySlice,
  (state) => state.transactions,
);

export const selectHistoryLoadingStatus = createSelector(
  selectHistorySlice,
  (state) => state.loadingStatus,
);

export const selectHistoryLastTransaction = createSelector(
  selectHistoryTransactions,
  (transactions) => transactions.slice(-1)[0],
);
