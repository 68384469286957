export const RADIX = 10;
export const YEAR = 365;
export const SEVEN_DAYS = 7;
export const THREE_MONTHS = 92;
export const SIX_MONTHS = 184;
export const NINE_MONTHS = 276;
export const TWELWE_MONTHS = 365;
export const EIGHTEEN_MONTHS = 550;
export const TWO_YEARS = 730;
export const OXY_DECIMALS = 6;
export const OXY_RATE = 10000;
export const INTEGER_LIMIT_DAYS = 3;
export const INTEGER_LIMIT_AMOUNT = 7;
export const MIN_OXY_AMOUNT = 100;
export const OXY_SLIDER_STEP = 14.2;
export const MAX_OXY_AMOUNT = 1000000;

export enum StakingPeriods {
  SevenDays = '7 Days',
  ThreeMonths = '3 Mos',
  SixMonths = '6 Mos',
  NineMonths = '9 Mos',
  TwelweMonths = '12 Mos',
  EighteenMonths = '18 Mos',
  TwentyFourMonths = '24 Mos',
}

export const STAKING_PERIOD_LIST = {
  14.2: StakingPeriods.SevenDays,
  28.4: StakingPeriods.ThreeMonths,
  42.6: StakingPeriods.SixMonths,
  56.8: StakingPeriods.NineMonths,
  71: StakingPeriods.TwelweMonths,
  85.2: StakingPeriods.EighteenMonths,
  99.4: StakingPeriods.TwentyFourMonths,
};

export const STAKING_PERIOD_VALUES = {
  14.2: SEVEN_DAYS,
  28.4: THREE_MONTHS,
  42.6: SIX_MONTHS,
  56.8: NINE_MONTHS,
  71: TWELWE_MONTHS,
  85.2: EIGHTEEN_MONTHS,
  99.4: TWO_YEARS,
};

export enum StakingStatus {
  Active = 'active',
  EarlyWithdrawal = 'earlyWithdrawal',
  WaitingForUnlockTokens = 'waitingForUnlockTokens',
}
