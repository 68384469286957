import { Button, Modal, Tooltip } from 'antd';
import { useIsMobile } from 'features/UI/hooks';
import React, { ReactElement, useState } from 'react';
import info from './info.svg';
import './StatisticItem.css';

export function StatisticItem({ label, value, change, tooltip }: {
  label: string;
  tooltip: string;
  value: string;
  change?: string;
}): ReactElement {
  const [modalVisible, setModalVisible] = useState(false);
  const isMobile = useIsMobile();

  return (
    <>
      {tooltip && (
        <Modal
          className="tooltip-modal"
          footer={null}
          closable
          visible={modalVisible}
          centered
        >
          {tooltip}
          <Button onClick={(): void => setModalVisible(false)} block>Close</Button>
        </Modal>
      )}
      <div className="statisticItem">
        <span className="statisticItem-name">
          {label}
          {(tooltip && !isMobile) && <Tooltip placement="bottom" title={tooltip}><img alt="info" src={info} /></Tooltip>}
          {(tooltip && isMobile) && <button type="button" onClick={(): void => setModalVisible(true)}><img alt="info" src={info} /></button>}
        </span>
        <span className={`statisticItem-value ${change}`}>{value}</span>
      </div>
    </>
  );
}
