import { useWallet } from '@solana/wallet-adapter-react';
import { Spin, Typography } from 'antd';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { LoadingStatus } from 'interfaces/Types';
import { selectUserActivePools, selectUserEarlyPools, selectUserPoolsIsLoaded } from '../../state/Staking.selector';
import { StakingAlert } from '../StakingAlert/StakingAlert';
import { StakingCard } from '../StakingCard/StakingCard';

export function StakingUnstakingForm(): ReactElement {
  const wallet = useWallet();
  const pools = useSelector(selectUserActivePools);
  const earlyPools = useSelector(selectUserEarlyPools);
  const userPoolIsLoaded = useSelector(selectUserPoolsIsLoaded);

  if (!wallet.publicKey) {
    return (
      <StakingAlert title="Connect your wallet" />
    );
  }

  return (
    <Spin spinning={userPoolIsLoaded === LoadingStatus.Loading}>
      {pools.length > 0 ? (
        <div className="unstake-cards">
          {pools?.map((pool) => <StakingCard key={pool.address} pool={pool} />)}
        </div>
      ) : (
        <StakingAlert title="No active stakes" content="Go to the Stake tab to stake OXY." />
      )}
      {earlyPools.length > 0 && (
        <>
          <Typography.Text className="unstake-title">Unstaking</Typography.Text>
          <div className="unstake-cards">
            {earlyPools?.map((pool) => <StakingCard key={pool.address} pool={pool} />)}
          </div>
        </>
      )}
    </Spin>
  );
}
