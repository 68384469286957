export interface Connection {
  title: string;
  url: string;
}

export enum NetworksTypes {
  Devnet= 'Devnet',
  Mainnet= 'Mainnet'
}

export const ConnectionMap: {
  [key in NetworksTypes]: Connection[]
} = {
  [NetworksTypes.Mainnet]: [
    {
      title: 'Oxygen RPC',
      url: process.env.REACT_APP_CONNECTION_ENDPOINT,
    },
    {
      title: 'Solana mainnet',
      url: process.env.REACT_APP_CONNECTION_ENDPOINT_SOLANA_MAINNET,
    },
  ],
  [NetworksTypes.Devnet]: [
    {
      title: 'Solana devnet',
      url: process.env.REACT_APP_CONNECTION_ENDPOINT,
    },
  ],
};
