import * as anchor from '@project-serum/anchor';
import BigNumber from 'bignumber.js';

export const convertToBN = (amount: number, decimals: number): anchor.BN => {
  const strAmount = amount.toFixed(decimals).replace('.', '');
  return new anchor.BN(strAmount);
};

export const convertFromBN = (amount: anchor.BN, decimals: number): number => {
  const amountStr = new BigNumber(amount.toString());
  const toDivAmount = `1${'0'.repeat(decimals)}`;
  return amountStr.dividedBy(toDivAmount).toNumber();
};

export const numberToFixed = (amount: number | BigNumber, fixed?: number, direction?: 'up' | 'down'): number => {
  if (!fixed) {
    fixed = 2; // for USD 1.00
  }

  if (direction && direction === 'up') {
    return new BigNumber(amount).decimalPlaces(fixed, BigNumber.ROUND_HALF_CEIL).toNumber();
  }

  if (direction && direction === 'down') {
    return new BigNumber(amount).decimalPlaces(fixed, BigNumber.ROUND_FLOOR).toNumber();
  }

  return new BigNumber(amount).decimalPlaces(fixed).toNumber();
};
