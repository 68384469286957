import { useWallet } from '@solana/wallet-adapter-react';
import { Alert, Spin } from 'antd';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { LoadingStatus } from 'interfaces/Types';
import { selectUserFinishedPools, selectUserPoolsIsLoaded } from '../../state/Staking.selector';
import { StakingAlert } from '../StakingAlert/StakingAlert';
import { StakingCard } from '../StakingCard/StakingCard';

export function StakingFinishedForm(): ReactElement {
  const finishedPools = useSelector(selectUserFinishedPools);
  const wallet = useWallet();
  const userPoolIsLoaded = useSelector(selectUserPoolsIsLoaded);

  if (!wallet.publicKey) {
    return (
      <StakingAlert title="Connect your wallet" />
    );
  }

  return (
    <Spin spinning={userPoolIsLoaded === LoadingStatus.Loading}>
      {finishedPools.length ? (
        <div className="unstake-cards">
          {finishedPools.map((pool) => <StakingCard pool={pool} key={pool.address} />)}
        </div>
      ) : (
        <StakingAlert title="No completed stakes" />
      )}
    </Spin>
  );
}
