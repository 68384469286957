import { useWallet } from '@solana/wallet-adapter-react';
import { Button, Space, Table, Typography, message } from 'antd';
import { TokenIcon } from 'features/Market/components/TokenIcon/TokenIcon';
import { selectWalletBalancesList } from 'features/Wallet/state/Wallet.selector';
import { Paths } from 'interfaces/Paths';
import { LoadingStatus } from 'interfaces/Types';
import { ReactElement, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TableTokenData } from './hooks';
import { Tabs } from './types';
import { HighlightedNumbersByDecimals } from '../HighlightedNumbersByDecimals/HighlightedNumbersByDecimals';

const { Text } = Typography;

export function TokenTableDesktop({
  handleMarketChange,
  tableTokenData,
  loadingStatus,
  selectedMarket,
  setSelectedToken,
}: {
  handleMarketChange: (market: Tabs) => () => void;
  loadingStatus: LoadingStatus;
  selectedMarket: Tabs;
  setSelectedToken: (pubKey: string) => void;
  tableTokenData: TableTokenData[];
}): ReactElement {
  const balances = useSelector(selectWalletBalancesList);
  const { connected } = useWallet();
  const { pathname } = useLocation();
  const titleFormatter = useCallback(() => (
    <Space size={25}>
      All markets
      <Space>
        <Button key="tokens" onClick={handleMarketChange(Tabs.Tokens)} type={selectedMarket === 'tokens' ? 'primary' : 'default'} shape="round" size="small">Tokens</Button>
        <Button key="usdc" onClick={handleMarketChange(Tabs.Usd)} type={selectedMarket === 'usd' ? 'primary' : 'default'} shape="round" size="small">$USD</Button>
      </Space>
    </Space>
  ), [handleMarketChange, selectedMarket]);

  const handleSelectRow = useCallback((record: TableTokenData) => ({
    onClick: (): void => {
      if (connected) {
        if (!balances.length) {
          message.warning('Please wait a few seconds for the numbers to load.');
        } else {
          setSelectedToken(record.mintPubkey);
        }
      } else {
        message.warning('Connect your wallet to deposit or withdraw from the protocol.');
      }
    },
  }), [balances.length, connected, setSelectedToken]);

  return (
    <Table
      loading={loadingStatus === LoadingStatus.Loading}
      className="table general-tables"
      rowClassName={(_, index): string => ((index % 2 === 0) ? 'odd' : 'even')}
      dataSource={tableTokenData}
      pagination={{ hideOnSinglePage: true }}
      scroll={{ x: 1200 }}
      onRow={handleSelectRow}
      columns={[
        {
          title: titleFormatter,
          children: [
            {
              title: 'Token',
              dataIndex: 'symbol',
              key: 'symbol',
              render: (symbol, record): ReactElement => (
                <>
                  <TokenIcon url={record.imageUrl} tokenName={record.name} />
                  <Text style={{ marginLeft: '15px' }}>
                    <Text className="bolder">{symbol}</Text>
                  </Text>
                </>
              ),
            },
            {
              title: 'Total deposit',
              dataIndex: 'totalDeposited',
              key: 'totalDeposited',
              render: (totalDeposited): ReactElement => (
                <Text className="bolder">{totalDeposited}</Text>
              ),
            },
            {
              title: 'Total borrow',
              dataIndex: 'totalBorrowed',
              key: 'totalBorrowed',
              render: (totalBorrowed): ReactElement => (
                <Text className="bolder">{totalBorrowed}</Text>
              ),
            },
            {
              title: 'Total available',
              dataIndex: 'totalAvailable',
              key: 'totalAvailable',
              render: (totalAvailable, record): ReactElement => (
                <Text className="bolder">
                  {totalAvailable}
                  <p className="tablehint">{record.utilization}</p>
                </Text>
              ),
            },
            {
              title: 'Deposit APY',
              dataIndex: 'depositApy',
              key: 'depositApy',
              render: (depositApy): ReactElement => (
                <Text className="bolder green">{depositApy}</Text>
              ),
            },
            {
              title: 'Borrow APR',
              dataIndex: 'borrowApr',
              key: 'borrowApr',
              render: (borrowApr): ReactElement => (
                <Text className="bolder red">{borrowApr}</Text>
              ),
            },
          ],
        },
        {
          title: 'My assets',
          children: [
            {
              title: 'Deposited',
              fixed: 'right',
              render: (record): ReactElement => (
                <Text>
                  {record.deposited}
                  {pathname === Paths.Support && (
                    <div>
                      <HighlightedNumbersByDecimals
                        amount={record.depositedProgram}
                        formatDecimals={record.formatDecimals}
                        decimals={record.decimals}
                      />
                    </div>
                  )}
                </Text>
              ),
            },
            {
              title: 'Borrowed',
              fixed: 'right',
              render: (record): ReactElement => (
                <Text>
                  {record.borrowed}
                  {pathname === Paths.Support && (
                    <div>
                      <HighlightedNumbersByDecimals
                        amount={record.borrowedProgram}
                        formatDecimals={record.formatDecimals}
                        decimals={record.decimals}
                      />
                    </div>
                  )}

                  <p className="tablehint">
                    <span>
                      {record.avaliable}
                    </span>
                    Available
                  </p>
                </Text>
              ),
            },
            {
              title: 'Wallet balance',
              fixed: 'right',
              render: (record): ReactElement => (
                <Text>
                  {record.walletBalance}
                  {pathname === Paths.Support && (
                    <HighlightedNumbersByDecimals
                      amount={record.walletBalanceProgram}
                      formatDecimals={record.formatDecimals}
                      decimals={record.decimals}
                    />
                  )}
                </Text>
              ),
            },
          ],
        },
      ]}
    />
  );
}
