import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { ConnectionSlice } from './Connection.slice';

const selectConnectionSlice = (state: RootState): ConnectionSlice => state.connection;

export const selectCurrentNetwork = createSelector(
  selectConnectionSlice,
  (state) => state.currentNetwork,
);
