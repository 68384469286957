import { Button, Modal, Progress, Tooltip } from 'antd';
import info from 'features/Statistics/StatisticItem/info.svg';
import { useIsMobile } from 'features/UI/hooks';
import React, { ReactElement, useCallback, useState } from 'react';
import { percentFormat, percentFormatOne, wholePercentFormat } from 'utils/formats';
import './Health.css';
import health from './health.svg';

function getLtvType(awayFromCritical: number): { color: string; label: string } {
  let label = '';
  let color = '';

  if (awayFromCritical < 0.1) {
    label = 'High risk';
    color = '#D70465';
  }

  if (awayFromCritical > 0.1 && awayFromCritical < 0.25) {
    label = 'Normal';
    color = '#F5C041';
  }

  if (awayFromCritical > 0.25) {
    label = 'Good';
    color = '#6BD492';
  }

  return {
    label,
    color,
  };
}

export function Health({ currentLtv, criticalLtv, tooltip }: {
  criticalLtv: number;
  currentLtv: number;
  tooltip: string;
}): ReactElement {
  const [modalVisible, setModalVisible] = useState(false);
  const curLtvNumber = (currentLtv * 100).toFixed(0);
  const awayFromCritical = 1 - (currentLtv / criticalLtv);
  const isMobile = useIsMobile();

  const formatter = useCallback(() => (
    <span style={{ color: getLtvType(awayFromCritical).color }}>
      {awayFromCritical > 0 ? `${wholePercentFormat.format(awayFromCritical)}` : '0 %'}
    </span>
  ), [awayFromCritical]);
  return (
    !isMobile ? (
      <div className="statisticBlock health">
        <p className="statisticBlock-title">
          My Loan Health:
          <span style={{ color: getLtvType(awayFromCritical).color }}>{getLtvType(awayFromCritical).label}</span>
        </p>

        <div className="statisticBlock-inner">
          <div style={{ display: 'flex' }}>
            <img src={health} alt="Current LTV" />
            <Tooltip title={`Current LTV: ${percentFormat.format(currentLtv)}`}>
              <strong>Health Reserve</strong>
            </Tooltip>
            <Tooltip
              title={`The maximum allowable amount of debt as a percentage of total assets ${percentFormat.format(criticalLtv)} in order to maintain an existing position.`}
              placement="topRight"
            >
              <Progress
                percent={Number(curLtvNumber)}
                format={formatter}
                strokeColor={getLtvType(awayFromCritical).color}
              />
            </Tooltip>
          </div>
          <p className="info">
            away from critical LTV
            <Tooltip
              overlayStyle={{ whiteSpace: 'pre-line' }}
              title={`${tooltip} 
                        Initial LTV: ${percentFormatOne.format(1 / 1.1)}
                        Current LTV: ${percentFormatOne.format(currentLtv)}
                        Critical LTV: ${percentFormatOne.format(criticalLtv)}
                        `}
              placement="bottomRight"
            >
              {' '}
              <img alt="info" src={info} />
            </Tooltip>
          </p>
        </div>
      </div>
    ) : (
      <>
        {tooltip && (
          <Modal className="tooltip-modal" footer={null} closable visible={modalVisible} centered>
            <p style={{ justifyContent: 'center' }}>
              Initial LTV: $
              {percentFormatOne.format(1 / 1.1)}
            </p>
            <p style={{ justifyContent: 'center' }}>
              Current LTV: $
              {percentFormatOne.format(currentLtv)}
            </p>
            <p style={{ justifyContent: 'center' }}>
              Critical LTV: $
              {percentFormatOne.format(criticalLtv)}
            </p>
            {tooltip}
            <Button onClick={(): void => setModalVisible(false)} block>Close</Button>
          </Modal>
        )}
        <div className="statisticBlock health">
          <div className="statisticBlock-inner">
            <div style={{ display: 'flex' }}>
              <img src={health} alt="Current LTV" />
              <Tooltip title={`Current LTV: ${percentFormat.format(currentLtv)}`}>
                <strong>Health Reserve</strong>
              </Tooltip>
              <Tooltip
                title={`The maximum allowable amount of debt as a percentage of total assets ${percentFormat.format(criticalLtv)} in order to maintain an existing position.`}
                placement="topRight"
              >
                <Progress
                  percent={Number(curLtvNumber)}
                  format={formatter}
                  strokeColor={getLtvType(awayFromCritical).color}
                />
              </Tooltip>
            </div>
            <p className="info">
              away from critical LTV
              {!isMobile && <Tooltip title={tooltip} placement="bottomRight"><img alt="info" src={info} /></Tooltip>}
              {isMobile && <span onClick={(): void => setModalVisible(true)}><img alt="info" src={info} /></span>}
            </p>
          </div>
        </div>
      </>
    )
  );
}
