import { Health } from 'features/Health';
import { selectMarketTokensList } from 'features/Market/state/Market.selector';
import { selectWalletBalancesList } from 'features/Wallet/state/Wallet.selector';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { numberToFixed } from 'utils';
import { dollarFormatMarketNumeral, percentFormat } from 'utils/formats';
import { StatisticItem } from '../StatisticItem/StatisticItem';
import './Statistics.css';

type stats = {
    avgApr: string;
    avgApy: string;
    borrowed: string;
    borrowingPower: string;
    criticalLTV: number;
    currentLTV: number;
    deposited: string;
    initialLTV: number;
    paymentPd: string;
    totalAssets: string;
    totalYield: string;
    yieldPd: string;
  }
export function Statistics(): ReactElement {
  const [stats, setStats] = useState<stats>(null);
  const dataTokens = useSelector(selectMarketTokensList);
  const balances = useSelector(selectWalletBalancesList);
  const initCoverage = 1.1;
  const liquidationCoverage = 1.05;

  useEffect(() => {
    const total = balances.map((balance) => balance.balance.usd).reduce((prev, next) => prev + next, 0);
    const borrowingPower = total / initCoverage;
    const deposited = balances.map((balance) => balance.deposited.usd).reduce((prev, next) => prev + next, 0);
    const borrowed = balances.map((balance) => balance.borrowed.usd).reduce((prev, next) => prev + next, 0);
    const currentLTV = (borrowed / deposited) > 0 ? borrowed / deposited : 0;
    const criticalLTV = 1 / liquidationCoverage;
    const initialLTV = 1 / initCoverage;
    let paymentPerDay = 0;
    let paymentPerDayToken = 0;
    let yieldPerDay = 0;
    let yieldPerDayToken = 0;
    let averageApy = 0;
    let averageApr = 0;

    dataTokens.forEach((token, index) => {
      // payment per day = sum of each token borrow apr * user token balance borrowed in usd
      paymentPerDay += token.borrowApr * (balances[index]?.borrowed.usd ?? 0);
      paymentPerDayToken += token.borrowApr * (balances[index]?.borrowed.token ?? 0);
      // yield per day = sum of each token deposit apy * user token balance deposited in usd divided to 365
      yieldPerDay += token.depositApy * (balances[index]?.deposited.usd ?? 0);
      yieldPerDayToken += token.depositApy * (balances[index]?.deposited.token ?? 0);
      averageApy += token.depositApy * (balances[index]?.deposited.usd ?? 0);
      averageApr += token.borrowApr * (balances[index]?.borrowed.usd ?? 0);
    });

    // average apy = (sum of token deposit apy * user token balance deposited in usd) / total user balance in usd
    averageApy = (averageApy > 0) ? averageApy / deposited : 0;
    averageApr = (averageApr > 0) ? averageApr / borrowed : 0;

    yieldPerDay /= 365;
    yieldPerDayToken /= 365;
    paymentPerDay /= 365;
    paymentPerDayToken /= 365;

    // total yield = yield per day - payment per day
    const totalYield = (yieldPerDay - paymentPerDay > 0)
      ? numberToFixed(yieldPerDay - paymentPerDay)
      : 0;
    const totalYieldToken = (yieldPerDayToken - paymentPerDayToken > 0)
      ? yieldPerDayToken - paymentPerDayToken
      : 0;

    yieldPerDay = numberToFixed(yieldPerDay);
    paymentPerDay = numberToFixed(paymentPerDay);

    setStats({
      totalAssets: dollarFormatMarketNumeral(total),
      borrowingPower: dollarFormatMarketNumeral(borrowingPower),
      deposited: dollarFormatMarketNumeral(deposited),
      borrowed: dollarFormatMarketNumeral(borrowed),
      yieldPd: yieldPerDay === 0 && yieldPerDayToken > 0
        ? '<0.01'
        : dollarFormatMarketNumeral(yieldPerDay),
      paymentPd: paymentPerDay === 0 && paymentPerDayToken > 0
        ? '<0.01'
        : dollarFormatMarketNumeral(paymentPerDay),
      totalYield: totalYield === 0 && totalYieldToken > 0
        ? '<0.01'
        : dollarFormatMarketNumeral(totalYield),
      avgApy: averageApy ? percentFormat.format(averageApy) : '-',
      avgApr: averageApr ? percentFormat.format(averageApr) : '-',
      currentLTV,
      criticalLTV,
      initialLTV,
    });
  }, [balances, dataTokens]);

  return (
    <div className="container statistics">
      <div className="statisticBlock">
        <p className="statisticBlock-title">My Wallet Assets</p>
        <div className="statisticBlock-inner">
          <StatisticItem
            label="Total Assets"
            tooltip="Value ($) of Assets in Wallet"
            value={stats?.totalAssets}
          />
          <span className="divider" />
          <StatisticItem
            label="Borrowing Power"
            tooltip="Borrowing power is the amount of assets you can borrow if you deposit all assets in the wallet: ( Total assets / ( 1 - Initial LTV ) )"
            value={stats?.borrowingPower}
          />
        </div>
      </div>
      <div className="statisticBlock">
        <p className="statisticBlock-title">My Platform Assets</p>
        <div className="statisticBlock-inner">
          <StatisticItem
            label="Deposited"
            tooltip="Value ($) of Deposited Assets"
            value={stats?.deposited}
          />
          <span className="divider" />
          <StatisticItem
            label="Borrowed"
            tooltip="Value ($) of Borrowed Assets"
            value={stats?.borrowed}
          />
        </div>
      </div>
      <div className="statisticBlock cashflow">
        <p className="statisticBlock-title">My Cashflow</p>
        <div className="statisticBlock-inner">
          <StatisticItem
            label="Total Yield"
            tooltip="Net interest per day. Borrowing results in a negative contribution to Net Yield while lending results in a positive contribution to Net Yield."
            value={stats?.totalYield}
          />
          <span className="divider" />
          <StatisticItem
            label="Avg APY"
            tooltip="Weighted average interest rates of all deposited assets (% pa)"
            change="up"
            value={stats?.avgApy}
          />
          <StatisticItem
            label="Yield p/d"
            tooltip="$ Yield per day"
            value={stats?.avgApy === '-' ? '-' : stats?.yieldPd}
          />
          <StatisticItem
            label="Avg APR"
            tooltip="Weighted average interest rates of all borrowed assets (% pa)"
            change="down"
            value={stats?.avgApr}
          />
          <StatisticItem
            label="Payment p/d"
            tooltip="Borrow Interest ($) per day"
            value={stats?.avgApr === '-' ? '-' : stats?.paymentPd}
          />
        </div>
      </div>
      <Health
        currentLtv={stats?.currentLTV}
        criticalLtv={stats?.criticalLTV}
        tooltip="The percentage by which value of pool assets needs to fall to trigger critical LTV % away from critical LTV = 1 - ( Current LTV / Critical LTV )"
      />
    </div>
  );
}
