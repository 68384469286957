import { useWallet } from '@solana/wallet-adapter-react';
import { Spin } from 'antd';
import { selectMarketTokensLoadingStatus } from 'features/Market/state/Market.selector';
import { SupplyOrBorrow } from 'features/Popup';
import { useIsMobile } from 'features/UI/hooks';
import { selectWalletBalancesLoadingStatus } from 'features/Wallet/state/Wallet.selector';
import { LoadingStatus } from 'interfaces/Types';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTableTokenData } from './hooks';
import { TokenTableDesktop } from './TokenTableDesktop';
import { TokenTableMobile } from './TokenTableMobile';
import { Tabs } from './types';

export function TokenTable(): ReactElement {
  const tokensLoadingStatus = useSelector(selectMarketTokensLoadingStatus);
  const balancesLoadingStatus = useSelector(selectWalletBalancesLoadingStatus);
  const isMobile = useIsMobile();
  const { publicKey } = useWallet();
  const [tableLoadingStatus, setTableLoadingStatus] = useState(LoadingStatus.Loading);
  const [selectedMarket, setSelectedMarket] = useState(Tabs.Tokens);
  const tableTokenData = useTableTokenData(selectedMarket);
  const [selectedToken, setSelectedToken] = useState(null);
  const [selectedType, setSelectedType] = useState(undefined);

  const handleMarketChange = useCallback((market): () => void => (): void => {
    setSelectedMarket(market);
  }, []);

  useEffect(() => {
    if (!publicKey && tokensLoadingStatus === LoadingStatus.Success) {
      setTableLoadingStatus(LoadingStatus.Idle);
    }

    if (publicKey && balancesLoadingStatus === LoadingStatus.Success) {
      setTableLoadingStatus(LoadingStatus.Idle);
    }
  }, [balancesLoadingStatus, publicKey, tokensLoadingStatus]);

  if (tableLoadingStatus === LoadingStatus.Loading) {
    return (
      <div className="spinner-container"><Spin /></div>
    );
  }
  return (
    <>
      {selectedToken && (
      <SupplyOrBorrow
        visible={selectedToken}
        tokenPubkey={selectedToken}
        defaultTab={selectedType}
        onClose={setSelectedToken}
      />
      )}

      {isMobile
        ? (
          <TokenTableMobile
            handleMarketChange={handleMarketChange}
            tableTokenData={tableTokenData}
            selectedMarket={selectedMarket}
            setSelectedToken={setSelectedToken}
            setSelectedType={setSelectedType}
          />
        ) : (
          <TokenTableDesktop
            handleMarketChange={handleMarketChange}
            loadingStatus={tableLoadingStatus}
            tableTokenData={tableTokenData}
            selectedMarket={selectedMarket}
            setSelectedToken={setSelectedToken}
          />

        )}
    </>
  );
}
