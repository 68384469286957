import { setTheme } from 'features/Theme/state/Theme.actions';
import { themeSelectType } from 'features/Theme/state/Theme.selector';
import { ReactComponent as LightModeSVG } from 'icons/day.svg';
import { ReactComponent as DarkModeSVG } from 'icons/night.svg';
import React, { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsMobile } from '../hooks';
import './ModeSwitcher.css';

export function ModeSwitcher(): ReactElement {
  const theme = useSelector(themeSelectType);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const handleThemeSwitch = useCallback(() => {
    if (theme === 'light') {
      dispatch(setTheme('dark'));
      document.body.classList.remove('light');
      document.body.classList.add('dark');
    } else {
      dispatch(setTheme('light'));
      document.body.classList.remove('dark');
      document.body.classList.add('light');
    }
  }, [dispatch, theme]);

  if (isMobile) {
    return (
      <ul>
        <li style={{ borderRadius: '15px' }}>
          <button type="button" onClick={handleThemeSwitch}>
            <span className="icon">{theme === 'light' ? <DarkModeSVG /> : <LightModeSVG />}</span>
            {theme === 'light' ? <span className="text">Switch to dark mode</span> : <span className="text">Switch to light mode</span>}
          </button>
        </li>
      </ul>
    );
  }
  return (
    <span className="switcher" onClick={handleThemeSwitch}>
      {theme === 'light' ? <DarkModeSVG /> : <LightModeSVG />}
    </span>
  );
}
