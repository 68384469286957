import { createSlice } from '@reduxjs/toolkit';

export interface ConnectionSlice {
  currentNetwork: string;
}

const initialState: ConnectionSlice = {
  currentNetwork: process.env.REACT_APP_CONNECTION_ENDPOINT,
};

export const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    setConnection: (state, { payload }) => {
      state.currentNetwork = payload;
    },
  },
});

export const connectionReducer = connectionSlice.reducer;
