// @ts-nocheck
import { WalletContextState } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';
import BigNumber from 'bignumber.js';
import { Provider } from 'client';
import BorrowLending from 'client/borrowLending';
import { INTERNAL_DECIMALS } from 'client/mainAccount';
import { Program } from 'client/program';
import TokenParametersAccount from 'client/tokenParametersAccount';
import { calculatorService } from 'features/Calculator/Calculator.service';
import { walletLoadBalances } from 'features/Wallet/state/Wallet.actions';
import { TokenData } from 'interfaces/Token.interface';
import { LoadingStatus } from 'interfaces/Types';
import { AppDispatch } from 'store/store';
import { convertFromBN, numberToFixed } from 'utils';
import { MINTS } from '../models/Market.model';
import { marketSlice } from './Market.slice';

export const { setTokenList, setTokensLoadingStatus } = marketSlice.actions;

export const marketTokensLoad = (connection) => async (dispatch: AppDispatch): void => {
  dispatch(setTokensLoadingStatus(LoadingStatus.Loading));
  try {
    const guestProvider = await Provider.guest(connection._rpcEndpoint);
    const guestProgram = await Program.getProgram(guestProvider);
    const program = await BorrowLending.create(guestProgram);

    const mainData = program.mainAccount.staticData;
    const tokenParameters = await TokenParametersAccount.create(program.program, program.mainAccount.address);

    const tokens = MINTS.reduce((acc, token, i) => {
      const { name, symbol, mintAddress, imageUrl, decimals, formatDecimals } = token;
      const depositTotal = numberToFixed(convertFromBN(mainData.balancesMap[mintAddress].depositTotal, INTERNAL_DECIMALS), decimals);
      const borrowTotal = numberToFixed(convertFromBN(mainData.balancesMap[mintAddress].borrowTotal, INTERNAL_DECIMALS), decimals);

      const { apy, apr } = tokenParameters.getTokenRate(token.symbol, borrowTotal, depositTotal);
      const utilization = calculatorService.getUtilization(borrowTotal, depositTotal);
      const available = BigNumber.maximum(new BigNumber(depositTotal).minus(borrowTotal), 0);
      const price = convertFromBN(mainData.pricesMap[mintAddress], INTERNAL_DECIMALS);

      acc[mintAddress.toString()] = {
        index: i,
        key: name,
        id: mintAddress.toString(),
        symbol,
        name,
        imageUrl,
        mintPubkey: new PublicKey(mintAddress).toString(),
        deposited: {
          token: depositTotal,
          usd: numberToFixed(depositTotal * price),
        },
        depositApy: numberToFixed(apy, decimals),
        borrowed: {
          token: borrowTotal,
          usd: numberToFixed((borrowTotal * price)),
        },
        borrowApr: numberToFixed(apr, decimals),
        utilization: numberToFixed(utilization, decimals),
        available: {
          token: numberToFixed(available, decimals),
          usd: numberToFixed(available * price),
        },
        usdAmount: price,
        decimals,
        formatDecimals,
      };
      return acc;
    }, {} as { [key: string]: TokenData });

    dispatch(setTokenList(tokens));
    dispatch(setTokensLoadingStatus(LoadingStatus.Success));
    return tokens;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    dispatch(setTokensLoadingStatus(LoadingStatus.Fail));
  }
};

export const marketTokensLoadAndBalances = (connection, wallet: WalletContextState): () => Promise<void> => async (dispatch: AppDispatch): Promise<void> => {
  await dispatch(marketTokensLoad(connection));
  await dispatch(walletLoadBalances(connection, wallet));
};
