import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const selectStakingSlice = (state: RootState) => state.staking;

export const selectStakingStatistics = createSelector(
  selectStakingSlice,
  (state) => state.poolStatistics,
);

export const selectStakingUserBalance = createSelector(
  selectStakingSlice,
  (state) => state.userOxyBalance,
);

export const selectPool = createSelector(
  selectStakingSlice,
  (state) => state.pool,
);

export const selectUserPools = createSelector(
  selectStakingSlice,
  (state) => state.userPools,
);

export const selectUserActivePools = createSelector(
  selectUserPools,
  (pools) => pools.filter((pool) => pool.state === 'active').reverse(),
);

export const selectUserEarlyPools = createSelector(
  selectUserPools,
  (pools) => pools.filter((pool) => pool.state === 'earlyWithdrawal').reverse(),
);

export const selectUserFinishedPools = createSelector(
  selectUserPools,
  (pools) => pools.filter((pool) => pool.state === 'waitingForUnlockTokens').reverse(),
);

export const selectUserPoolsIsLoaded = createSelector(
  selectStakingSlice,
  (state) => state.userPoolsLoaded,
);

export const selectPoolDetails = createSelector(
  selectStakingSlice,
  (state) => state.selectedPool,
);

export const selectIsPoolDetailsVisible = createSelector(
  selectStakingSlice,
  (state) => state.selectedPoolVisible,
);

export const selectIsMobileDetected = createSelector(
  selectStakingSlice,
  (state) => state.mobileEntry,
);
