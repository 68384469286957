import { BN } from '@project-serum/anchor';
import { NATIVE_MINT, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { LAMPORTS_PER_SOL, ParsedConfirmedTransaction, ParsedInstruction, PartiallyDecodedInstruction } from '@solana/web3.js';
import { convertFromBN, numberToFixed } from 'utils';
import { MINTS } from 'features/Market/models/Market.model';
import { Tx } from 'interfaces/History.interface';

const programAddress = process.env.REACT_APP_PROGRAM_ADDRESS;

export async function unpackTransaction(txInfo: ParsedConfirmedTransaction | null): Promise<Tx> {
  if (!txInfo || txInfo.meta.err) {
    return null;
  }

  // wsol withdraw
  if (txInfo.transaction.message.instructions[3] && txInfo.transaction.message.instructions[3].programId.toString() === programAddress) {
    let type = '';
    let amount = 0;
    const token = MINTS.filter((item) => item.mintAddress === NATIVE_MINT.toString())[0];

    if ((txInfo.meta.innerInstructions?.[0].instructions?.[0] as ParsedInstruction)?.parsed?.type === 'transfer') {
      // withdraw
      if ((txInfo.transaction.message.instructions[3] as PartiallyDecodedInstruction).accounts[6]
      && (txInfo.transaction.message.instructions[3] as PartiallyDecodedInstruction).accounts[6].toString() === TOKEN_PROGRAM_ID.toString()) {
        amount = (txInfo.meta.innerInstructions[0].instructions[0] as ParsedInstruction)?.parsed?.info?.amount;
        type = 'withdraw';
      } else if ((txInfo.transaction.message.instructions[3] as PartiallyDecodedInstruction).accounts[5]
      && (txInfo.transaction.message.instructions[3] as PartiallyDecodedInstruction).accounts[5].toString() === TOKEN_PROGRAM_ID.toString()) {
        amount = (txInfo.meta.innerInstructions[0].instructions[0] as ParsedInstruction)?.parsed?.info?.amount;
        type = 'deposit';
      }
      amount /= LAMPORTS_PER_SOL;
      return {
        key: txInfo.transaction.signatures[0],
        time: txInfo.blockTime,
        tokenImg: token?.imageUrl,
        symbol: token?.symbol,
        amount: numberToFixed(amount, token.formatDecimals) || `~${numberToFixed(amount, token.formatDecimals, 'up')}`,
        type,
      };
    }
  }

  // other tokens
  if (txInfo.transaction.message.instructions[0] && txInfo.transaction.message.instructions[0].programId.toString() === programAddress) {
    let type = '';
    let amount = 0;

    const postTokenBalances = txInfo.meta.postTokenBalances[0];
    const token = MINTS.filter((item) => item.mintAddress === postTokenBalances?.mint)[0];

    // TODO Proadik check it please
    if (!token) {
      return null;
    }

    if ((txInfo.meta.innerInstructions?.[0].instructions?.[0] as ParsedInstruction)?.parsed?.type === 'transfer') {
      // withdraw
      if ((txInfo.transaction.message.instructions[0] as PartiallyDecodedInstruction).accounts[6]
      && (txInfo.transaction.message.instructions[0] as PartiallyDecodedInstruction).accounts[6].toString() === TOKEN_PROGRAM_ID.toString()) {
        amount = (txInfo.meta.innerInstructions[0].instructions[0] as ParsedInstruction)?.parsed?.info?.amount;
        type = 'withdraw';
      } else if ((txInfo.transaction.message.instructions[0] as PartiallyDecodedInstruction).accounts[5]
       && (txInfo.transaction.message.instructions[0] as PartiallyDecodedInstruction).accounts[5].toString() === TOKEN_PROGRAM_ID.toString()) {
        amount = (txInfo.meta.innerInstructions[0].instructions[0] as ParsedInstruction)?.parsed?.info?.amount;
        type = 'deposit';
      }

      amount = convertFromBN(new BN(amount), token.decimals);

      return {
        key: txInfo.transaction.signatures[0],
        time: txInfo.blockTime,
        tokenImg: token?.imageUrl,
        symbol: token?.symbol,
        amount: numberToFixed(amount, token.formatDecimals) || `~${numberToFixed(amount, token.formatDecimals, 'up')}`,
        type,
      };
    }
  }
}
