import * as anchor from '@project-serum/anchor';
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, Token } from '@solana/spl-token';
import { Keypair, LAMPORTS_PER_SOL, PublicKey, SYSVAR_CLOCK_PUBKEY, TokenAmount, Transaction, TransactionSignature } from '@solana/web3.js';
import { MainData } from 'interfaces/BorrowLending.interface';
import { convertFromBN, convertToBN } from 'utils';
import MainAccount, { INTERNAL_DECIMALS } from './mainAccount';
import MarginAccount from './marginAccount';
import { getMintInfo } from './mintInfo';
import { WRAPPED_SOL_MINT, wrapSol } from './utils';

// @ts-ignore
const ZERO = new anchor.BN(0);
const ZERO_TOKEN_AMOUNT = {
  amount: '0',
  decimals: INTERNAL_DECIMALS,
  uiAmount: 0,
};

class BorrowLending {
  program: anchor.Program;
  // @ts-ignore
  mainAccount: MainAccount;
  // @ts-ignore
  marginAccount: MarginAccount;
  // @ts-ignore
  tokenAccounts: PublicKey[];
  // @ts-ignore
  wrappedSolAccount: Keypair;

  constructor(program: anchor.Program) {
    this.program = program;
  }

  static async create(program: anchor.Program, options: { shouldInit?: boolean } = {}): Promise<BorrowLending> {
    // console.log('--programFromClass', program.programId.toString());
    const borrowLending = new BorrowLending(program);

    borrowLending.mainAccount = await MainAccount.create(program, options);
    borrowLending.marginAccount = await MarginAccount.create(program, borrowLending.mainAccount.address);
    borrowLending.tokenAccounts = await borrowLending.getTokenAccounts();

    return borrowLending;
  }

  async getTokenAccounts(): Promise<PublicKey[]> {
    const tokenAccounts: PublicKey[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const token of this.mainAccount.staticData.tokens) {
      const { mint } = token;

      const address = await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        mint,
        this.program.provider.wallet.publicKey,
      );

      tokenAccounts.push(address);
    }

    return tokenAccounts;
  }

  async createTokenAccount(mint: PublicKey): Promise<string> {
    const mintIndex = this.mainAccount.getMintIndex(mint);

    const tx = new Transaction();

    tx.add(Token.createAssociatedTokenAccountInstruction(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      mint,
      this.tokenAccounts[mintIndex],
      this.program.provider.wallet.publicKey,
      this.program.provider.wallet.publicKey,
    ));

    return this.program.provider.send(tx);
  }

  async deposit(mint: PublicKey, amount: number): Promise<TransactionSignature> {
    const { decimals } = await getMintInfo(this.program.provider, mint);

    const bnAmount = convertToBN(amount, decimals);
    const mintIndex = this.mainAccount.getMintIndex(mint);
    const bump = this.mainAccount.tokenAccountBumps[mintIndex];

    if (!(await this.marginAccount.isInitialized())) {
      await this.marginAccount.initialize();
    }

    let tx;
    let signers;

    if (mint.equals(WRAPPED_SOL_MINT)) {
      let account;
      ({ tx, signers, account } = await wrapSol(this.program.provider, amount));
      this.wrappedSolAccount = account;
      this.tokenAccounts[mintIndex] = account.publicKey;
    } else {
      tx = new Transaction();
      signers = undefined;
    }

    tx.add(this.program.instruction.deposit(bnAmount, new anchor.BN(bump), {
      accounts: {
        marginAccount: this.marginAccount.address,
        mainAccount: this.mainAccount.address,
        from: this.tokenAccounts[mintIndex],
        to: this.mainAccount.tokenAccounts[mintIndex],
        authority: this.program.provider.wallet.publicKey,
        tokenProgram: TOKEN_PROGRAM_ID,
      },
    }));

    if (mint.equals(WRAPPED_SOL_MINT)) {
      tx.add(Token.createCloseAccountInstruction(
        TOKEN_PROGRAM_ID,
        this.tokenAccounts[mintIndex],
        this.program.provider.wallet.publicKey,
        this.program.provider.wallet.publicKey,
        [],
      ));
    }

    return this.program.provider.send(tx, signers);
  }

  async withdraw(mint: PublicKey, amount: number): Promise<TransactionSignature> {
    const { decimals } = await getMintInfo(this.program.provider, mint);
    const bnAmount = convertToBN(Number(amount), decimals);
    const mintIndex = this.mainAccount.getMintIndex(mint);
    const bump = this.mainAccount.tokenAccountBumps[mintIndex];

    let tx;
    let signers;

    if (mint.equals(WRAPPED_SOL_MINT)) {
      let account;
      ({ tx, signers, account } = await wrapSol(this.program.provider, 0));
      this.wrappedSolAccount = account;
      this.tokenAccounts[mintIndex] = account.publicKey;
    } else {
      tx = new Transaction();
      signers = undefined;
    }

    const tokenAccountInfo = await this.program.provider.connection.getAccountInfo(this.tokenAccounts[mintIndex]);

    if (!tokenAccountInfo && !mint.equals(WRAPPED_SOL_MINT)) {
      await this.createTokenAccount(mint);
    }

    const tryout = {
      marginAccount: this.marginAccount.address.toString(),
      mainSigner: this.mainAccount.signer.toString(),
      mainAccount: this.mainAccount.address.toString(),
      from: this.mainAccount.tokenAccounts[mintIndex].toString(),
      to: this.tokenAccounts[mintIndex].toString(),
      authority: this.program.provider.wallet.publicKey.toString(),
    };

    // eslint-disable-next-line no-console
    console.log('--tryout', tryout);

    tx.add(this.program.instruction.withdraw(bnAmount, new anchor.BN(bump), {
      accounts: {
        marginAccount: this.marginAccount.address,
        mainSigner: this.mainAccount.signer,
        mainAccount: this.mainAccount.address,
        from: this.mainAccount.tokenAccounts[mintIndex],
        to: this.tokenAccounts[mintIndex],
        authority: this.program.provider.wallet.publicKey,
        tokenProgram: TOKEN_PROGRAM_ID,
        clock: SYSVAR_CLOCK_PUBKEY,
      },
    }));

    if (mint.equals(WRAPPED_SOL_MINT)) {
      tx.add(Token.createCloseAccountInstruction(
        TOKEN_PROGRAM_ID,
        this.tokenAccounts[mintIndex],
        this.program.provider.wallet.publicKey,
        this.program.provider.wallet.publicKey,
        [],
      ));
    }

    return this.program.provider.send(tx, signers);
  }

  async isTokenAccountInitialized(mint: PublicKey): Promise<boolean> {
    return (await this.getWalletBalance(mint)) !== undefined;
  }

  async getWalletBalance(mint: PublicKey): Promise<TokenAmount | undefined> {
    try {
      if (mint.toBase58() === '1'.repeat(32)) {
        return undefined;
      }
      if (mint.equals(WRAPPED_SOL_MINT)) {
        const { value } = await this.program.provider.connection.getParsedAccountInfo(this.program.provider.wallet.publicKey);
        const lamports = value?.lamports ?? 0;
        return {
          amount: lamports.toString(),
          uiAmount: lamports / LAMPORTS_PER_SOL,
          decimals: Math.log10(LAMPORTS_PER_SOL),
        };
      }
      const mintIndex = this.mainAccount.getMintIndex(mint);
      const tokenAccountAddress = this.tokenAccounts[mintIndex];
      const tokenAmount = await this.program.provider.connection.getTokenAccountBalance(tokenAccountAddress);
      return tokenAmount.value;
    } catch (e) {
      return undefined;
    }
  }

  async getWalletBalances(isGuest = false): Promise<{ [key: string]: TokenAmount }> {
    const balances: { [key: string]: TokenAmount } = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const token of this.mainAccount.staticData.tokens) {
      const { mint } = token;

      if (isGuest) {
        balances[mint.toString()] = ZERO_TOKEN_AMOUNT;
        continue;
      }
      const balance = await this.getWalletBalance(mint);
      balances[mint.toString()] = balance ?? ZERO_TOKEN_AMOUNT;
    }

    return balances;
  }

  async getMarginBalances({ mainData, marginData }: { mainData?: MainData; marginData?: any }): Promise<{ [key: string]: TokenAmount }> {
    const balances: { [key: string]: TokenAmount } = {};
    marginData = marginData ?? (await this.marginAccount.getData()) as any;

    if (!marginData) {
      return undefined;
    }

    mainData = mainData ?? (await this.mainAccount.getData()) as any;

    // eslint-disable-next-line no-restricted-syntax
    for (const tokenShareItem of marginData.tokenShares) {
      const mintIndex = tokenShareItem.tokenId - 1;

      if (mintIndex < 0) {
        break;
      }

      const publicKey = mainData.tokensPKByIndex[mintIndex];
      const { decimals } = mainData.tokens[mintIndex];
      const tokenShares = tokenShareItem.shares;
      const mainBalance = mainData.balances[mintIndex].balance;
      let tokenAmount = ZERO;
      if (!tokenShares.eq(ZERO)) {
        tokenAmount = (
          tokenShares.gt(ZERO)
            ? tokenShares.mul(mainBalance.depositTotal).div(mainBalance.depositShares)
            : tokenShares.mul(mainBalance.borrowTotal).div(mainBalance.borrowShares)
        );
      }

      balances[publicKey] = {
        amount: tokenAmount.toString(),
        decimals,
        uiAmount: convertFromBN(tokenAmount, INTERNAL_DECIMALS),
      };
    }

    return balances;
  }

  async liquidate(liquidatee: PublicKey): Promise<string> {
    return this.program.rpc.liquidate({
      accounts: {
        liquidateeAccount: liquidatee,
        liquidatorAccount: this.marginAccount.address,
        mainAccount: this.mainAccount.address,
        authority: this.program.provider.wallet.publicKey,
        clock: SYSVAR_CLOCK_PUBKEY,
      },
    });
  }
}

export default BorrowLending;
