import * as anchor from '@project-serum/anchor';
import { BN } from '@project-serum/anchor';
import { PublicKey } from '@solana/web3.js';
import { calculatorService } from 'features/Calculator/Calculator.service';
import { TokensSymbols } from 'interfaces/Types';
import { TokenApyApr } from '../interfaces/Token.interface';

// export type TokenRate = IdlTypes<BorrowLending>['TokenRate'];
export type TokenRate = {
  borrowApr: BN;
  depositApy: BN;
}

class TokenParametersAccount {
  program: anchor.Program;
  data: TokenRate[];
  address: PublicKey;
  private mainAccountAddress: PublicKey;

  constructor(program: anchor.Program, mainAccount: PublicKey) {
    this.program = program;
    this.mainAccountAddress = mainAccount;
  }

  static async create(program: anchor.Program, mainAccount: PublicKey): Promise<TokenParametersAccount> {
    const tokenParametersAccount = new TokenParametersAccount(program, mainAccount);

    const [address] = await PublicKey.findProgramAddress(
      [Buffer.from('token-params'), mainAccount.toBuffer()],
      program.programId,
    );

    tokenParametersAccount.address = address;
    return tokenParametersAccount;
  }

  getTokenRate(tokenSymbol: TokensSymbols, borrowTotal: number, depositTotal: number): TokenApyApr {
    const utilizationRatio = calculatorService.getUtilization(borrowTotal, depositTotal);
    const apr = calculatorService.getTokenApr(tokenSymbol, utilizationRatio);

    return {
      apr,
      apy: calculatorService.getTokenApy(apr, utilizationRatio),
    };
  }
}
export default TokenParametersAccount;
