import React, { ReactElement, useMemo } from 'react';
import { tokenFormatNumeral } from 'utils';
import styles from './HighlightedNumbersByDecimals.module.scss';

export function HighlightedNumbersByDecimals({ amount, decimals, formatDecimals }: {
  amount: number | string;
  decimals: number;
  formatDecimals: number;
}): ReactElement {
  const [bold, fDec, dec] = useMemo(() => {
    const [int, fract] = amount.toString().split('.');
    const boldFormated = tokenFormatNumeral(+int).split('.')[0];
    return [
      `${boldFormated}.${fract.slice(0, formatDecimals)}`,
      fract.slice(formatDecimals, decimals),
      fract.slice(decimals, fract.length),
    ];
  }, [amount, decimals, formatDecimals]);

  return (
    <div>
      <span className={styles.bold}>{bold}</span>
      <span className={styles.medium}>{fDec}</span>
      <span className={styles.light}>{dec}</span>
    </div>
  );
}
