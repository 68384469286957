import { createSlice } from '@reduxjs/toolkit';

export interface ThemeSlice {
  type: 'light' | 'dark';
}

const initialState: ThemeSlice = {
  type: 'light',
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, { payload }) => {
      state.type = payload;
    },
  },
});

export const themeReducer = themeSlice.reducer;
