import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'antd/dist/antd.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Spin } from 'antd';
import { ErrorBoundaryFallback } from 'features/Error/ErrorBoundaryFallback';
import { App } from './features/App';
import './index.css';
import './mobile.css';
import store from './store/store';

if (process.env.REACT_APP_CONNECTION_NETWORK === 'Mainnet') {
  Sentry.init({
    dsn: 'https://312a7c49a326443991836fc1a60d4d01@o1052348.ingest.sentry.io/6158840',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
    environment: 'production',
  });
}

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<div className="spinner-container"><Spin /></div>} persistor={persistor}>
        <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />}>
          <App />
        </Sentry.ErrorBoundary>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
