import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import '@solana/wallet-adapter-react-ui/styles.css';
import {
  CoinbaseWalletAdapter,
  LedgerWalletAdapter,
  MathWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { WalletConnectWalletAdapter } from '@solana/wallet-adapter-walletconnect';
import { NitrogenWalletAdapter } from '@convexitydmcc/wallet-adapter-nitrogen';
import { Layout, message } from 'antd';
import { selectCurrentNetwork } from 'features/Connection/state/Connection.selector';
import { Market, SupportPage } from 'features/Market';
import { Navbar } from 'features/Navbar';
import { useIsMobile } from 'features/UI/hooks';
import { ReactElement, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { Staking } from 'features/Staking';
import { Paths } from 'interfaces/Paths';

const { Content } = Layout;

export function App(): ReactElement {
  const network = WalletAdapterNetwork.Mainnet;
  const isMobile = useIsMobile();
  const endpoint = useSelector(selectCurrentNetwork);

  const wallets = useMemo(() => [
    new NitrogenWalletAdapter(),
    new CoinbaseWalletAdapter(),
    new PhantomWalletAdapter(),
    new WalletConnectWalletAdapter({
      network,
      options: {
        relayUrl: process.env.REACT_APP_PUBLIC_WALLETCONNECT_RELAY_URL,
        projectId: process.env.REACT_APP_PUBLIC_WALLETCONNECT_PROJECT_ID,
        metadata: {
          name: 'Oxygen Light',
          description: 'Oxygen Light',
          url: 'https://light.oxygen.org/',
          icons: [
            'https://light.oxygen.org/favicon.ico',
            'https://light.oxygen.org/static/media/logo.0ea2d1534b70ff1a3c3949cb5af497ce.svg',
          ],
        },
      },
    }),
    new SolflareWalletAdapter(),
    new SolletWalletAdapter({ network }),
    new LedgerWalletAdapter(),
    new MathWalletAdapter(),
    new SlopeWalletAdapter(),
  ], [network]);

  useEffect(() => {
    message.config({
      duration: 5,
    });
  }, []);

  return (
    <ConnectionProvider endpoint={endpoint || process.env.REACT_APP_CONNECTION_ENDPOINT}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <BrowserRouter>
            <Navbar mobile={isMobile} />
            <Layout className={isMobile ? 'mobile-layout' : ''}>
              <Content>
                <Routes>
                  <Route path={Paths.Market} element={<Market />} />
                  <Route path={Paths.Support} element={<SupportPage />} />
                  <Route path={Paths.Staking} element={<Staking />} />
                </Routes>
              </Content>
            </Layout>
          </BrowserRouter>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}
