import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const selectMarketSlice = (state: RootState) => state.market;

export const selectMarketTokens = createSelector(
  selectMarketSlice,
  (state) => state.tokens,
);

export const selectMarketTokensList = createSelector(
  selectMarketSlice,
  (state) => Object.values(state.tokens),
);

export const selectMarketTokensLoadingStatus = createSelector(
  selectMarketSlice,
  (state) => state.tokensLoadingStatus,
);
