import { useWallet } from '@solana/wallet-adapter-react';
import { Button, Collapse, Space, Typography, message } from 'antd';
import { TokenIcon } from 'features/Market/components/TokenIcon/TokenIcon';
import { Statistics } from 'features/Statistics';
import { ReactComponent as ArrowSVG } from 'icons/arrow.svg';
import { ReactElement, useCallback } from 'react';
import { TableTokenData } from './hooks';
import { Tabs } from './types';

const { Text } = Typography;
const { Panel } = Collapse;

export function TokenTableMobile({
  handleMarketChange,
  tableTokenData,
  selectedMarket,
  setSelectedToken,
  setSelectedType,
}: {
  handleMarketChange: (market: Tabs) => () => void;
  selectedMarket: Tabs;
  setSelectedToken: (pubKey: string) => void;
  setSelectedType: (tab: string) => void;
  tableTokenData: TableTokenData[];
}): ReactElement {
  const { connected } = useWallet();

  const handlePopup = useCallback((record: TableTokenData, tab: string): () => void => (): void => {
    if (connected) {
      setSelectedType(tab);
      setSelectedToken(record.mintPubkey);
    } else {
      message.warning('Connect your wallet to deposit or withdraw from the protocol.');
    }
  }, [connected, setSelectedToken, setSelectedType]);

  return (
    <div className="mobile-token-table">
      {connected && <Statistics />}
      <h2>
        <Space size={25} style={{ justifyContent: 'space-between' }}>
          All markets
          <Space>
            <Button
              key="tokens"
              onClick={handleMarketChange(Tabs.Tokens)}
              type={selectedMarket === Tabs.Tokens ? 'primary' : 'default'}
              shape="round"
              size="small"
            >
              Tokens
            </Button>
            <Button
              key="usdc"
              onClick={handleMarketChange(Tabs.Usd)}
              type={selectedMarket === Tabs.Usd ? 'primary' : 'default'}
              shape="round"
              size="small"
              className="empty"
            >
              $USD
            </Button>
          </Space>
        </Space>
      </h2>
      <div className="mobile-market-table">
        {tableTokenData.map((record) => (
          <Collapse accordion key={record.key}>
            <Panel
              header={(
                <div className="market-row">
                  <div className="market-id">
                    <TokenIcon userAssets={record.userAssets} url={record.imageUrl} tokenName={record.name} />
                    <Text style={{ marginLeft: '10px' }} className="bolder">{record.symbol}</Text>
                  </div>
                  <Text>
                    <span className="tablehint">Deposit APY</span>
                    {' '}
                    <span className="bolder green">{record.depositApy}</span>
                  </Text>
                  <Text>
                    <span className="tablehint">Borrow APR</span>
                    {' '}
                    <span className="bolder red">{record.borrowApr}</span>
                  </Text>
                  <ArrowSVG />
                </div>
            )}
              key={record.id}
            >
              <div className="market-row-inner">
                <p>
                  <Text className="text">Total supply:</Text>
                  <Text className="number">{record.totalDeposited}</Text>
                </p>
                <p>
                  <Text className="text">Total borrow:</Text>
                  <Text className="number">{record?.totalBorrowed}</Text>
                </p>
                <p>
                  <Text className="text">Total available:</Text>
                  <Text className="number">{record.totalAvailable}</Text>
                  <Text className="tablehint">{record.utilization}</Text>
                </p>
                <hr />
                <p>
                  <Text className="text">Deposited:</Text>
                  <Text className="number">{record.deposited}</Text>
                </p>
                <p>
                  <Text className="text">Borrowed:</Text>
                  <Text className="number">{record.borrowed}</Text>
                  <Text className="tablehint">
                    <span>{record.avaliable}</span>
                    {' '}
                    Available
                  </Text>
                </p>
                <p>
                  <Text className="text">Wallet balance:</Text>
                  <Text className="number">{record.walletBalance}</Text>
                </p>
                <div className="action-buttons">
                  <Button onClick={handlePopup(record, 'withdraw')} type="primary" className="empty" block>Withdraw</Button>
                  <Button onClick={handlePopup(record, 'deposit')} type="primary" block>Deposit</Button>
                </div>
              </div>
            </Panel>
          </Collapse>
        ))}
      </div>
    </div>
  );
}
