import BigNumber from 'bignumber.js';
import { TokenData } from 'interfaces/Token.interface';
import { TokensSymbols } from 'interfaces/Types';
import { WalletBalances } from 'interfaces/Wallet.interface';
import { ASSET_INTEREST_RATES } from './constants';

class CalculatorService {
  solTxFee = new BigNumber(0.000005); // tx fee
  solTxAccountHold = new BigNumber(0.003); // account creation hold sol + a bit more sol while deposit
  solTxAccountHoldWithdraw = new BigNumber(0.00204928); // account creation hold sol for checking while withdraw
  riskPercent = new BigNumber(1.1);
  oxyBorrowPercent = 0;

  yieldPerDay(apy: number, amount: number): number {
    return new BigNumber(apy).multipliedBy(amount).dividedBy(365).toNumber();
  }

  getMaxAmount(token: TokenData, amount: number): number {
    if (token.symbol === TokensSymbols.Sol) {
      return BigNumber.maximum(0, new BigNumber(amount).minus(this.solTxFee.plus(this.solTxAccountHold))).toNumber();
    }

    return amount;
  }

  getBorrowLimit(token: TokenData, deposited: number, borrowed: number): number {
    return BigNumber.maximum(
      0,
      new BigNumber(deposited)
        .dividedBy(this.riskPercent)
        .minus(borrowed)
        .dividedBy(token.usdAmount),
    ).toNumber();
  }

  getWithdrawLimit(token: TokenData, deposited: number, borrowed: number): number {
    return BigNumber.maximum(
      0,
      new BigNumber(deposited)
        .minus(new BigNumber(borrowed).multipliedBy(this.riskPercent).plus(0.01)) // plus 1 cent
        .dividedBy(token.usdAmount),
    ).toNumber();
  }

  getBorrowTotalAvailable(token: TokenData, borrowLimit: number): number {
    return BigNumber.minimum(borrowLimit, token?.available.token).toNumber();
  }

  getWithdrawTotalAvailable(token: TokenData, withdrawLimit: number, balance: WalletBalances, borrowed: number): number {
    if (borrowed > 0) {
      return BigNumber.minimum(
        token.available.token,
        balance.deposited.token,
        withdrawLimit,
      ).toNumber();
    }
    return BigNumber.minimum(token.available.token, balance.deposited.token).toNumber();
  }

  getIsUnderLiquidation(walletTotalDepositedUsd: number, walletTotalBorrowedUsd: number): boolean {
    return new BigNumber(walletTotalDepositedUsd).dividedBy(walletTotalBorrowedUsd).isLessThan(this.riskPercent);
  }

  getBorrowTotalFee(token: TokenData, amount: number): number {
    return token?.borrowApr > 0
      ? new BigNumber(token?.borrowApr).minus(this.oxyBorrowPercent).multipliedBy(amount).dividedBy(365)
        .toNumber()
      : 0;
  }

  getTokenApr(tokenSymbol: TokensSymbols, utilizationRatio: number | BigNumber): number {
    let apr = new BigNumber(0);
    const utilizationRatioBN = new BigNumber(utilizationRatio);

    if (utilizationRatioBN.isLessThanOrEqualTo(ASSET_INTEREST_RATES[tokenSymbol].OptimalUtilization)) {
      // (utilization/optimal_utilization) * optimal_rate
      apr = utilizationRatioBN
        .dividedBy(ASSET_INTEREST_RATES[tokenSymbol].OptimalUtilization)
        .multipliedBy(ASSET_INTEREST_RATES[tokenSymbol].OptimalRate);
    } else {
      // (utilization - optimal_utilization)/(1 - optimal_utilization) * max_rate + optimal_rate
      apr = utilizationRatioBN
        .minus(ASSET_INTEREST_RATES[tokenSymbol].OptimalUtilization)
        .dividedBy(new BigNumber(1).minus(ASSET_INTEREST_RATES[tokenSymbol].OptimalUtilization))
        .multipliedBy(ASSET_INTEREST_RATES[tokenSymbol].MaxRate)
        .plus(ASSET_INTEREST_RATES[tokenSymbol].OptimalRate);
    }
    return apr.toNumber();
  }

  getTokenApy(apr: number, utilizationRatio: number | BigNumber): number {
    return new BigNumber(apr).multipliedBy(utilizationRatio).toNumber();
  }

  getUtilization(borrowed: number | BigNumber, deposited?: number | BigNumber): number {
    const result = new BigNumber(borrowed).dividedBy(deposited);
    if (!borrowed || !result.isFinite() || result.isNaN()) { // if borrowed is 0
      return 0;
    }
    return result.toNumber();
  }

  getMinimumPaymentUnit(): number {
    return 1 / 10 ** 6;
  }
}

export const calculatorService = new CalculatorService();
