import React, { ChangeEventHandler, ReactElement, useCallback, useRef } from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import './BetterInputNumber.css';

export function BetterInputNumber({ decimals, placeholder, value, onChange, id, isDisabled, integerLimit }: {
  decimals: number;
  onChange: (value: any) => void;
  placeholder: string;
  value: number;
  id?: string;
  integerLimit?: number;
  isDisabled?: boolean;
}): ReactElement {
  const currencyMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: decimals,
    allowNegative: false,
    allowLeadingZeroes: false,
    integerLimit,
  });

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    onChange(e.target.value);
  }, [onChange]);

  const ref = useRef<HTMLDivElement>(null);
  const handleToggleClass = useCallback(() => {
    ref.current.classList.toggle('ant-input-number-focused');
  }, []);

  return (
    <div className="ant-input-number ant-input-number-lg inputPopup" ref={ref}>
      <div className="ant-input-number-input-wrap">
        <MaskedInput
          id={id}
          mask={currencyMask}
          className="ant-input-number-input"
          style={{
            flex: 1,
            borderColor: 'rgb(236, 236, 236)',
            width: '100%',
            borderRadius: '5px',
          }}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onBlur={handleToggleClass}
          onFocus={handleToggleClass}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
}
