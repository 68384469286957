import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';
import { Button, Input, Space, Spin } from 'antd';
import { Market } from 'features/Market';
import { marketTokensLoadAndBalances } from 'features/Market/state/Market.actions';
import { clearBalances, setPublicKey } from 'features/Wallet/state/Wallet.actions';
import { selectWalletBalancesLoadingStatus } from 'features/Wallet/state/Wallet.selector';
import { LoadingStatus } from 'interfaces/Types';
import { ReactElement, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './SupportPage.module.scss';

export function SupportPage(): ReactElement {
  const dispatch = useDispatch();
  const { connection } = useConnection();
  const [inputValue, setInputValue] = useState('');
  const [pk, setPk] = useState<PublicKey>(null);
  const wallet = useWallet();
  const balancesLoadingStatus = useSelector(selectWalletBalancesLoadingStatus);
  const handleChange = useCallback((e) => {
    setInputValue(e.target.value);
    setPk(null);
    dispatch(setPublicKey(''));
    dispatch(clearBalances());
  }, [dispatch]);

  const ref = useRef<Input>();
  const handleGetInfo = useCallback(async () => {
    if (inputValue) {
      const publicKey = new PublicKey(inputValue);
      setPk(publicKey);
      dispatch(setPublicKey(inputValue));
      dispatch(marketTokensLoadAndBalances(connection, { ...wallet, publicKey }));
    }
  }, [inputValue, connection, dispatch, wallet]);

  return (
    <div className={styles.wrapper}>
      <Space className={styles.addressBlock}>
        <Input
          value={inputValue}
          onChange={handleChange}
          allowClear
          ref={ref}
        />
        <Button
          className={styles.button}
          onClick={handleGetInfo}
          type="primary"
        >
          {balancesLoadingStatus === LoadingStatus.Loading && pk
            ? <Spin />
            : 'Get Info'}
        </Button>
      </Space>
      <Market walletPubkey={pk} />
    </div>
  );
}
