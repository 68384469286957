import { useWallet } from '@solana/wallet-adapter-react';
import { Button, Form, Input, Modal, Select, Spin, Switch, Typography, message } from 'antd';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectWalletBalancesList, selectWalletBalancesLoadingStatus } from 'features/Wallet/state/Wallet.selector';
import { LoadingStatus } from 'interfaces/Types';
import QRCode from 'qrcode';
import { catchError } from 'features/Error/Error.program';
import './Alert.css';
import { ReactComponent as QRSvg } from 'icons/qr.svg';
import { ReactComponent as URLSvg } from 'icons/url.svg';

const ALERT_URL = process.env.REACT_APP_ALERTS_URL;
const AVAILABLE_AWAYS = [1, 5, 10, 15, 20, 25, 30, 35, 40];
const TELEGRAM_BOT_NAME = 'oxygen_light_alerts_bot';

export function Alert({ isModalOpen, toggleAlert }: {
  isModalOpen: boolean;
  toggleAlert: Function;
}): ReactElement {
  const [form] = Form.useForm();
  const wallet = useWallet();
  const balances = useSelector(selectWalletBalancesList);
  const [isLoading, setIsLoading] = useState(LoadingStatus.Idle);
  const [alert, setAlert] = useState(null);
  const [isBalancesLoaded, setIsBalancesLoaded] = useState(false);
  const walletBalancesLoadingStatus = useSelector(selectWalletBalancesLoadingStatus);
  const [ltvAwayTg, setLtvAwayTg] = useState(0);
  const [qrUrl, setQrUrl] = useState('');
  const [tgHidden, setTgHidden] = useState(true);
  const [emailHidden, setEmailHidden] = useState(true);

  const currentLtv = useMemo(() => {
    const deposited = balances.map((balance) => balance.deposited.usd).reduce((prev, next) => prev + next, 0);
    const borrowed = balances.map((balance) => balance.borrowed.usd).reduce((prev, next) => prev + next, 0);
    return (borrowed / deposited) > 0 ? borrowed / deposited : 0;
  }, [balances]);

  const fetchUserAlerts = useCallback(async (): Promise<void> => {
    try {
      const alertResponse = await fetch(`${ALERT_URL}/get-user/${wallet.publicKey}`);
      const json = await alertResponse.json();
      if (!json.error) {
        setAlert(json);
      } else {
        setAlert(null);
      }
    } catch (e) {
      message.error(e.message);
    }
  }, [wallet.publicKey]);

  const onOk = useCallback(async () => {
    if (!form.getFieldValue('email') && !emailHidden) {
      form.validateFields(['email']);
      form.setFieldsValue({
        alertTypeEmail: true,
      });
      setEmailHidden(false);
      return;
    }

    setIsLoading(LoadingStatus.Loading);

    try {
      let url = `${ALERT_URL}/add-user`;
      let telegramId = null;

      if (alert) {
        url = `${ALERT_URL}/update-user/${wallet.publicKey}`;
        telegramId = alert.telegramId;
      }

      const data = await fetch(url, {
        method: 'POST',
        body: new URLSearchParams({
          email: form.getFieldValue('email'),
          ltvAway: form.getFieldValue('ltvAway'),
          currentLtv: form.getFieldValue('currentLtv'),
          wallet: form.getFieldValue('wallet'),
          telegramId,
        }),
      });

      const json = await data.json();

      if (json.error) {
        message.error(json.content);
      }

      if (!json.error) {
        message.success(json.content);
        await fetchUserAlerts();
      }
    } catch (e) {
      message.error(e.message);
    }

    setIsLoading(LoadingStatus.Success);
  }, [form, emailHidden, alert, wallet.publicKey, fetchUserAlerts]);

  const onCancel = useCallback(() => {
    toggleAlert();
    form.resetFields();
  }, [form, toggleAlert]);

  const handlePercentChange = useCallback((e) => {
    setLtvAwayTg(e);
  }, []);

  const handleQRGeneration = useCallback(async () => {
    if (!ltvAwayTg) {
      form.validateFields(['ltvAway']);
      return;
    }

    try {
      const url = await QRCode.toDataURL(`https://t.me/${TELEGRAM_BOT_NAME}?start=${wallet.publicKey.toString()}-${ltvAwayTg}`, {
        margin: 0,
        width: 115,
      });
      setQrUrl(url);
    } catch (e) {
      catchError(e);
    }
  }, [form, ltvAwayTg, wallet.publicKey]);

  const handleTelegramOpen = useCallback(() => {
    if (!ltvAwayTg) {
      form.validateFields(['ltvAway']);
      return;
    }

    toggleAlert();
    const url = `https://t.me/${TELEGRAM_BOT_NAME}?start=${wallet.publicKey.toString()}-${ltvAwayTg}`;
    window.open(url, '_blank');
  }, [form, ltvAwayTg, toggleAlert, wallet.publicKey]);

  useEffect(() => {
    if (!isBalancesLoaded && walletBalancesLoadingStatus === LoadingStatus.Success) {
      setIsBalancesLoaded(true);
    }
  }, [isBalancesLoaded, walletBalancesLoadingStatus]);

  useEffect(() => {
    if (isModalOpen) {
      const init = async (): Promise<void> => {
        try {
          const alertResponse = await fetch(`${ALERT_URL}/get-user/${wallet.publicKey}`);
          const json = await alertResponse.json();
          if (!json.error) {
            setAlert(json);
          } else {
            setAlert(null);
          }
        } catch (e) {
          message.error(e.message);
        }
      };

      init();
    }

    return (): void => {
      setLtvAwayTg(0);
      setQrUrl('');
      setTgHidden(true);
      setEmailHidden(true);
      form.setFieldsValue({
        email: '',
        ltvAway: '',
      });
    };
  }, [form, isModalOpen, wallet.publicKey]);

  useEffect(() => {
    if (!alert) {
      return;
    }

    setLtvAwayTg(alert.ltvAway);

    form.setFieldsValue({
      ltvAway: alert.ltvAway,
      currentLtv,
      email: alert.email,
    });
  }, [alert, currentLtv, fetchUserAlerts, form]);

  useEffect(() => {
    if (!alert) {
      return;
    }

    if (alert.email) {
      setEmailHidden(false);
      form.setFieldsValue({
        alertTypeEmail: true,
      });
    }

    if (alert.telegramId) {
      setTgHidden(false);
      form.setFieldsValue({
        alertTypeTelegram: true,
      });
    }
  }, [alert, form]);

  if (!wallet.publicKey) {
    return <></>;
  }

  return (
    <Modal
      className="alert-modal"
      title="Set up loan health alert"
      visible={isModalOpen}
      onCancel={onCancel}
      footer={(
        <Button
          disabled={!ltvAwayTg}
          loading={isLoading === LoadingStatus.Loading}
          onClick={onOk}
          type="primary"
          shape="round"
          block
          htmlType="submit"
        >
          {alert ? 'Update alert' : 'Save alert'}
        </Button>
      )}
      closable
      maskClosable
      centered
    >
      {!isBalancesLoaded ? <Spin /> : (
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            ltvAway: alert ? alert.ltvAway : '',
            currentLtv,
            wallet: wallet.publicKey.toString(),
            email: alert ? alert.email : '',
          }}
        >
          <Typography.Text className="alert-notification-title">Notification</Typography.Text>
          <Form.Item
            name="ltvAway"
            label={(
              <>
                Send me notification if
                <span className="alert-away-bold">Away from critical LTV</span>
                reaches:
              </>
            )}
            rules={[{ required: true, message: 'Away from critical LTV is required' }]}
          >
            <Select onChange={handlePercentChange}>
              {AVAILABLE_AWAYS.map((percent, i) => (
                <Select.Option key={i} value={percent}>{`${percent}%`}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="currentLtv" hidden>
            <Input type="text" />
          </Form.Item>

          <Form.Item name="wallet" hidden>
            <Input type="text" />
          </Form.Item>

          <Typography.Text className="alert-notification-title">Alert me via:</Typography.Text>

          <div className="alert-modal-type-block">
            <Form.Item className="alert-modal-type" name="alertTypeTelegram" label="Telegram" valuePropName="checked">
              <Switch checked={!tgHidden} onChange={(e): void => setTgHidden(!e)} />
            </Form.Item>

            <div className="alert-modal-telegram" hidden={tgHidden}>
              {alert && alert.telegramId ? (
                <Typography.Text type="secondary" style={{ marginBottom: 0 }}>
                  Notification Away from critical LTV setted to —
                  <br />
                  {alert.ltvAway}
                  %
                </Typography.Text>
              ) : (
                <>
                  <Typography.Text type="secondary">Generate and scan QR code or open telegram</Typography.Text>
                  {qrUrl && <img src={qrUrl} className="alert-qr" alt="qr" />}
                  <p>
                    <a onClick={handleQRGeneration}>
                      <QRSvg />
                      Generate Telegram QR code
                    </a>
                  </p>
                  <p>
                    <a onClick={handleTelegramOpen}>
                      <URLSvg />
                      Open Telegram
                    </a>
                  </p>
                </>
              )}
            </div>
          </div>

          <Form.Item className="alert-modal-type borderless" name="alertTypeEmail" label="Email" valuePropName="checked">
            <Switch onChange={(e): void => setEmailHidden(!e)} />
          </Form.Item>

          <Form.Item className="alert-email-input" name="email" hidden={emailHidden} label="" rules={[{ required: true, type: 'email', message: 'Email is required' }]}>
            <Input inputMode="email" placeholder="Enter email" />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}
