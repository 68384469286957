import * as anchor from '@project-serum/anchor';
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { WalletContextState } from '@solana/wallet-adapter-react';
import { Connection, Keypair, PublicKey } from '@solana/web3.js';
import moment from 'moment-timezone';
import { OxyReward } from 'interfaces/Staking.interface';
import { numberToFixed } from 'utils';
import { EIGHTEEN_MONTHS, NINE_MONTHS, SEVEN_DAYS, SIX_MONTHS, STAKING_PERIOD_VALUES, THREE_MONTHS, TWELWE_MONTHS, TWO_YEARS } from '../models/Staking.model';
import STAKING_IDL from '../program/staking.json';
import { OXY_STAKING_PROGRAM, OXY_TOKEN_MINT, commitment } from '../program/Staking.program';

moment.tz.setDefault('UTC');

export const generateInfo = (countInDays: number, oxyAmount: number, apy: number): OxyReward => ({
  oxyCount: numberToFixed(((oxyAmount * apy * countInDays) / 365) / 100, 2),
  date: moment().add(countInDays, 'days').format('DD MMMM YYYY'),
  apy,
});

export const checkDaysRange = (countInDays: number, oxyAmount: number, apy: number[]): OxyReward => {
  if (countInDays < SEVEN_DAYS) {
    return generateInfo(0, 0, 0);
  }
  if (countInDays >= SEVEN_DAYS && countInDays < THREE_MONTHS) {
    return generateInfo(countInDays, oxyAmount, apy[0]);
  }
  if (countInDays >= THREE_MONTHS && countInDays < SIX_MONTHS) {
    return generateInfo(countInDays, oxyAmount, apy[1]);
  }
  if (countInDays >= SIX_MONTHS && countInDays < NINE_MONTHS) {
    return generateInfo(countInDays, oxyAmount, apy[2]);
  }
  if (countInDays >= NINE_MONTHS && countInDays < TWELWE_MONTHS) {
    return generateInfo(countInDays, oxyAmount, apy[3]);
  }
  if (countInDays >= TWELWE_MONTHS && countInDays < EIGHTEEN_MONTHS) {
    return generateInfo(countInDays, oxyAmount, apy[4]);
  }
  if (countInDays >= EIGHTEEN_MONTHS && countInDays < TWO_YEARS) {
    return generateInfo(countInDays, oxyAmount, apy[5]);
  }
  if (countInDays >= TWO_YEARS) {
    return generateInfo(countInDays, oxyAmount, apy[6]);
  }
};

export const calculateRewards = (countInDays: number, oxyAmount: number): OxyReward => {
  if (!countInDays || !oxyAmount) {
    return generateInfo(1, 0, 0);
  }

  if (oxyAmount >= 100 && oxyAmount < 1000) {
    return checkDaysRange(countInDays, oxyAmount, [
      3, 4, 5, 6, 7, 9.5, 12,
    ]);
  }

  if (oxyAmount >= 1000 && oxyAmount < 10_000) {
    return checkDaysRange(countInDays, oxyAmount, [
      4, 5, 6, 7, 8, 10.5, 13,
    ]);
  }

  if (oxyAmount >= 10_000 && oxyAmount < 100_000) {
    return checkDaysRange(countInDays, oxyAmount, [
      5, 6, 7, 8, 9, 11.5, 14,
    ]);
  }

  if (oxyAmount >= 100_000) {
    return checkDaysRange(countInDays, oxyAmount, [
      6, 7, 8, 9, 10, 12.5, 15,
    ]);
  }

  return generateInfo(countInDays, 0, 0);
};

export const createProvider = async (connection: Connection, wallet: WalletContextState): Promise<anchor.Provider> => new anchor.Provider(connection, wallet, { commitment });

export const createProgram = async (connection: Connection, wallet?: WalletContextState): Promise<anchor.Program> => {
  let provider;
  if (wallet) {
    provider = await createProvider(connection, wallet);
  } else {
    const reader = new Keypair();
    provider = await createProvider(connection, reader as any);
  }
  // @ts-ignore
  return new anchor.Program(STAKING_IDL, OXY_STAKING_PROGRAM, provider);
};

export async function getPoolAddress(program: anchor.Program): Promise<[PublicKey, number]> {
  return PublicKey.findProgramAddress(
    [
      Buffer.from(anchor.utils.bytes.utf8.encode('oxy_staking_pool')),
      OXY_TOKEN_MINT.toBuffer(),
    ],
    program.programId,
  );
}

export async function findAssociatedTokenAddress(walletAddress: PublicKey, tokenMintAddress: PublicKey): Promise<PublicKey> {
  return (await PublicKey.findProgramAddress(
    [
      walletAddress.toBuffer(),
      TOKEN_PROGRAM_ID.toBuffer(),
      tokenMintAddress.toBuffer(),
    ],
    ASSOCIATED_TOKEN_PROGRAM_ID,
  ))[0];
}

export async function getMainUserStakeContractAddress(
  poolAddress: PublicKey,
  wallet: WalletContextState,
  program: anchor.Program,
): Promise<[PublicKey, number]> {
  return PublicKey.findProgramAddress(
    [
      Buffer.from(anchor.utils.bytes.utf8.encode('main_user_stake_contract')),
      wallet.publicKey.toBuffer(),
      poolAddress.toBuffer(),
    ],
    program.programId,
  );
}

export async function getUserStakeContractAddress(
  poolAddress: PublicKey,
  wallet: WalletContextState,
  nextContractNumber: number,
  program: anchor.Program,
): Promise<[PublicKey, number]> {
  return PublicKey.findProgramAddress(
    [
      Buffer.from(anchor.utils.bytes.utf8.encode('user_stake_contract')),
      wallet.publicKey.toBuffer(),
      poolAddress.toBuffer(),
      Buffer.from(anchor.utils.bytes.utf8.encode(nextContractNumber.toString())),
    ],
    program.programId,
  );
}

// @ts-ignore
export const getMonthsInDays = (month: number): number => STAKING_PERIOD_VALUES[month];

export const getFullTime = (start: number, end: number, countIn: any): number => Math.abs(moment.unix(start).diff(moment.unix(end), countIn));

export const getLeftTime = (end: number, countIn: any): number => Math.ceil(moment.unix(end).diff(moment(), countIn, true));

export const isPast = (end: number, countIn: any): boolean => moment.unix(end).diff(moment(), countIn) < 0;
