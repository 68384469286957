import numeral from 'numeral';
import moment from 'moment';

export const percentFormat = new Intl.NumberFormat('us-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  style: 'percent',
});

export const percentFormatOne = new Intl.NumberFormat('us-US', {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  style: 'percent',
});

export const quoteSizeFormat = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: false,
});

export const leverageFormat = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
});

export const wholePercentFormat = new Intl.NumberFormat(undefined, {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const dollarFormat = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const coinSizeFormat = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 8,
  maximumFractionDigits: 8,
  useGrouping: true,
});

export const twoDecimalFormat = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: true,
});

export const fourDecimalFormat = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 4,
  maximumFractionDigits: 4,
  useGrouping: true,
});

export const fundingRateFormat = new Intl.NumberFormat(undefined, {
  style: 'percent',
  minimumFractionDigits: 4,
  maximumFractionDigits: 4,
});

export const feeFormat = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 6,
  maximumFractionDigits: 6,
});

const zeroNotNaN = (n: string): string => (['NaN', '$NaN', 'N$aN'].includes(n) ? '0' : n);
const zeroToDash = (n: string): string => (['$0.00'].includes(n) ? '-' : n);

export const dollarFormatNumeral = (value: number): string => zeroNotNaN(numeral(value).format('$0,0.00'));
export const dollarFormatNumeralUnsigned = (value: number): string => zeroNotNaN(numeral(value).format('0,0.00'));

export const dollarFormatIENumeral = (value: number): string => {
  if (value <= 0) {
    return '0';
  }

  if (value >= 1000000) return numeral(value).format('($0a)');
  return zeroNotNaN(numeral(value).format('$0,0[.]00[]00'));
};

export const wholeDollarFormatNumeral = (value: number): string => {
  if (value <= 0) {
    return '0';
  }

  if (value >= 1000000) return numeral(value).format('($0a)');
  return zeroNotNaN(numeral(value).format('$0,0'));
};

export const tokenFormatNumeral = (value: number): string => zeroNotNaN(numeral(value).format('0,000[]000[]000.000[]000'));
export const tokenFormatNumeralNonZero = (value: number): string => zeroNotNaN(numeral(value).format('0,000[]000[]000'));
export const btcEthFormatNumeral = (value: number): string => zeroNotNaN(numeral(value).format('0.[]000000'));
export const dollarFormatMarketNumeral = (value: number): string => zeroToDash(numeral(value).format('$0,0.00'));
export const feeTokenFormat = (value: number): string => zeroNotNaN(numeral(value).format('0.[]00000'));
export const oxyFormatToken = (value: number): string => numeral(value).format('0[,]00[.]00');
export const timeHumanReadableFormat = (value: number): string => `${moment.unix(value).format('ddd DD MMM YYYY HH:mm')} UTC`;
