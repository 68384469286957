import { Typography } from 'antd';
import { ReactElement } from 'react';

export function StakingAlert({ title, content }: {
  title: string;
  content?: string;
}): ReactElement {
  return (
    <div className="staking-alert">
      <svg className="staking-alert-icon" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 1.5C3.243 1.5 1 3.743 1 6.5C1 9.257 3.243 11.5 6 11.5C8.757 11.5 11 9.257 11 6.5C11 3.743 8.757 1.5 6 1.5ZM6 10.5C3.7945 10.5 2 8.7055 2 6.5C2 4.2945 3.7945 2.5 6 2.5C8.2055 2.5 10 4.2945 10 6.5C10 8.7055 8.2055 10.5 6 10.5Z"
          fill="rgba(34, 96, 229, 1)"
        />
        <path d="M5.5 6H6.5V9H5.5V6ZM5.5 4H6.5V5H5.5V4Z" fill="rgba(34, 96, 229, 1)" />
      </svg>
      <div>
        <Typography.Text className="staking-alert-title">{title}</Typography.Text>
        {content && <Typography.Text className="staking-alert-content">{content}</Typography.Text>}
      </div>
    </div>
  );
}
