import { Button, Modal } from 'antd';
import React, { ReactElement } from 'react';

const handleReloadPage = (): void => {
  window.location.reload();
};
export function ErrorBoundaryFallback(): ReactElement {
  return (
    <Modal
      title="Error"
      visible
      footer={[
        <Button key="submit" type="primary" onClick={handleReloadPage}>
          Reload page
        </Button>,
      ]}
    >
      <div style={{ padding: '16px 24px' }}>
        <p>Something went wrong please reload the page.</p>
        <p>If this error will repeat, write to our support.</p>
      </div>
    </Modal>
  );
}
