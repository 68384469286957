import { createSlice } from '@reduxjs/toolkit';
import { Tx } from 'interfaces/History.interface';
import { LoadingStatus } from 'interfaces/Types';

export interface HistorySlice {
  loadingStatus: LoadingStatus;
  transactions: Tx[];
}

const initialState: HistorySlice = {
  transactions: [],
  loadingStatus: LoadingStatus.Idle,
};

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    clearHistoryTransactions: (state) => {
      state.transactions = [];
      state.loadingStatus = LoadingStatus.Idle;
    },
    setHistoryTransactions: (state, { payload: { transactions = [], loadingStatus } }) => {
      state.transactions = state.transactions.concat(transactions);
      state.loadingStatus = loadingStatus;
    },
    addHistoryTransaction: (state, { payload }) => {
      state.transactions = [
        ...state.transactions,
        payload,
      ];
    },
  },
});

export const historyReducer = historySlice.reducer;
