import { message } from 'antd';
import { TokenData } from 'interfaces/Token.interface';
import { MIN_OXY_AMOUNT, SEVEN_DAYS } from '../Staking/models/Staking.model';

export function catchError(error: Error, token?: TokenData): void {
  // eslint-disable-next-line no-console
  console.log('--error', error, error.name, error.message);

  if (error.name === 'WalletNotConnectedError') {
    message.error('Please reconnect your wallet');
    return;
  }

  if (error.name === 'WalletSignTransactionError') {
    message.error('Transaction canceled');
    return;
  }

  if (error.message.includes('0x132')) {
    message.error('Insufficient collateral remaining. Please try withdrawing a smaller amount.');
    return;
  }

  if (error.message.includes('0x135')) {
    message.error('Invalid transaction due to token mismatch. Please try again.');
    return;
  }

  if (error.message.includes('0x130')) {
    message.error('Invalid transaction due to changes in market data. Please refresh page and try again');
    return;
  }

  if (error.message.includes('0x12')) {
    message.error('Token account is not owned by main account');
    return;
  }

  if (error.message.includes('0x177a')) {
    message.error('Pool reward account doesnt have enough amount.');
    return;
  }

  if (error.message.includes('0x1') && token) {
    message.error(`Not enough ${token.symbol} balance to execute the transaction. Please lower the amount and try again.`);
    return;
  }

  if (error.message.includes('0x1777')) {
    message.error('No available tokens to unstake today.');
    return;
  }

  if (error.message.includes('0x1773')) {
    message.error(`Minimum amount to stake is ${MIN_OXY_AMOUNT} OXY`);
    return;
  }

  if (error.message.includes('0x1772')) {
    message.error(`Minimum stake period is ${SEVEN_DAYS} days`);
    return;
  }

  if (/Attempt to debit an account/.test(error.message)) {
    message.error('Not enough SOL for transaction fee.');
    return;
  }

  if (/Transaction was not confirmed in [\d.,]* seconds/.test(error.message)) {
    message.error('Solana RPC confirmation time-out. Please try again or choose a new RPC.');
    return;
  }

  message.error('Something went wrong. Please, refresh page and try again.');
}
