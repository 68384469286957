import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { Alert, Button, Space, Switch, Tooltip, Typography, message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import BigNumber from 'bignumber.js';
import { historyLoadUserTransactions } from 'features/History/state/History.actions';
import { selectMarketTokens } from 'features/Market/state/Market.selector';
import info from 'features/Statistics/StatisticItem/info.svg';
import { useIsMobile } from 'features/UI/hooks';
import { PopupInput } from 'features/UI/PopupInput/PopupInput';
import { walletWithdrawToken } from 'features/Wallet/state/Wallet.actions';
import { selectWalletBalances, selectWalletBorrowTotalUsd, selectWalletDepositTotalUsd } from 'features/Wallet/state/Wallet.selector';
import { TokenData } from 'interfaces/Token.interface';
import { LoadingStatus, TokensSymbols } from 'interfaces/Types';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { numberToFixed } from 'utils';
import { calculatorService } from '../../Calculator/Calculator.service';
import { catchError } from '../../Error/Error.program';
import { TermsCheckbox } from '../TermsCheckbox/TermsCheckbox';
import { Summary } from './Summary';

const { Text } = Typography;

export function Withdraw({ token, handleCancel, reloadAllData }: {
  handleCancel: Function;
  reloadAllData: Function;
  token: TokenData;
}): ReactElement {
  const wallet = useWallet();
  const { connection } = useConnection();
  const balance = useSelector(selectWalletBalances);
  const tokens = useSelector(selectMarketTokens);
  const tokenBalance = balance[token.id];
  const solTokenBalance = useMemo(() => {
    if (token.symbol === TokensSymbols.Sol) {
      return tokenBalance?.balance?.token;
    }
    const solToken = Object.values(tokens).find(({ symbol }) => symbol === TokensSymbols.Sol);
    return solToken ? balance[solToken?.id]?.balance?.token : 0;
  }, [balance, token.symbol, tokenBalance?.balance?.token, tokens]);

  const [termsCheckvboxValue, setTermsCheckvboxValue] = useState(true);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [inputAmount, setInputAmount] = useState(null);
  const [isLoading, setIsLoading] = useState(LoadingStatus.Idle);
  const [borrowFunds, setBorrowFunds] = useState(false);
  const isBorrowFundsDisabled = useMemo(() => {
    const balanceList = Object.entries(balance);
    return !balanceList.find(([tokenId, { deposited: { token: depositedValue } }]) => {
      const { decimals } = tokens[tokenId];
      return token.id !== tokenId && numberToFixed(depositedValue, decimals) > 0;
    });
  }, [balance, token, tokens]);
  const [isBorrowing, setIsBorrowing] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const depositedTotalInUsd = useSelector(selectWalletDepositTotalUsd);
  const borrowedTotalInUsd = useSelector(selectWalletBorrowTotalUsd);

  const withdrawLimit = useMemo(
    () => numberToFixed(calculatorService.getWithdrawLimit(token, depositedTotalInUsd, borrowedTotalInUsd), token.decimals, 'down'),
    [borrowedTotalInUsd, depositedTotalInUsd, token],
  );

  const borrowLimit = useMemo(
    () => numberToFixed(calculatorService.getBorrowLimit(token, depositedTotalInUsd, borrowedTotalInUsd), token.decimals, 'down'),
    [borrowedTotalInUsd, depositedTotalInUsd, token],
  );

  const withdraw = useMemo(
    () => numberToFixed(calculatorService.getWithdrawTotalAvailable(token, withdrawLimit, tokenBalance, borrowedTotalInUsd), token.decimals, 'down'),
    [withdrawLimit, borrowedTotalInUsd, token, tokenBalance],
  );

  const borrowAvailable = useMemo(
    () => Math.max(withdraw, numberToFixed(calculatorService.getBorrowTotalAvailable(token, borrowLimit), token.decimals, 'down')),
    [borrowLimit, token, withdraw],
  );

  const isUtilizationRatio100 = useMemo(() => calculatorService.getUtilization(token.borrowed.token, token.deposited.token) === 1, [token]);

  const withdrawAvailable = useMemo(() => {
    const amount = numberToFixed(
      calculatorService
        .getWithdrawTotalAvailable(
          token,
          withdrawLimit,
          tokenBalance,
          borrowedTotalInUsd,
        ),
      token.decimals,
      'down',
    );
    if (`${amount}`.includes('e')) {
      return amount.toFixed(token.decimals);
    }
    return amount;
  }, [borrowedTotalInUsd, token, tokenBalance, withdrawLimit]);

  const isButtonBlocked = useMemo(() => {
    if (!termsCheckvboxValue) {
      return true;
    }
    if (inputAmount > 0) {
      if (borrowFunds && borrowAvailable >= inputAmount) {
        return false;
      }
      return !(!borrowFunds && numberToFixed(withdraw, token.decimals, 'down') >= inputAmount);
    }
    return true;
  }, [borrowAvailable, borrowFunds, inputAmount, termsCheckvboxValue, token.decimals, withdraw]);

  const handleWithdraw = useCallback(() => {
    if (!withdrawAvailable) return;
    setInputAmount(withdrawAvailable);
  }, [withdrawAvailable]);

  const handleBorrow = useCallback(() => {
    if (!borrowAvailable) return;
    setInputAmount(borrowAvailable);
    setIsBorrowing(true);
  }, [borrowAvailable]);

  const handleBorrowFunds = useCallback((checked) => {
    setBorrowFunds(checked);
  }, []);

  const handleWithdrawOrBorrow = useCallback(
    async () => {
      if (!wallet.connected) {
        message.error('Please connect your wallet');
        return;
      }

      if (inputAmount <= 0) {
        message.error('Deposit amount is less than or equal to 0. Try an amount greater than 0');
        return;
      }

      if (borrowFunds && inputAmount > Math.max(withdraw, borrowLimit)) {
        message.error('You are trying with withdraw too much. Please try a smaller amount.');
        return;
      }

      if (!borrowFunds && inputAmount > numberToFixed(withdraw, token.decimals, 'down')) {
        message.error('You are trying with withdraw too much. Please try a smaller amount.');
        return;
      }

      if (token.symbol === TokensSymbols.Sol) {
        const balanceLamports = await connection.getBalance(wallet?.publicKey);
        const balanceToken = balanceLamports / LAMPORTS_PER_SOL;
        if (new BigNumber(balanceToken).isLessThan(calculatorService.solTxFee.plus(calculatorService.solTxAccountHoldWithdraw))) {
          message.error(
            `Not enough SOL for request fee. Please decrease amount.
             Your wallet should have enough funds to cover the desired
             deposit amount and still hold at least 0.00203 SOL to be able
             to execute the instruction (0.00203 SOL should return
             to your wallet after successful deposit)`,
          );
          return;
        }
      } else if (calculatorService.solTxFee.isGreaterThan(solTokenBalance)) {
        message.error('Not enough SOL for the desired transaction. Please top-up your wallet with SOL');
        return;
      }

      setIsLoading(LoadingStatus.Loading);

      const amountWithdraw = inputAmount;

      const borrowPrecision = numberToFixed(calculatorService.getBorrowTotalAvailable(token, borrowLimit), token.decimals, 'down');

      if (isBorrowing && inputAmount === borrowPrecision) {
        setIsBorrowing(false);
      }

      const borrowedOrWithdrawedText = (
        <>
          {inputAmount}
          {' '}
          {token.symbol}
          {' '}
          successfuly
          {' '}
          <b>{borrowFunds ? 'Borrowed & Withdrawn' : 'Withdrawn'}</b>
        </>
      );

      // eslint-disable-next-line no-console
      console.log('--amountWithdrawOrBorrow', amountWithdraw);

      try {
        await walletWithdrawToken(connection, wallet, token.mintPubkey, amountWithdraw);
        setIsLoading(LoadingStatus.Idle);
        message.success(borrowedOrWithdrawedText);
        reloadAllData();
        dispatch(historyLoadUserTransactions(connection, wallet.publicKey));
        handleCancel();
      } catch (e: any) {
        catchError(e, token);
        setIsLoading(LoadingStatus.Idle);
      }
    },
    [
      borrowFunds,
      borrowLimit,
      connection,
      dispatch,
      handleCancel,
      inputAmount,
      isBorrowing,
      reloadAllData,
      solTokenBalance,
      token,
      wallet,
      withdraw,
    ],
  );

  return (
    <Space direction="vertical" size="large">
      <PopupInput token={token} inputAmount={inputAmount} onChange={setInputAmount} />

      <Text>
        {!borrowFunds ? (
          <Text className="new-repay">
            {withdrawAvailable}
            {' '}
            {token?.symbol}
            {' '}
            available to withdraw
            {' '}
            <strong onClick={handleWithdraw}>Withdraw Max</strong>
          </Text>
        ) : (
          <Text className="new-repay">
            {borrowAvailable}
            {' '}
            {token?.symbol}
            {' '}
            available to borrow
            {' '}
            <strong onClick={handleBorrow}>Borrow Max</strong>
          </Text>
        )}
      </Text>
      {isUtilizationRatio100 && tokenBalance?.deposited?.token && numberToFixed(tokenBalance?.deposited?.token, token?.decimals, 'down') > 0 && (
        <Alert
          message="Unfortunately, you can not make withdrawal because all deposited funds are borrowed. Please try again later, when utilization rate will be lower."
          type="error"
        />
      )}
      <Modal
        className="tooltip-modal"
        footer={null}
        closable
        visible={modalVisible}
        centered
      >
        Interest is charged on your borrowed balance and is subject to change.
      </Modal>
      <Space direction="horizontal" align="center" style={{ justifyContent: 'space-between' }} wrap>
        <Text>
          <Space size={5}>
            <strong>Borrow funds</strong>
            {!isMobile && <Tooltip title="Interest is charged on your borrowed balance and is subject to change." placement="bottom"><img alt="info" src={info} /></Tooltip>}
            {isMobile && <button type="button" onClick={(): void => setModalVisible(true)}><img alt="info" src={info} /></button>}
          </Space>
        </Text>
        <Text><Switch checked={borrowFunds} disabled={isBorrowFundsDisabled} onChange={handleBorrowFunds} /></Text>
      </Space>
      <Button
        onClick={handleWithdrawOrBorrow}
        loading={isLoading === LoadingStatus.Loading}
        disabled={isButtonBlocked}
        type="primary"
        size="large"
        style={{ height: 50 }}
        shape={isMobile ? 'round' : null}
        block
      >
        <b>{borrowFunds ? 'Borrow' : 'Withdraw'}</b>
      </Button>

      <Summary token={token} inputAmount={inputAmount} borrowFunds={borrowFunds} borrowAvailable={borrowAvailable} />

      <Space align="center" direction="vertical"><TermsCheckbox checked={termsCheckvboxValue} onChange={setTermsCheckvboxValue} /></Space>
    </Space>
  );
}
