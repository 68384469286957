import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';
import { Tabs } from 'antd';
import { AccountMobile } from 'features/AccountMobile/AccountMobile';
import { History } from 'features/History/History/History';
import { TokenTable } from 'features/Market/components/TokenTable/TokenTable';
import { marketTokensLoadAndBalances } from 'features/Market/state/Market.actions';
import { Statistics } from 'features/Statistics';
import { useIsMobile } from 'features/UI/hooks';
import { ReactComponent as MoreSVG } from 'icons/more.svg';
import { ReactComponent as AssetsSVG } from 'icons/assets.svg';
import { ReactComponent as HistorySVG } from 'icons/history.svg';
import { ReactComponent as MarketSVG } from 'icons/markets.svg';
import { ReactComponent as StakingSVG } from 'icons/staking.svg';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { historyLoadUserTransactions } from 'features/History/state/History.actions';
import { Staking } from 'features/Staking';
import { setMobileEntry } from 'features/Staking/state/Staking.actions';
import { selectIsMobileDetected } from 'features/Staking/state/Staking.selector';

const { TabPane } = Tabs;

export function Market({ walletPubkey }: {walletPubkey?: PublicKey}): ReactElement {
  const dispatch = useDispatch();
  const wallet = useWallet();
  const { connection } = useConnection();
  const isMobile = useIsMobile();
  const isDesktop = !isMobile;
  const [selectedTab, setSelectedTab] = useState('marketplace');
  const fromMobile = useSelector(selectIsMobileDetected);

  const handleTabChange = useCallback((tab) => {
    setSelectedTab(tab);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(marketTokensLoadAndBalances(connection, { ...wallet, publicKey: wallet.publicKey || walletPubkey }));
    }, 5000);

    return (): void => clearInterval(interval);
  }, [connection, dispatch, wallet, walletPubkey]);

  useEffect(() => {
    if (!wallet.publicKey && !walletPubkey) {
      return;
    }
    dispatch(historyLoadUserTransactions(connection, wallet.publicKey || walletPubkey));
  }, [dispatch, wallet.publicKey, connection, walletPubkey]);

  useEffect(() => {
    if (fromMobile) {
      setSelectedTab('staking');
      dispatch(setMobileEntry(false));
    }
  }, [dispatch, fromMobile]);

  return (
    isDesktop ? (
      <>
        <Statistics />
        <Tabs className="tabs-market" defaultActiveKey={selectedTab} onChange={handleTabChange}>
          <TabPane tab="Markets" key="marketplace"><TokenTable /></TabPane>
          <TabPane tab="History" key="history"><History /></TabPane>
        </Tabs>
      </>
    ) : (
      <Tabs activeKey={selectedTab} onChange={handleTabChange} className="mobile-tabs">
        <TabPane
          className="mobile-tab"
          tab={(
            <span>
              <span className="mobile-tab-icon"><AssetsSVG /></span>
              {' '}
              Markets
            </span>
          )}
          key="marketplace"
        >
          <TokenTable />
        </TabPane>
        <TabPane
          className="mobile-tab"
          tab={(
            <span>
              <span className="mobile-tab-icon"><MarketSVG /></span>
              {' '}
              My Assets
            </span>
          )}
          key="my-assets"
        >
          <Statistics />
        </TabPane>
        <TabPane
          className="mobile-tab"
          tab={(
            <span>
              <span className="mobile-tab-icon"><HistorySVG /></span>
              {' '}
              History
            </span>
          )}
          key="history"
        >
          <History />
        </TabPane>
        {process.env.REACT_APP_STAKING_MENU === '1' && (
        <TabPane
          className="mobile-tab"
          tab={(
            <span>
              <span className="mobile-tab-icon"><StakingSVG /></span>
              {' '}
              Staking
            </span>
          )}
          key="staking"
        >
          <Staking />
        </TabPane>
        )}
        <TabPane
          className="mobile-tab"
          tab={(
            <span>
              <span className="mobile-tab-icon"><MoreSVG /></span>
              {' '}
              More
            </span>
          )}
          key="account"
        >
          <AccountMobile />
        </TabPane>
      </Tabs>
    )
  );
}
