import { createSlice } from '@reduxjs/toolkit';
import { TokenData } from 'interfaces/Token.interface';
import { LoadingStatus } from 'interfaces/Types';

export interface MarketSlice {
  tokens: {
    [key: string]: TokenData;
  };
  tokensLoadingStatus: LoadingStatus;
}

const initialState: MarketSlice = {
  tokens: {},
  tokensLoadingStatus: LoadingStatus.Idle,
};

export const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    setTokenList: (state, { payload }) => {
      state.tokens = payload;
    },
    setTokensLoadingStatus: (state, { payload }) => {
      state.tokensLoadingStatus = payload;
    },
  },
});

export const marketReducer = marketSlice.reducer;
